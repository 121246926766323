import { Box, Typography, TypographyProps } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

interface SlideUpByLinePropsType extends TypographyProps {
  globaldelay?: number;
  text: string;
}

export const SlideUpByLine = (props: SlideUpByLinePropsType) => {
  const hiddenText = useRef(null);
  const textRef = useRef(null);
  const [lines, setLines] = useState(null);
  const calcLines = () => {
    // This works by adding words to a hidden div and check if it overflows
    setLines(null);
    if (!textRef.current || !hiddenText) {
      return;
    }
    const allWords = textRef.current.innerText.match(/\S+/g) || [];
    const lines = [];
    let currentLine = '';
    for (let i = 0; i < allWords.length; i++) {
      const newLine = currentLine + allWords[i] + ' ';
      hiddenText.current.innerText = newLine;
      if (hiddenText.current.clientWidth > textRef.current.clientWidth) {
        lines.push(currentLine.trim());
        currentLine = allWords[i] + ' ';
      } else {
        currentLine = newLine;
      }
    }
    lines.push(currentLine.trim());
    setLines(lines);
  };
  useEffect(() => {
    calcLines();
  }, []);

  // remove dist
  if (lines !== null) {
    return (
      <Typography {...props}>
        {lines.map((ln, i) => (
          <span
            key={ln + '/' + i}
            style={{
              overflow: 'hidden',
              width: '100%',
            }}
          >
            <span
              data-scroll-class="slide-up-by-line"
              data-scroll
              className="slide-up-by-line-base"
              style={{
                display: 'block',
                transitionDelay: props.globaldelay + i * 100 + 'ms',
              }}
            >
              {ln + ' '}
            </span>
          </span>
        ))}
      </Typography>
    );
  }
  return (
    <Box position={'relative'} overflow="hidden">
      <Typography
        {...props}
        ref={textRef}
        sx={{
          display: 'block',
          height: 'auto',
        }}
      >
        {props.text}
      </Typography>

      <Typography
        {...props}
        ref={hiddenText}
        sx={{
          position: 'absolute',
          visibility: 'hidden',
          height: 'auto',
          width: 'auto',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
      >
        {props.text}
      </Typography>
    </Box>
  );
};
