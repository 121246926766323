import { PaletteOptions } from '@mui/material';

declare module '@mui/material/styles' {
  interface TypeText {
    subhead: string;
  }

  interface SimplePaletteColorOptions {
    text?: string;
  }
}

export const palette: PaletteOptions = {
  primary: {
    main: '#1AC74A',
    text: '#18BA51',
    light: '#E8F9ED',
  },
  text: {
    primary: '#111111',
    subhead: '#393939',
  },
  grey: {
    50: '#F6F6F7',
    100: '#E2E3E5',
    200: '#DEDEDE',
    300: '#B5B5B5',
    400: '#6D7175',
  },
  warning: {
    main: '#E7C421',
  },
  error: {
    main: '#DE3730',
    light: '#FED3D1',
  },
  success: {
    main: '#008060',
  },
  action: {
    hover: 'rgba(0, 0, 0, 0.16)',
    hoverOpacity: 0.16,
    selected: 'rgba(0, 0, 0, 0.2)',
    selectedOpacity: 0.2,
    focus: 'rgba(0, 0, 0, 0.12)',
    focusOpacity: 0.12,
    disabled: '#EFF0F2',
  },
};
