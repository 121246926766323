import { useGetStudentDetailsLazyQuery } from '@generated';
import { RecaptchaVerifier, signOut as firebaseSignOut, signInWithPhoneNumber } from 'firebase/auth';
import { signIn } from 'next-auth/react';
import { PropsWithChildren, createContext, useCallback, useContext, useMemo, useState } from 'react';
import { OTPconfirmation, auth } from '../lib';

interface AuthContextInterface {
  optSignIn?: (phoneNumber: string) => void;
  optSignOut?: () => void;
  generateRecaptcha?: () => void;
  recaptchaLoaded?: boolean;
}

const AuthContext = createContext<AuthContextInterface>({});

export const useAuthContext = () => useContext(AuthContext);

export function AuthProvider({ children }: PropsWithChildren) {
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
  const [getStudentData] = useGetStudentDetailsLazyQuery();
  const generateRecaptcha = () => {
    setRecaptchaLoaded(false);
    window.recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
        callback: recaptchaCallback,
      },
      auth
    );
  };

  const recaptchaCallback = useCallback(() => {
    setRecaptchaLoaded(true);
  }, []);

  const optSignIn = async (phoneNumber: string) => {
    if (phoneNumber.length !== 8) {
      return;
    }

    if (!window?.recaptchaVerifier) {
      if (process.env.NODE_ENV !== 'development') {
        generateRecaptcha();
      } else {
        return null;
      }
    }

    const confirmationResult = '';
    if (process.env.NODE_ENV !== 'development') {
      const appVerifier = window.recaptchaVerifier;
      const confirmationResult = await signInWithPhoneNumber(auth, '+976' + phoneNumber, appVerifier);
      window.confirmationResult = confirmationResult;
    } else {
      const appVerifier = window.recaptchaVerifier;
      return await signInWithPhoneNumber(auth, '+976' + phoneNumber, appVerifier);
    }

    const { data } = await getStudentData();
    const { id, phone } = data.getStudentDetails || { id: '', phone: '' };

    if (OTPconfirmation) {
      await signIn('credentials', { id, phone, redirect: false });
    }

    return confirmationResult;
  };

  const optSignOut = async () => {
    await firebaseSignOut(auth);
  };

  const providerValue = useMemo(() => ({ optSignIn, optSignOut, generateRecaptcha, recaptchaLoaded }), [recaptchaLoaded]);

  return <AuthContext.Provider value={providerValue}>{children}</AuthContext.Provider>;
}
