import React from 'react';
import { LogoProps } from '..';

export const EnrollmentScanIcon = ({ width, height, color }: LogoProps) => (
  <svg width={width || '41'} height={height || '40'} viewBox="0 0 41 40" fill="none">
    <rect opacity="0.1" x="1.25" y="0.75" width="38.5" height="38.5" rx="7.25" stroke={color || '#111111'} strokeWidth="1.5" strokeDasharray="24 16" />
    <path
      d="M12 15V14C12 13.4696 12.2107 12.9609 12.5858 12.5858C12.9609 12.2107 13.4696 12 14 12H16M12 25V26C12 26.5304 12.2107 27.0391 12.5858 27.4142C12.9609 27.7893 13.4696 28 14 28H16M24 12H26C26.5304 12 27.0391 12.2107 27.4142 12.5858C27.7893 12.9609 28 13.4696 28 14V15M24 28H26C26.5304 28 27.0391 27.7893 27.4142 27.4142C27.7893 27.0391 28 26.5304 28 26V25M13 20H27"
      stroke={color || '#111111'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
