import { useMediaQuery } from '@hooks';
import { SimpleHeaderDesktop } from './desktop';
import { SimpleHeaderMobile } from './mobile';

export interface SimpleHeaderProps {
  showProgressBar?: boolean;
  progressBarValue?: number;
  progress?: number;
  handleBack?: () => void;
  handleClick?: () => void;
}

export const SimpleHeader = (props: SimpleHeaderProps) => {
  const isMobile = useMediaQuery('sm');

  if (isMobile) return <SimpleHeaderMobile {...props} />;

  return <SimpleHeaderDesktop {...props} />;
};
