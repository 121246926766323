import { ArrowTopRightCornerIcon, PlayerPlayIcon } from '@assets/icons';
import { breakpoints } from '@theme/breakpoints';
import { createContext, useEffect, useRef, useState } from 'react';
interface CustomCursorContextType {
  mouseTrailer: React.LegacyRef<HTMLElement>;
}
interface CustomCursorPropsType {
  children: React.ReactNode;
}

const cursorIcons = {
  link: <ArrowTopRightCornerIcon color="white" width={48} height={48} />,
  play: <PlayerPlayIcon color="white" />,
};
export const CustomCursorContext = createContext<CustomCursorContextType>({} as CustomCursorContextType);

export const CustomCursorProvider = ({ children }: CustomCursorPropsType) => {
  const mouseTrailer = useRef(null);
  const [cursonIcon, setCursorIcon] = useState(null);

  useEffect(() => {
    const animateTrailer = (e, interacting) => {
      const x = e.clientX - mouseTrailer?.current?.offsetWidth / 2;
      const y = e.clientY - mouseTrailer?.current?.offsetHeight / 2;

      const keyframes = {
        transform: `translate(${x}px, ${y}px)`,
        width: interacting ? '96px' : '20px',
        height: interacting ? '96px' : '20px',
        borderRadius: '50%',
        background: interacting ? `rgba(255, 255, 255, 0.2)` : '#111111',
        boxShadow: `0 4px 30px rgba(0, 0, 0, 0.1)`,
        backdropFilter: `blur(5px)`,
        WebkitBackdropFilter: `blur(5px)`,
        border: `1px solid rgba(255, 255, 255, 0.3)`,
      };

      mouseTrailer?.current?.animate(keyframes, {
        duration: 800,
        fill: 'forwards',
      });
    };
    const disableTrailer = () => {
      mouseTrailer?.current?.animate(
        {
          opacity: 0,
        },
        {
          duration: 800,
          fill: 'forwards',
        }
      );
    };
    const enableTrailer = () => {
      mouseTrailer?.current?.animate(
        {
          opacity: 1,
        },
        {
          duration: 800,
          fill: 'forwards',
        }
      );
    };

    const mouseMoveHandler = (e) => {
      if (breakpoints.values.lg >= window.innerWidth) {
        disableTrailer();
        return;
      }
      const isDisabled = e.target.closest('.no-trailer');
      if (isDisabled !== null) {
        disableTrailer();
      } else {
        enableTrailer();
      }
      const interactable = e.target.closest('.interactable'),
        interacting = interactable !== null;
      animateTrailer(e, interacting);

      mouseTrailer.current.dataset.type = interacting ? interactable?.dataset?.type : '';
      if (interacting) {
        if (cursorIcons[interactable.dataset.type]) {
          setCursorIcon(cursorIcons[interactable.dataset.type]);
        }
      }
    };

    document.addEventListener('mousemove', mouseMoveHandler);
    return () => document.removeEventListener('mousemove', mouseMoveHandler);
  });

  return (
    <CustomCursorContext.Provider value={{ mouseTrailer }}>
      <div id="trailer" ref={mouseTrailer}>
        <div id="trailer-icon">{cursonIcon}</div>
      </div>

      {children}
    </CustomCursorContext.Provider>
  );
};
