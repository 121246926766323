export const CameraIcon = () => {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="40" cy="40" r="39.5" fill="#1AC74A" fillOpacity="0.1" stroke="#1AC74A" />
      <g clipPath="url(#clip0_4088_5364)">
        <path
          d="M45 36.6664L52.5883 32.873C52.8424 32.7461 53.1246 32.6862 53.4083 32.699C53.692 32.7117 53.9677 32.7968 54.2093 32.9461C54.4509 33.0953 54.6504 33.3038 54.7888 33.5518C54.9271 33.7998 54.9998 34.0791 55 34.363V45.6364C54.9998 45.9204 54.9271 46.1996 54.7888 46.4476C54.6504 46.6956 54.4509 46.9041 54.2093 47.0534C53.9677 47.2026 53.692 47.2877 53.4083 47.3005C53.1246 47.3132 52.8424 47.2533 52.5883 47.1264L45 43.333V36.6664Z"
          stroke="#1AC74A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M41.6667 30H28.3333C26.4924 30 25 31.4924 25 33.3333V46.6667C25 48.5076 26.4924 50 28.3333 50H41.6667C43.5076 50 45 48.5076 45 46.6667V33.3333C45 31.4924 43.5076 30 41.6667 30Z"
          stroke="#1AC74A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4088_5364">
          <rect width="40" height="40" fill="white" transform="translate(20 20)" />
        </clipPath>
      </defs>
    </svg>
  );
};
