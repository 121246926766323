import React from 'react';
import { LogoProps } from '.';

export const PhoneIcon = ({ width, height, color }: LogoProps) => (
  <svg width={width || '40'} height={height || '40'} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 24.8V32C0 36.4183 3.58172 40 8 40H15.2V38.5H8C4.41015 38.5 1.5 35.5899 1.5 32V24.8H0ZM0 15.2H1.5V8C1.5 4.41015 4.41015 1.5 8 1.5H15.2V0H8C3.58172 0 0 3.58172 0 8V15.2ZM24.8 0V1.5H32C35.5899 1.5 38.5 4.41015 38.5 8V15.2H40V8C40 3.58172 36.4183 0 32 0H24.8ZM40 24.8H38.5V32C38.5 35.5899 35.5899 38.5 32 38.5H24.8V40H32C36.4183 40 40 36.4183 40 32V24.8Z"
      fill={color || '#111111'}
    />
    <path
      d="M12.5 12H16.5L18.5 17L16 18.5C17.071 20.6715 18.8285 22.429 21 23.5L22.5 21L27.5 23V27C27.5 27.5304 27.2893 28.0391 26.9142 28.4142C26.5391 28.7893 26.0304 29 25.5 29C21.5993 28.763 17.9202 27.1065 15.1568 24.3432C12.3935 21.5798 10.737 17.9007 10.5 14C10.5 13.4696 10.7107 12.9609 11.0858 12.5858C11.4609 12.2107 11.9696 12 12.5 12Z"
      stroke={color || '#111111'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
