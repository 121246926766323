import React from 'react';
import { LogoProps } from '../';

export const ArrowCornerIcon = ({ width, height, color, className }: LogoProps) => {
  return (
    <svg className={className} width={width || '39'} height={height || '39'} viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M28.3181 32.5608L0.439453 4.68209L4.68209 0.439453L32.5608 28.3181V4.82548H38.5608V38.5608H5.12388V32.5608H28.3181Z" fill={color || 'white'} />
    </svg>
  );
};
