import loadingAnimation from '@assets/lottie/loading.json';
import { Stack, Typography } from '@mui/material';
import dynamic from 'next/dynamic';

const Lottie = dynamic(() => import('lottie-react'), { ssr: false });

export const Loading = () => {
  return (
    <Stack paddingX={5} paddingY={8}>
      <Lottie animationData={loadingAnimation} />

      <Typography>Түр хүлээнэ үү</Typography>
      <Typography>Төлбөр төлөгдөж байна...</Typography>
    </Stack>
  );
};
