import { Box, ButtonProps, Button as MuiButton, Stack, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import Link from 'next/link';
import { useState } from 'react';
import { ArrowCornerIcon } from '../assets/icons';

export const Footer = () => {
  return (
    <Box component="footer" overflow="hidden" data-cy="Footer">
      <div data-scroll data-scroll-direction="vertical" data-scroll-speed="-8" data-scroll-position="bottom">
        <Stack
          width="100vw"
          justifyContent="center"
          bgcolor="black"
          direction={{
            sm: 'column',
            lg: 'row',
          }}
        >
          <Button text="Бидний тухай" href="/about-us" dataCy="Footer-About-Us-Button" />
          <Button text="элсэх" dataCy="Footer-Register-Button" sx={{ border: '1px solid rgba(255, 255, 255, 0.2)', borderWidth: { sm: '1px 0px', lg: '0px 1px' } }} href="/enroll" />
          <Button text="FAQ" href="/faq" dataCy="Footer-FAQ-Button" />
        </Stack>
      </div>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  animatedItem: {
    animation: `$myEffect 200ms ${theme.transitions.easing.sharp}`,
    opacity: 0.3,
    scale: 1,
  },
  animatedItemExiting: {
    scale: 1.2,
    opacity: 1,
    transform: 'translate(70%, 70%)',
    animation: `$myEffectExit 300ms ${theme.transitions.easing.sharp}`,
  },
  '@keyframes myEffect': {
    '0%': {
      scale: 1.2,
      transform: 'translate(70%, 70%)',
    },
    '100%': {
      scale: 1,
      opacity: 1,
      transform: 'translate(0)',
    },
  },
  '@keyframes myEffectExit': {
    '0%': {
      scale: 1,
      transform: 'translate(0)',
    },
    '50%': {
      scale: 1.2,
      opacity: 1,
      stroke: 'white',
      transform: 'translate(90%, 90%)',
    },
    '100%': {
      scale: 1.2,
      opacity: 1,
      stroke: 'white',
      transform: 'translate(70%, 70%)',
    },
  },
}));

const Button = (props: { text: string; href?: string; dataCy?: string } & ButtonProps) => {
  const { text, sx, href, dataCy } = props;
  const [exit, setExit] = useState(false);
  const classes = useStyles();

  return (
    <Link
      href={href}
      style={{
        flex: 1,
      }}
      data-cy={dataCy}
    >
      <MuiButton fullWidth sx={{ ...sx }} onMouseOver={() => setExit(true)} onMouseLeave={() => setExit(false)}>
        <Stack width="100%" maxWidth={({ breakpoints }) => ({ md: breakpoints.values.md, lg: breakpoints.values.lg })}>
          <Stack direction="row" justifyContent="space-between" height={{ lg: 200, md: 164, sm: 125 }} alignItems="center" marginX={{ sm: 3, md: 8 }}>
            <Stack>
              <Typography
                sx={{
                  typography: {
                    sm: 'captions',
                    md: 'bodyM',
                  },
                }}
                color="grey.300"
                textAlign="left"
              >
                Дэлгэрэнгүй
              </Typography>
              <Typography
                sx={{
                  typography: {
                    sm: 'buttonL',
                    md: 'displayM',
                  },
                  transform: 'scale(1.2, 0.9)',
                  transformOrigin: 'left',
                }}
                color="white"
                textTransform="uppercase"
                textAlign="left"
              >
                {text}
              </Typography>
            </Stack>
            <Box
              sx={{
                width: {
                  sm: 20,
                  md: 39,
                },
                height: {
                  sm: 20,
                  md: 39,
                },
              }}
            >
              <ArrowCornerIcon
                width="100%"
                height="100%"
                className={clsx(classes.animatedItem, {
                  [classes.animatedItemExiting]: exit,
                })}
                color="white"
              />
            </Box>
          </Stack>
        </Stack>
      </MuiButton>
    </Link>
  );
};
