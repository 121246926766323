export const FrLeftArrorIcon = (props) => {
  const { width = 40, height = 41, color = 'black' } = props;

  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M31.6654 20H8.33203" stroke={color} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19.9987 31.6666L8.33203 19.9999L19.9987 8.33325" stroke={color} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
