export const ArrowLeftIcon = (props) => {
  const { width = 40, height = 40, direction = 'right', color = '#111111' } = props;
  // const base = {
  //   right: 'm15 10.5 10 10-10 10',
  //   left: 'M25 10.5L15 20.5L25 30.5',
  // };

  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      {direction == 'left' ? (
        <>
          <path d="M31.6654 20H8.33203" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M19.9987 31.6666L8.33203 19.9999L19.9987 8.33325" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </>
      ) : (
        <>
          <path d="M8.33073 20H31.6641" stroke="#111111" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M19.9974 31.6666L31.6641 19.9999L19.9974 8.33325" stroke="#111111" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </>
      )}
      {/* <path d={base[direction]} stroke={color} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" /> */}
    </svg>
  );
};
