import { ChevronRightIcon } from '@assets/icons';
import { BackInUp, InputField } from '@components';
import {
  EnrollmentForWhoTypes,
  EnrollmentProgramTypes,
  useAddEmergencyContactMutation,
  useCreateEnrollmentProfileMutation,
  useGetInformationSourcesQuery,
  useGetStudentDetailsLazyQuery,
  useRegisterStudentForAcademyMutation,
} from '@generated';
import { Box, Grid, Link, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { Field, Form, Formik, ErrorMessage as FormikErrorMessage, ErrorMessageProps as FormikErrorMessageProps } from 'formik';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useState } from 'react';
import * as Yup from 'yup';
import { useAuthContext } from '../../contexts/';
import { EnrollmentDataProps, useEnrollmentContext } from './context';
import { EnrollmentGeneralInfoSectionData } from './data-content';
import { EnrollmentProcessHeader } from './header';
import { StyledIconButton } from './styled-icon-button';
import { TwoFactorModal } from './two-factor-modal';

interface ErrorMessageProp extends FormikErrorMessageProps {
  supportingComponent?: React.ReactNode | undefined;
  isShowSupport?: boolean | undefined;
  color?: 'main' | 'light';
  dataCy?: string;
}

const ErrorMessage = ({ name, color = 'main', supportingComponent, isShowSupport, dataCy }: ErrorMessageProp) => (
  <Box ml={1.5}>
    <FormikErrorMessage name={name}>
      {(errorMsg) => (
        <Stack flex={1} direction="row">
          <Typography color={`error.${color}`} data-cy={dataCy}>
            {errorMsg}
          </Typography>
          {isShowSupport && supportingComponent && supportingComponent}
        </Stack>
      )}
    </FormikErrorMessage>
  </Box>
);

export const EnrollmentGeneralInfo = () => {
  const router = useRouter();
  const [getStudentData] = useGetStudentDetailsLazyQuery();
  const { enrollmentData } = useEnrollmentContext();
  const [addEmergencyContact] = useAddEmergencyContactMutation();
  const [createEnrollment] = useCreateEnrollmentProfileMutation();
  const [registerStudent] = useRegisterStudentForAcademyMutation();
  const [twoFactorModalOpen, setTwoFactorModalOpen] = useState(false);
  const { optSignIn } = useAuthContext();
  const [informationSource, setInformationSource] = useState('NA');
  const [informationSourceError, setInformationSourceError] = useState(false);

  type initialValuesType = Omit<EnrollmentDataProps, 'progress'>;

  const initialValues: initialValuesType = {
    lastName: '',
    firstName: '',
    phoneNumber: '',
    facebookName: '',
    email: '',
    emergencyContact: '',
    emergencyContactPhoneNumber: '',
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().max(70, 'Хэт урт байна!').required('Нэр заавал бөглөнө үү'),
    lastName: Yup.string().max(70, 'Хэт урт байна!').required('Овог заавал бөглөнө үү'),
    phoneNumber: Yup.string()
      .matches(/^[0-9]{8}$/, 'Утасны дугаар 8 оронтой байх ёстой')
      .test('phoneNumber', 'Энэ дугаар бүртгэлтэй байна', async (phoneNumber) => {
        try {
          const { data } = await getStudentData({ variables: { phone: phoneNumber } });
          if (data?.getStudentDetails) throw new Error();
          return true;
        } catch (error) {
          return false;
        }
      })
      .required('Утасны дугаар заавал бөглөнө үү'),
    facebookName: Yup.string().max(70, 'Хэт урт байна!').required('Facebook хаяг заавал бөглөнө үү'),
    email: Yup.string().email('И-мэйл хаяг шаардлага хангаагүй').required('И-мэйл хаягаа заавал бөглөнө үү'),
    emergencyContact: Yup.string().max(70, 'Хэт урт байна!').required('Заавал бөглөнө үү'),
    emergencyContactPhoneNumber: Yup.string()
      .matches(/^[0-9]{8}$/, 'Утасны дугаар 8 оронтой байх ёстой')
      .required('Заавал бөглөнө үү')
      .notOneOf([Yup.ref('phoneNumber'), null], 'Утасны дугаар өөр байх шаардлагатай'),
  });

  const checkTwoAuth = async (values: initialValuesType) => {
    if (informationSource === 'NA') {
      setInformationSourceError(true);
      return;
    }
    try {
      await optSignIn(values?.phoneNumber.toString());
      setTwoFactorModalOpen(true);
    } catch (err) {
      //error
    }
  };

  const onSubmit = async (values: initialValuesType) => {
    try {
      const {
        data: { addEmergencyContact: contactId },
      } = await addEmergencyContact({ variables: { who: values.emergencyContact, phone: values.emergencyContactPhoneNumber.toString() } });

      let program = enrollmentData.curriculum;
      let time = enrollmentData.shift;

      const shiftValues = enrollmentData.shift.split(' ');
      if (shiftValues.length === 2) {
        const [shiftProgram, shiftTime] = shiftValues;
        program = shiftProgram;
        time = shiftTime;
      }

      const {
        data: { createEnrollmentProfile: enrollmentId },
      } = await createEnrollment({
        variables: {
          forWho: enrollmentData.forWho as EnrollmentForWhoTypes,
          program: program as EnrollmentProgramTypes,
          time: time,
        },
      });

      const {
        data: {
          registerStudentForAcademy: { id },
        },
      } = await registerStudent({
        variables: {
          details: {
            phone: values.phoneNumber.toString(),
            lastName: values.lastName,
            email: values.email,
            emergency: [contactId],
            enrollment: [enrollmentId],
            firstName: values.firstName,
            facebook: values.facebookName,
            informationSource: informationSource,
          },
        },
      });
      await signIn('credentials', { id, phone: enrollmentData?.phoneNumber, redirect: false });
      router.push('/enroll/review/');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <BackInUp>
      <Stack alignItems="center" spacing={5} minHeight="calc(100vh - 170px)" paddingTop={4}>
        <EnrollmentProcessHeader
          icon={EnrollmentGeneralInfoSectionData.titleIcon}
          title={EnrollmentGeneralInfoSectionData.title}
          subTitle={enrollmentData.forWho === 'YOURSELF' ? EnrollmentGeneralInfoSectionData.subTitle1 : EnrollmentGeneralInfoSectionData.subTitle2}
        />
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={checkTwoAuth}>
          {({ values, errors, touched, isValid, dirty }) => (
            <Form>
              <Grid maxWidth={640} container spacing={2} justifyContent="center" paddingX={{ sm: 2, md: 0 }}>
                <Grid item sm={12} md={6}>
                  <Field
                    label="Овог"
                    name="lastName"
                    as={InputField}
                    important
                    error={touched.lastName && Boolean(errors.lastName)}
                    variant={touched.lastName && errors.lastName ? 'error' : 'normal'}
                    dataCy="General-Info-LastName"
                  />
                  <ErrorMessage name="lastName" dataCy="General-Info-Lastname-Error" />
                </Grid>
                <Grid item sm={12} md={6}>
                  <Field
                    label="Нэр"
                    name="firstName"
                    as={InputField}
                    important
                    error={touched.firstName && Boolean(errors.firstName)}
                    variant={touched.firstName && errors.firstName ? 'error' : 'normal'}
                    dataCy="General-Info-FirstName"
                  />
                  <ErrorMessage name="firstName" dataCy="General-Info-FirstName-Error" />
                </Grid>
                <Grid item sm={12} md={12}>
                  <Field
                    label="Утасны дугаар"
                    name="phoneNumber"
                    as={InputField}
                    important
                    inputMode="numeric"
                    type="number"
                    error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                    variant={touched.phoneNumber && errors.phoneNumber ? 'error' : 'normal'}
                    dataCy="General-Info-PhoneNumber"
                  />
                  <ErrorMessage
                    name="phoneNumber"
                    dataCy="General-Info-PhoneNumber-Error"
                    isShowSupport={'Энэ дугаар бүртгэлтэй байна' === errors.phoneNumber}
                    supportingComponent={
                      <Link href="/sign-in" sx={{ marginX: 1 }} underline="always" fontWeight={400} color="#000000">
                        Нэвтрэх
                      </Link>
                    }
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <Field
                    label="Facebook хаягаа бичнэ үү"
                    as={InputField}
                    important
                    name="facebookName"
                    error={touched.facebookName && Boolean(errors.facebookName)}
                    variant={touched.facebookName && errors.facebookName ? 'error' : 'normal'}
                    dataCy="General-Info-FacebookName"
                  />
                  <ErrorMessage name="facebookName" dataCy="General-Info-FacebookName-Error" />
                </Grid>
                <Grid item sm={12} md={6}>
                  <Field
                    label="И-мэйл хаягаа бичнэ үү"
                    as={InputField}
                    important
                    name="email"
                    error={touched.email && Boolean(errors.email)}
                    variant={touched.email && errors.email ? 'error' : 'normal'}
                    dataCy="General-Info-Email"
                  />
                  <ErrorMessage name="email" dataCy="General-Info-Email-Error" />
                </Grid>
                <Grid item sm={12}>
                  <Box mb={2}>
                    <Typography variant="bodyMSemibold">Яаралтай үед холбогдох</Typography>
                  </Box>
                  <Field
                    label="Таны хэн болох"
                    name="emergencyContact"
                    as={InputField}
                    important
                    error={touched.emergencyContact && Boolean(errors.emergencyContact)}
                    variant={touched.emergencyContact && errors.emergencyContact ? 'error' : 'normal'}
                    dataCy="General-Info-EmergencyContact"
                  />
                  <Box>
                    <ErrorMessage name="emergencyContact" dataCy="General-Info-EmergencyContact-Error" />
                  </Box>
                </Grid>
                <Grid item sm={12}>
                  <Field
                    label="Утасны дугаар"
                    name="emergencyContactPhoneNumber"
                    as={InputField}
                    important
                    inputMode="numeric"
                    type="number"
                    error={touched.emergencyContactPhoneNumber && Boolean(errors.emergencyContactPhoneNumber)}
                    variant={touched.emergencyContactPhoneNumber && errors.emergencyContactPhoneNumber ? 'error' : 'normal'}
                    dataCy="General-Info-EmergencyContactPhoneNumber"
                  />
                  <Box>
                    <ErrorMessage name="emergencyContactPhoneNumber" dataCy="General-Info-EmergencyContactPhoneNumber-Error" />
                  </Box>
                </Grid>
                <Grid item sm={12}>
                  <Box mb={2}>
                    <Typography variant="bodyMSemibold">Та хаанаас мэдээлэлээ авсан бэ?</Typography>
                  </Box>

                  <InformationSourcesSelect value={informationSource} onChange={setInformationSource} />

                  {informationSourceError && (
                    <Box>
                      <Typography color={`error.main`}>Заавал оруулна уу</Typography>
                    </Box>
                  )}
                </Grid>
                <Box mt={{ sm: 4, md: 10 }} mb={{ sm: 9, md: 24, lg: 6 }}>
                  <Box marginX="auto" width="fit-content">
                    <StyledIconButton type="submit" sx={{ color: ({ palette }) => (dirty ? 'white' : palette.grey[200]) }} disabled={!dirty} data-cy="General-Info-Next-Button">
                      <ChevronRightIcon color={dirty && 'white'} />
                    </StyledIconButton>
                  </Box>
                </Box>
              </Grid>

              <TwoFactorModal open={twoFactorModalOpen} setOpen={setTwoFactorModalOpen} callback={() => onSubmit(values)} phone={values.phoneNumber?.toString()} />
            </Form>
          )}
        </Formik>
      </Stack>
    </BackInUp>
  );
};

function InformationSourcesSelect({ value, onChange }) {
  const { data, loading } = useGetInformationSourcesQuery();

  if (loading) return <div>Loading...</div>;

  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value as string);
  };

  return (
    <Select value={value} onChange={handleChange} sx={{ width: '100%' }} data-cy="General-Info-Source-Select">
      <MenuItem value="NA">Сонгох...</MenuItem>
      {data?.getInformationSources.map((source) => (
        <MenuItem value={source.name} key={source.name} data-cy={`General-Info-Source-Select-${source.name}`}>
          {source.text}
        </MenuItem>
      ))}
    </Select>
  );
}
