import React from 'react';
import { LogoProps } from '..';

export const EnrollmentClockIcon = ({ width, height, color }: LogoProps) => (
  <svg width={width || '40'} height={height || '40'} viewBox="0 0 40 40" fill="none">
    <rect opacity="0.1" x="0.75" y="0.75" width="38.5" height="38.5" rx="7.25" stroke={color || '#111111'} strokeWidth="1.5" strokeDasharray="24 16" />
    <path
      d="M16.5 18L19.5 20V15M28.5 20C28.5 24.9706 24.4706 29 19.5 29C14.5294 29 10.5 24.9706 10.5 20C10.5 15.0294 14.5294 11 19.5 11C24.4706 11 28.5 15.0294 28.5 20Z"
      stroke={color || '#111111'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
