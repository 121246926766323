import { NotSelectedDotIcon, SelectedDotIcon } from '@assets/icons';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { RowSelectorProps, SelectorItemProps } from './props';

export const RowSelector = (props: RowSelectorProps) => {
  const { selected, onChange, values, columns = 1, type } = props;

  return (
    <Grid container width="100%" margin={0} rowSpacing={1.5} columnSpacing={{ sm: 0, lg: 1.5 }}>
      {values.map((cur) => (
        <Grid key={`selector-item-${cur.value}`} item zIndex={10} sm={12 / columns}>
          <SelectorItem {...cur} active={selected === cur.value} onChange={onChange} type={type} />
        </Grid>
      ))}
    </Grid>
  );
};

const SelectorItem = (props: SelectorItemProps & { type?: 'row' | 'column' | 'grid' }) => {
  const { active, label, value, subLabel, onChange, disabled, disableText, type, helperText } = props;
  const isTypeGrid = type === 'grid';

  return (
    <Box
      borderRadius={1}
      sx={{ cursor: disabled ? 'default' : 'pointer' }}
      onClick={() => !disabled && onChange && onChange(value)}
      boxShadow="0px 4px 15px rgba(0, 0, 0, 0.05)"
      bgcolor={({ palette }) => (disabled ? palette.grey[50] : palette.common.white)}
      border={({ palette }) => '1px solid ' + (active ? palette.primary.main : palette.grey[100])}
    >
      <Stack position="relative" direction="row" alignItems="center" paddingY={3} paddingX={2} spacing={1}>
        {active && <SelectedDotIcon />}
        {!active && <NotSelectedDotIcon />}

        <Stack spacing={0.3} direction={isTypeGrid ? 'column' : 'row'}>
          <Typography typography={{ sm: 'bodyS', lg: isTypeGrid ? 'bodyS' : 'bodyM' }} fontWeight={isTypeGrid ? 400 : 600} data-cy="For-Who-Select-Radio">
            {label}
          </Typography>
          <Typography typography={{ sm: isTypeGrid ? 'bodyM' : 'bodyS', lg: 'bodyM' }} fontWeight={`${isTypeGrid ? 600 : 400} !important`}>
            {subLabel}
          </Typography>
        </Stack>

        <Typography variant="caption" fontWeight="600" position="absolute" color={disabled ? 'black' : '#6D7175'} right={12}>
          {disableText ? disableText : helperText}
        </Typography>
      </Stack>
    </Box>
  );
};
