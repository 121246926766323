import { Box, IconButton, IconButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const RawStyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  boxShadow: '0px 4px 15px rgba(26, 199, 74, 0.5)',
  padding: theme.spacing(2),
  ':hover': {
    backgroundColor: theme.palette.primary.main,
    opacity: 0.6,
  },
  ':disabled': {
    backgroundColor: theme.palette.grey[50],
    boxShadow: 'none',
  },
}));

export const StyledIconButton = (props: IconButtonProps) => {
  const { children, disabled, ...others } = props;

  return (
    <Box padding={0.8} border={({ palette }) => (disabled ? 'none' : `0.5px solid ${palette.grey[100]}`)} borderRadius="100%" data-cy="Enroll-Icon-Button">
      <RawStyledIconButton disabled={disabled} {...others}>
        {children}
      </RawStyledIconButton>
    </Box>
  );
};
