import React from 'react';
import { LogoProps } from '..';

export const SuccessLocationLogo = ({ width, height, color }: LogoProps) => (
  <svg width={width || '41'} height={height || '40'} viewBox="0 0 41 40" fill="none">
    <path
      d="M20.5013 23.3333C23.2627 23.3333 25.5013 21.0948 25.5013 18.3333C25.5013 15.5719 23.2627 13.3333 20.5013 13.3333C17.7399 13.3333 15.5013 15.5719 15.5013 18.3333C15.5013 21.0948 17.7399 23.3333 20.5013 23.3333Z"
      stroke={color || '#111111'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.9296 27.7617L22.858 34.8333C22.233 35.4577 21.3856 35.8085 20.5021 35.8085C19.6187 35.8085 18.7713 35.4577 18.1463 34.8333L11.073 27.7617C9.20834 25.8969 7.93853 23.5212 7.42412 20.9348C6.90971 18.3484 7.17379 15.6675 8.18298 13.2312C9.19217 10.7949 10.9011 8.71257 13.0938 7.24751C15.2864 5.78246 17.8643 5.00049 20.5013 5.00049C23.1384 5.00049 25.7162 5.78246 27.9088 7.24751C30.1015 8.71257 31.8104 10.7949 32.8196 13.2312C33.8288 15.6675 34.0929 18.3484 33.5785 20.9348C33.0641 23.5212 31.7943 25.8969 29.9296 27.7617Z"
      stroke={color || '#111111'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
