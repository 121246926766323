import React from 'react';

export function CalendarIcon() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 24.8V32C0 36.4183 3.58172 40 8 40H15.2V38.5H8C4.41015 38.5 1.5 35.5899 1.5 32V24.8H0ZM0 15.2H1.5V8C1.5 4.41015 4.41015 1.5 8 1.5H15.2V0H8C3.58172 0 0 3.58172 0 8V15.2ZM24.8 0V1.5H32C35.5899 1.5 38.5 4.41015 38.5 8V15.2H40V8C40 3.58172 36.4183 0 32 0H24.8ZM40 24.8H38.5V32C38.5 35.5899 35.5899 38.5 32 38.5H24.8V40H32C36.4183 40 40 36.4183 40 32V24.8Z"
        fill="#111111"
      />
      <path
        d="M23.5 11V15M15.5 11V15M11.5 19H27.5M18.5 23H19.5V26M13.5 13H25.5C26.6046 13 27.5 13.8954 27.5 15V27C27.5 28.1046 26.6046 29 25.5 29H13.5C12.3954 29 11.5 28.1046 11.5 27V15C11.5 13.8954 12.3954 13 13.5 13Z"
        stroke="#111111"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
