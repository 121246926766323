import React from 'react';
import { LogoProps } from '..';

export const EnrollmentPersonIcon = ({ width, height, color }: LogoProps) => (
  <svg width={width || '40'} height={height || '40'} viewBox="0 0 40 40" fill="none">
    <rect opacity="0.1" x="0.75" y="0.75" width="38.5" height="38.5" rx="7.25" stroke={color || '#111111'} strokeWidth="1.5" strokeDasharray="24 16" />
    <path
      d="M13.5 29V27C13.5 25.9391 13.9214 24.9217 14.6716 24.1716C15.4217 23.4214 16.4391 23 17.5 23H21.5C22.5609 23 23.5783 23.4214 24.3284 24.1716C25.0786 24.9217 25.5 25.9391 25.5 27V29M23.5 15C23.5 17.2091 21.7091 19 19.5 19C17.2909 19 15.5 17.2091 15.5 15C15.5 12.7909 17.2909 11 19.5 11C21.7091 11 23.5 12.7909 23.5 15Z"
      stroke={color || '#111111'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
