import { PineconeLogoIcon } from '@assets/icons';
import { Box, IconButton, Stack } from '@mui/material';
import { SimpleHeaderProps } from '.';

export const SimpleHeaderDesktop = (props: SimpleHeaderProps) => {
  const { showProgressBar, handleClick } = props;

  return (
    <Stack width="100vw" alignItems="center">
      <Stack
        height={80}
        position="relative"
        width={{ sm: 'calc(100% - 128px)', md: 'calc(100% - 40px)', lg: 'calc(100% - 128px)' }}
        maxWidth={({ breakpoints }) => breakpoints.values.lg}
        justifyContent="center"
        alignItems="center"
      >
        <Box position="absolute" left={0}>
          <IconButton onClick={handleClick}>
            <PineconeLogoIcon />
          </IconButton>
        </Box>

        {showProgressBar && <ProgressBar {...props} />}
      </Stack>
    </Stack>
  );
};

const ProgressBar = (props: SimpleHeaderProps) => {
  const { progress = 0, progressBarValue = 4 } = props;

  return (
    <Stack direction="row" spacing={1}>
      {[...Array(progressBarValue).keys()].map((cur, key) => (
        <Box key={`progress-${key}`} width={30} height={3} bgcolor={({ palette }) => (cur <= progress ? palette.primary.main : palette.grey[100])} borderRadius={1} />
      ))}
    </Stack>
  );
};
