import { ChevronRightIcon } from '@assets/icons';
import { BackInUp, Selector } from '@components';
import { Stack } from '@mui/material';
import { useEnrollmentContext } from './context';
import { EnrollmentCurriculumSectionData } from './data-content';
import { EnrollmentProcessHeader } from './header';
import { StyledIconButton } from './styled-icon-button';

export const EnrollmentCurriculum = () => {
  const { setEnrollmentData, enrollmentData } = useEnrollmentContext();
  const { curriculum } = enrollmentData;
  const isSelected = curriculum !== undefined;

  const onChange = (newValue: string) => {
    setEnrollmentData((prev) => ({ ...prev, curriculum: newValue }));
  };

  const onSubmit = () => {
    if (isSelected) setEnrollmentData((prev) => ({ ...prev, progress: prev.progress + 1 }));
  };

  return (
    <BackInUp>
      <Stack width="100%" alignItems="center" spacing={5} minHeight="calc(100vh - 170px)" paddingTop={4}>
        <EnrollmentProcessHeader icon={EnrollmentCurriculumSectionData.titleIcon} title={EnrollmentCurriculumSectionData.title} subTitle={EnrollmentCurriculumSectionData.subTitle} />

        <Stack width={({ spacing }) => `calc(100% - ${spacing(4)})`} maxWidth={648} alignItems="center" spacing={5}>
          <Selector type="column" values={EnrollmentCurriculumSectionData.selections.filter((selection) => selection.choosable)} value={curriculum} onChange={onChange} />
        </Stack>

        <StyledIconButton disabled={!isSelected} onClick={onSubmit} data-cy="Curriculum-Next-Button">
          <ChevronRightIcon color={isSelected && 'white'} />
        </StyledIconButton>
      </Stack>
    </BackInUp>
  );
};
