import { ArrowLeftIcon } from '@assets/icons';
import errorAnimation from '@assets/lottie/error.json';
import successAnimation from '@assets/lottie/success.json';
import { BackInUp } from '@components';
import { MeetingTypes, useScheduleMeetingMutation, useUpdateStudentDetailMutation } from '@generated';
import { Box, CircularProgress, Container, IconButton, Modal as MUIModal, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { EnrollmentProcessHeader } from '../enroll/header';
import { MeetingInterface } from './AppointmentSelector';
import { useBookingContext } from './context';
import { BookingAppointmentSectionData } from './data-content';
import dynamic from 'next/dynamic';

const Lottie = dynamic(() => import('lottie-react'), { ssr: false });

export const Appointment = () => {
  const { setBookingData } = useBookingContext();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { titleIcon, title } = BookingAppointmentSectionData ?? {};
  const [scheduleMeeting] = useScheduleMeetingMutation();
  const [updateStudent] = useUpdateStudentDetailMutation();

  const onSubmit = async (appointment: MeetingInterface) => {
    setOpen(true);
    setLoading(true);
    try {
      const {
        data: { scheduleMeeting: meetingId },
      } = await scheduleMeeting({ variables: { date: appointment.date.toString(), location: appointment.location, title: appointment.location, type: 'ONLINE' as MeetingTypes } });
      await updateStudent({ variables: { studentId: localStorage.getItem('uid'), details: { meeting: [meetingId] } } });
      setBookingData((prev) => ({ ...prev, meetingResult: appointment.date, progress: prev.progress + 1, appointment }));
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };
  // const meetings = data && formatMeetings(data?.academyWebMeetings);

  return (
    <Container>
      <BackInUp>
        <Stack spacing={5} overflow="scroll" minHeight="calc(100vh - 170px)" maxWidth={500} margin="auto" paddingTop={4}>
          <IconButton sx={{ display: { md: 'none' } }}>
            <ArrowLeftIcon width={40} height={40} direction="left" />
          </IconButton>
          <Stack alignItems="center">
            <EnrollmentProcessHeader hideBackButton {...{ icon: titleIcon, title }} />
          </Stack>
          {/* {data && <AppointmentSelector values={meetings} onChange={onSubmit} />} */}
        </Stack>

        <Modal loading={loading} open={open} error={error} />
      </BackInUp>
    </Container>
  );
};

const modalStyle = {
  position: 'absolute',
  borderRadius: '4px',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 304,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

interface ModalProps {
  loadingText?: string;
  successText?: string;
  open: boolean;
  loading: boolean;
  error: any;
}

export const Modal = ({ loadingText = 'Цаг товлогдож байна...', successText = 'Уулзалтын цаг товлогдлоо.', open, loading, error }: ModalProps) => {
  if (error) {
    return (
      <MUIModal open={open} sx={{ height: '100vh' }}>
        <Box sx={modalStyle} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <>
            <Lottie animationData={errorAnimation} style={{ height: 80 }} loop={false} />
            <Typography variant="bodyM" mt={3} mb={0.5} fontWeight={590} sx={{ mt: 0.5 }}>
              Алдаа гарлаа
            </Typography>
            <Typography variant="bodyS">{error?.message}</Typography>
          </>
        </Box>
      </MUIModal>
    );
  }

  return (
    <MUIModal open={open} sx={{ height: '100vh' }}>
      <Box sx={modalStyle} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        {loading ? (
          <>
            <CircularProgress />
            <Typography variant="bodyS" mt={3} mb={0.5}>
              Түр хүлээнэ үү
            </Typography>
            <Typography variant="bodyM" fontWeight={590} sx={{ mt: 0.5 }}>
              {loadingText}
            </Typography>
          </>
        ) : (
          <>
            <Lottie animationData={successAnimation} style={{ height: 80 }} />
            <Typography variant="bodyM" mt={3} mb={0.5} fontWeight={590} sx={{ mt: 0.5 }}>
              Амжилттай
            </Typography>
            <Typography variant="bodyS">{successText}</Typography>
          </>
        )}
      </Box>
    </MUIModal>
  );
};
