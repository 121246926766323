export const MessageIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 24.8V32C0 36.4183 3.58172 40 8 40H15.2V38.5H8C4.41015 38.5 1.5 35.5899 1.5 32V24.8H0ZM0 15.2H1.5V8C1.5 4.41015 4.41015 1.5 8 1.5H15.2V0H8C3.58172 0 0 3.58172 0 8V15.2ZM24.8 0V1.5H32C35.5899 1.5 38.5 4.41015 38.5 8V15.2H40V8C40 3.58172 36.4183 0 32 0H24.8ZM40 24.8H38.5V32C38.5 35.5899 35.5899 38.5 32 38.5H24.8V40H32C36.4183 40 40 36.4183 40 32V24.8Z"
        fill="#111111"
      />
      <path
        d="M21.5 23V25C21.5 25.2652 21.3946 25.5196 21.2071 25.7071C21.0196 25.8946 20.7652 26 20.5 26H13.5L10.5 29V19C10.5 18.7348 10.6054 18.4804 10.7929 18.2929C10.9804 18.1054 11.2348 18 11.5 18H13.5M28.5 22L25.5 19H18.5C18.2348 19 17.9804 18.8946 17.7929 18.7071C17.6054 18.5196 17.5 18.2652 17.5 18V12C17.5 11.7348 17.6054 11.4804 17.7929 11.2929C17.9804 11.1054 18.2348 11 18.5 11H27.5C27.7652 11 28.0196 11.1054 28.2071 11.2929C28.3946 11.4804 28.5 11.7348 28.5 12V22Z"
        stroke="#111111"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
