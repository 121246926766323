import { Box } from '@mui/material';
import { Dispatch, ReactElement, SetStateAction, createContext, useContext, useState } from 'react';

export interface EnrollmentDataProps {
  progress: number;
  forWho?: string;
  curriculum?: string;
  shift?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  facebookName?: string;
  email?: string;
  emergencyContact?: string;
  emergencyContactPhoneNumber?: string;
}
export interface EnrollmentContextProps {
  enrollmentData?: EnrollmentDataProps;
  setEnrollmentData?: Dispatch<SetStateAction<EnrollmentDataProps>>;
}

export const EnrollmentContext = createContext<EnrollmentContextProps>({});

export const EnrollmentContextProvider = ({ children }: { children: ReactElement }) => {
  const [enrollmentData, setEnrollmentData] = useState<EnrollmentDataProps>({ progress: -1 });

  return (
    <EnrollmentContext.Provider value={{ enrollmentData, setEnrollmentData }}>
      {children}
      <Box
        id="recaptcha-container"
        sx={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          overflow: 'hidden',
        }}
      ></Box>
    </EnrollmentContext.Provider>
  );
};

export const useEnrollmentContext = () => useContext(EnrollmentContext);
