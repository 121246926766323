import { Modal, Stack } from '@mui/material';
import { ReactNode } from 'react';

export interface ConfirmModalProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
}
export const ConfirmModal = ({ open, onClose, children }: ConfirmModalProps) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      slotProps={{
        backdrop: {
          sx: {
            height: {
              sm: '100vh',
              md: '100vh',
            },
          },
        },
      }}
    >
      <Stack
        alignItems={'center'}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          gap: 2,
          bgcolor: 'white',
          boxShadow: 24,
          p: ({ spacing }) => ({
            sm: `${spacing(3)} ${spacing(2.5)}`,
          }),
          borderRadius: 2,
        }}
      >
        {children}
      </Stack>
    </Modal>
  );
};
