export const OfficeIcon = () => {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="40" cy="40" r="39.5" fill="#1AC74A" fillOpacity="0.1" stroke="#1AC74A" />
      <g clipPath="url(#clip0_4088_5285)">
        <path d="M25 55H55" stroke="#1AC74A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M35 33.333H36.6667" stroke="#1AC74A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M35 40H36.6667" stroke="#1AC74A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M35 46.667H36.6667" stroke="#1AC74A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M43.334 33.333H45.0007" stroke="#1AC74A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M43.334 40H45.0007" stroke="#1AC74A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M43.334 46.667H45.0007" stroke="#1AC74A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M28.334 55V28.3333C28.334 27.4493 28.6852 26.6014 29.3103 25.9763C29.9354 25.3512 30.7833 25 31.6673 25H48.334C49.218 25 50.0659 25.3512 50.691 25.9763C51.3161 26.6014 51.6673 27.4493 51.6673 28.3333V55"
          stroke="#1AC74A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4088_5285">
          <rect width="40" height="40" fill="white" transform="translate(20 20)" />
        </clipPath>
      </defs>
    </svg>
  );
};
