import { Loading, Selector } from '@components';
import {
  EnrollmentForWhoTypes,
  EnrollmentProgramTypes,
  GetStudentDetailsQuery,
  useCreateEnrollmentProfileMutation,
  useGetEnrollmentProgramDetailsQuery,
  useGetStudentProgramDetailsQuery,
  useUpdateStudentDetailMutation,
} from '@generated';
import { useMediaQuery } from '@hooks';
import { Button, Modal, Stack, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { useEnrollmentContext } from './context';
import arrowRoudedAnimation from '../../assets/lottie/arrow-rounded.json';
import dynamic from 'next/dynamic';

const Lottie = dynamic(() => import('lottie-react'), { ssr: false });

interface ShiftPopUpProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  entrantData: GetStudentDetailsQuery;
  onClose?: () => Promise<void>;
}

const Content = ({ setOpen, entrantData, onClose }: ShiftPopUpProps) => {
  const { enrollment } = entrantData?.getStudentDetails || {};
  const { data: studentProgram } = useGetStudentProgramDetailsQuery({ variables: { enrollmentId: enrollment && enrollment[0] } });
  const { data, loading } = useGetEnrollmentProgramDetailsQuery();
  const [createEnrollment] = useCreateEnrollmentProfileMutation();
  const [updateStudent] = useUpdateStudentDetailMutation();
  const programDetails = data?.getEnrollmentProgramDetails.find(({ program }) => program === studentProgram?.getStudentProgramDetails?.program);
  const shiftData = programDetails?.result.map((cur, index) => ({
    label: `${index + 1}-ээлж`,
    subLabel: cur.time,
    helperText: `Үлдсэн: ${cur.leftSeatsCount}`,
    value: cur.time,
    disableText: Number(cur.leftSeatsCount) <= 0 && 'Дүүрсэн',
    disabled: Number(cur.leftSeatsCount) <= 0,
  }));
  const isDesktop = useMediaQuery('lg');
  const { setEnrollmentData, enrollmentData } = useEnrollmentContext();
  const isSelected = enrollmentData?.shift !== undefined;

  const onChange = (newValue: string) => {
    setEnrollmentData((prev) => ({ ...prev, shift: newValue }));
  };

  const handleSave = async () => {
    const uid = localStorage.getItem('uid');
    const {
      data: { createEnrollmentProfile: enrollmentId },
    } = await createEnrollment({
      variables: {
        forWho: studentProgram?.getStudentProgramDetails?.forWho as EnrollmentForWhoTypes,
        program: studentProgram?.getStudentProgramDetails?.program as EnrollmentProgramTypes,
        time: enrollmentData.shift,
      },
    });
    await updateStudent({
      variables: {
        studentId: uid,
        details: { enrollment: [enrollmentId] },
      },
    });

    setOpen(false);

    await onClose();
  };

  if (loading)
    return (
      <Stack alignItems="center">
        <Loading />
      </Stack>
    );

  return (
    <Stack alignItems="center">
      <Stack alignItems="center" spacing={1}>
        <Lottie
          animationData={arrowRoudedAnimation}
          loop
          style={{
            width: '64px',
            height: '64px',
          }}
        />
        <Typography typography={{ sm: 'displayS', md: 'displayM', lg: 'displayL' }} fontWeight={800}>
          Та өөр ээлж сонгоно уу.
        </Typography>
        <Typography variant="bodyM" textAlign="center" maxWidth={640} color="#393939">
          Таны сонгосон ээлж дүүрсэн байна.
        </Typography>
      </Stack>
      <Stack py={6}>
        <Selector type="grid" columns={isDesktop ? 2 : 1} values={shiftData} onChange={onChange} />{' '}
      </Stack>
      <Button
        variant="contained"
        sx={{
          width: 200,
        }}
        disabled={!isSelected}
        onClick={handleSave}
      >
        Хадгалах
      </Button>
    </Stack>
  );
};

export const ShiftPopUp = (props: ShiftPopUpProps) => {
  const { open } = props;

  return (
    <Modal
      open={open}
      slotProps={{
        backdrop: {
          sx: {
            height: {
              sm: 'auto',
              md: '100vh',
            },
          },
        },
      }}
    >
      <Stack
        alignItems="center"
        position="absolute"
        width="100%"
        maxWidth={800}
        margin="auto"
        borderRadius={2}
        bgcolor="white"
        top="50%"
        left="50%"
        sx={{
          transform: 'translate(-50%, -50%)',
          boxShadow: 24,
          p: ({ spacing }) => ({
            sm: `${spacing(4.5)} ${spacing(1)}`,
            md: `${spacing(4.5)} ${spacing(10)}`,
          }),
        }}
      >
        <Content {...props} />
      </Stack>
    </Modal>
  );
};
