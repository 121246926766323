import { Components } from '@mui/material';
import { createTheme, Theme } from '@mui/material/styles';
import { breakpoints } from './breakpoints';

const theme = createTheme({
  breakpoints,
});
export const components: Components<Omit<Theme, 'components'>> = {
  MuiCssBaseline: {},
  MuiUseMediaQuery: {
    defaultProps: {
      noSsr: true,
    },
  },
  MuiContainer: {
    styleOverrides: {
      root: {
        padding: `0 !important`,
        [theme.breakpoints.up('sm')]: {
          maxWidth: `calc(100% - 30px)`,
        },
        [theme.breakpoints.up('md')]: {
          maxWidth: `calc(100% - 48px)`,
        },
        [theme.breakpoints.up('lg')]: {
          maxWidth: `1312px`,
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: '2px',
        boxShadow: 'none',
        padding: '13px 24px 13px 24px',
        textTransform: 'none',
        ':focus': {
          boxShadow: 'none',
        },
        ':hover': {
          backgroundColor: 'rgba(250, 250, 250, 0.1)',
          boxShadow: 'none',
        },
      },
    },
    variants: [
      {
        props: { variant: 'contained' },
        style: {
          color: 'white',
        },
      },
    ],
  },
};
