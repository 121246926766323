import { Box, Stack } from '@mui/material';
import { FC, PropsWithChildren, createContext, useCallback, useContext, useMemo, useState } from 'react';

interface ShowModalProps {
  onModalOpen: () => void;
  component: FC<unknown> | null;
}

interface ModalContextProps {
  showModal: (ShowModalProps: ShowModalProps) => void;
  hideModal?: () => void;
  modal?: FC<unknown> | null;
  props?: unknown;
}

interface UseModalProps {
  component: FC<unknown> | null;
  onModalOpen?: () => void;
  props?: unknown;
}

export const ModalContext = createContext<ModalContextProps>({} as ModalContextProps);

export const ModalProvider = ({ children }: PropsWithChildren) => {
  const [modal, setModal] = useState<(props: unknown) => JSX.Element>(null);

  const showModal = ({ onModalOpen, component }: ShowModalProps) => {
    setModal(component);

    if (onModalOpen) onModalOpen();
  };

  const hideModal = () => {
    setModal(null);
  };

  const value = useMemo(() => ({ modal, hideModal, showModal }), [modal]);

  return (
    <ModalContext.Provider value={value}>
      <ModalContainer>{children}</ModalContainer>
    </ModalContext.Provider>
  );
};

const ModalContainer = ({ children }: PropsWithChildren) => {
  const { modal: Modal, props } = useContext(ModalContext);

  return (
    <Box width="fit-content" height="fit-content" position="relative">
      {children}
      {Modal && (
        <Stack width="100vw" height="100vh" position="fixed" top={0} left={0} zIndex={999} justifyContent="center" alignItems="center" bgcolor="rgba(0, 0, 0, 0.5))">
          {/* {modal(props)} */}
          {/* <Modal /> */}
        </Stack>
      )}
    </Box>
  );
};

export const useModal = (props: UseModalProps) => {
  const { component, onModalOpen, props: modalProps } = props;
  const { showModal, hideModal } = useContext(ModalContext);

  const showModalCallback = useCallback(() => showModal({ onModalOpen, component }), [showModal, component, onModalOpen]);

  const hideModalCallback = useCallback(() => hideModal(), [hideModal]);

  return {
    showModal: showModalCallback,
    hideModal: hideModalCallback,
  };
};
