import { useState } from 'react';
import { ColumnSelector } from './ColumnSelector';
import { RowSelector } from './RowSelector';
import { SelectorProps } from './props';

export const Selector = (props: SelectorProps) => {
  const { type, onChange: defaultOnChange, value = '', ...others } = props;
  const [selected, setSelected] = useState<string>(value);

  const onChange = (newValue: string) => {
    if (newValue === selected) {
      setSelected('');
      defaultOnChange && defaultOnChange(undefined);

      return;
    }

    setSelected(newValue);
    defaultOnChange && defaultOnChange(newValue);
  };

  if (type === 'column') return <ColumnSelector {...others} selected={selected} onChange={onChange} />;

  if (!type || type === 'grid' || type === 'row') return <RowSelector {...others} selected={selected} onChange={onChange} type={type} />;

  return <div />;
};
