import { Typography, TypographyProps } from '@mui/material';

export const ExpanedTypography = ({ color = 'primary', children, fontSize, ...other }: Omit<TypographyProps, 'fontSize'> & { fontSize?: Record<string, number> }) => {
  return (
    <Typography
      textTransform="uppercase"
      fontSize={fontSize ? { sm: fontSize.sm * 1.3, md: fontSize.md * 1.3, lg: fontSize.lg * 1.3 } : undefined}
      lineHeight="80%"
      fontWeight={700}
      color={color}
      sx={{ transform: 'scaleY(0.76) !important' }}
      {...other}
    >
      {children}
    </Typography>
  );
};
