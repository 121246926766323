import { BreakpointsOptions } from '@mui/material';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false;
    sm: true;
    md: true;
    lg: true;
    xl: false;
  }
}

export const breakpoints: BreakpointsOptions = {
  values: {
    sm: 0,
    md: 720,
    lg: 1440,
  },
};
