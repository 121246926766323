import React from 'react';
import { LogoProps } from '.';

export const MessageIcon = ({ width, height, color }: LogoProps) => (
  <svg width={width || '40'} height={height || '40'} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 24.8V32C0 36.4183 3.58172 40 8 40H15.2V38.5H8C4.41015 38.5 1.5 35.5899 1.5 32V24.8H0ZM0 15.2H1.5V8C1.5 4.41015 4.41015 1.5 8 1.5H15.2V0H8C3.58172 0 0 3.58172 0 8V15.2ZM24.8 0V1.5H32C35.5899 1.5 38.5 4.41015 38.5 8V15.2H40V8C40 3.58172 36.4183 0 32 0H24.8ZM40 24.8H38.5V32C38.5 35.5899 35.5899 38.5 32 38.5H24.8V40H32C36.4183 40 40 36.4183 40 32V24.8Z"
      fill={color || '#111111'}
    />
    <path
      d="M15.5 17H23.5M15.5 21H21.5M11.5 29V16C11.5 15.2044 11.8161 14.4413 12.3787 13.8787C12.9413 13.3161 13.7044 13 14.5 13H24.5C25.2956 13 26.0587 13.3161 26.6213 13.8787C27.1839 14.4413 27.5 15.2044 27.5 16V22C27.5 22.7956 27.1839 23.5587 26.6213 24.1213C26.0587 24.6839 25.2956 25 24.5 25H15.5L11.5 29Z"
      stroke={color || '#111111'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
