import React from 'react';
import { LogoProps } from '../';

export const PineconeStrokeLogo = ({ width, height, color }: LogoProps) => (
  <svg width={width || '642'} height={height || '520'} viewBox="1173.722 -573.999 626.525 520.582" fill="none">
    <path
      d="M 539.043 46.139 L 539.478 45.893 L 539.043 46.138 C 523.216 18.058 492.26 0.5 458.562 0.5 L 367.337 0.5 L 367.337 42.83 C 384.243 42.988 399.756 51.85 407.743 66.016 L 494.064 218.968 C 501.321 231.787 504.938 245.881 504.938 260 C 504.938 274.119 501.321 288.201 494.064 301.032 L 407.743 453.984 C 399.756 468.15 384.243 477.012 367.337 477.171 L 367.337 519.5 L 458.562 519.5 C 492.26 519.5 523.216 501.942 539.043 473.862 L 539.461 474.098 L 539.043 473.861 L 625.389 320.933 C 636.118 301.868 641.5 280.945 641.5 260 C 641.5 239.055 636.118 218.132 625.389 199.067 L 539.043 46.139 Z"
      stroke="black"
      fill="transparent"
      transform="matrix(1, 0, 0, 1, 1158.746338, -572.917297)"
    />
    <path
      d="M 1346.228 -527.938 L 1346.663 -528.184 L 1346.228 -527.939 C 1330.401 -556.019 1299.445 -573.577 1265.747 -573.577 L 1174.522 -573.577 L 1174.522 -531.247 C 1191.428 -531.089 1206.941 -522.227 1214.928 -508.061 L 1301.249 -355.109 C 1308.506 -342.29 1312.123 -328.196 1312.123 -314.077 C 1312.123 -299.958 1308.506 -285.876 1301.249 -273.045 L 1214.928 -120.093 C 1206.941 -105.927 1191.428 -97.065 1174.522 -96.906 L 1174.522 -54.577 L 1265.747 -54.577 C 1299.445 -54.577 1330.401 -72.135 1346.228 -100.215 L 1346.646 -99.979 L 1346.228 -100.216 L 1432.574 -253.144 C 1443.303 -272.209 1448.685 -293.132 1448.685 -314.077 C 1448.685 -335.022 1443.303 -355.945 1432.574 -375.01 L 1346.228 -527.938 Z"
      stroke="black"
      fill="transparent"
      transform="matrix(-0.999995, 0.003088, -0.003088, -0.999995, 2622.230957, -632.20282)"
    />
  </svg>
);
