import React from 'react';

export function BookIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 20H6C5.46957 20 4.96086 19.7893 4.58579 19.4142C4.21071 19.0391 4 18.5304 4 18M4 18V6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H18V9M4 18C4 17.4696 4.21071 16.9609 4.58579 16.5858C4.96086 16.2107 5.46957 16 6 16H13M15 19L18 22M18 22L21 19M18 22V13"
        stroke="#18BA51"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
