import { Stack, Typography } from '@mui/material';
import Link from 'next/link';

export const SimpleFooter = () => {
  return (
    <Stack width="100vw" alignItems="center" mt={{ sm: 10, md: 0 }}>
      <Stack alignItems="center" spacing={2}>
        <Link href="/faq" style={{ textDecorationColor: 'black' }}>
          <Typography variant="caption" fontWeight="600" color="black" data-cy="Simple-Footer-Faq">
            Түгээмэл асуулт
          </Typography>
        </Link>

        <Typography variant="bodyS" color="grey.500">
          © 2023 Pinecone Academy
        </Typography>
      </Stack>
    </Stack>
  );
};
