import { useEffect, useRef, useState } from 'react';

export const useSlider = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollDisabledLeft, setScrollDisabledLeft] = useState(true);
  const [scrollDisabledRight, setScrollDisabledRight] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const handleScroll = (scrollOffset: number) => {
    setScrollPosition((prev) => prev + scrollOffset);
  };

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleScroll = () => {
      const maxScrollLeft = container.scrollWidth - container.clientWidth;
      setScrollDisabledLeft(container.scrollLeft === 0);
      setScrollDisabledRight(container.scrollLeft === maxScrollLeft);
    };
    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const options = {
    scrollDisabledLeft,
    scrollDisabledRight,
    scrollPosition,
  };

  return {
    containerRef,
    options,
    handleScroll,
  };
};
