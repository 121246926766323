import { ArrowLeftIcon } from '@assets/icons';
import { useMediaQuery } from '@hooks';
import { Box, IconButton, Stack } from '@mui/material';
import { PropsWithChildren, useEffect, useMemo } from 'react';
import { ExpanedTypography } from './expanded-typography';

interface SliderLabelProps {
  label?: string;
  scrollDisabledLeft: boolean;
  scrollDisabledRight: boolean;
  handleScroll: (offset: number) => void;
}

export const SliderLabel = (props: SliderLabelProps) => {
  const isMobile = useMediaQuery('sm');
  const isTablet = useMediaQuery('md');
  const { label, scrollDisabledLeft, scrollDisabledRight, handleScroll } = props;

  const cardWidth = useMemo(() => {
    return isTablet ? 230 : isMobile ? 288 : 304;
  }, [isTablet, isMobile]);

  const cardGap = useMemo(() => {
    return isTablet ? 16 : isMobile ? 8 : 24;
  }, [isTablet, isMobile]);

  return (
    <Stack direction="row" justifyContent="space-between">
      <ExpanedTypography color="black" fontSize={{ sm: 24, md: 28, lg: 48 }}>
        {label}
      </ExpanedTypography>
      <Box sx={{ display: { sm: 'none', md: 'block' } }}>
        <IconButton disabled={scrollDisabledLeft} onClick={() => handleScroll(-(cardWidth + cardGap))} aria-label="Өмнөх">
          <ArrowLeftIcon direction="left" color={scrollDisabledLeft ? 'grey' : 'black'} />
        </IconButton>
        <IconButton disabled={scrollDisabledRight} onClick={() => handleScroll(cardWidth + cardGap)} aria-label="Дараах">
          <ArrowLeftIcon color={scrollDisabledRight ? 'grey' : 'black'} />
        </IconButton>
      </Box>
    </Stack>
  );
};

interface SliderProps extends PropsWithChildren {
  scrollPosition?: number;
  containerRef: any;
}

export function Slider({ scrollPosition, containerRef, children, ...other }: SliderProps) {
  useEffect(() => {
    if (containerRef) {
      containerRef?.current.scroll({
        left: scrollPosition,
        behavior: 'smooth',
      });
    }
  }, [containerRef, scrollPosition]);

  return (
    <Stack gap={6} {...other}>
      <Stack
        ref={containerRef}
        flexWrap="nowrap"
        width="maxContent"
        direction="row"
        justifyContent="space-between"
        sx={{
          gap: {
            lg: 3,
            md: 2,
            sm: 2,
          },
          overflow: 'scroll',
          transition: 'transform 0.5s ease',
          '::-webkit-scrollbar': {
            display: 'none',
          },
          scrollSnapType: 'x mandatory',
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
}
