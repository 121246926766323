import { Stack, Typography } from '@mui/material';
import { ArrowDownInsideCircleIcon } from '../assets/icons';

type PropsType = {
  onClick?: () => void;
  color?: string;
  datacy?: string;
};

export const ScrollToExplore = ({ onClick = null, color = 'white', datacy }: PropsType) => {
  return (
    <Stack
      width="fit-content"
      alignItems="center"
      gap="12px"
      onClick={() => {
        onClick && onClick();
      }}
      sx={{
        cursor: 'pointer',
      }}
    >
      <ArrowDownInsideCircleIcon color={color} width={48} height={48} />
      <Typography width="fit-content" variant="bodyM" color={color} data-cy={datacy}>
        Scroll to Explore
      </Typography>
    </Stack>
  );
};
