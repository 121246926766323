import React from 'react';
import { LogoProps } from '..';

export const SuccessClockLogo = ({ width, height, color }: LogoProps) => (
  <svg width={width || '40'} height={height || '40'} viewBox="0 0 40 40" fill="none">
    <path
      d="M16.6667 25L20 20V11.6667M35 20C35 28.2843 28.2843 35 20 35C11.7157 35 5 28.2843 5 20C5 11.7157 11.7157 5 20 5C28.2843 5 35 11.7157 35 20Z"
      stroke={color || '#111111'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
