import React from 'react';
import { LogoProps } from '..';

export const PineconeLeftStrokeLogo = ({ width, height, color }: LogoProps) => (
  <svg width={width || '97'} height={height || '181'} viewBox="0 0 97 181" fill="none">
    <path
      opacity="0.5"
      d="M90.6973 15.4744V1.08041L62.231 1C40.9214 1.88451 35.6142 14.1073 29.9852 25.2848L5.29834 71.3616C-2.48567 86.4793 2.055 103.286 5.29834 109.799L32.076 159.816C40.7109 177.566 51.8103 178.939 60.2363 179.982L60.3815 180H90.6973V165.606C82.077 165.091 77.8311 159.173 76.7858 156.278L48.7215 104.009C43.0122 92.3495 46.5504 80.7432 48.7215 77.1514L76.3837 25.4456C79.9862 17.2757 87.4271 15.394 90.6973 15.4744Z"
      stroke={color || 'black'}
    />
  </svg>
);
