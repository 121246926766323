import React from 'react';
import { LogoProps } from '..';

export const EnrollmentCardIcon = ({ width, height, color }: LogoProps) => (
  <svg width={width || '40'} height={height || '40'} viewBox="0 0 40 40" fill="none">
    <rect opacity="0.1" x="0.75" y="0.75" width="38.5" height="38.5" rx="7.25" stroke={color || '#111111'} strokeWidth="1.5" strokeDasharray="24 16" />
    <path
      d="M22.4996 12H23.4996C23.7648 12 24.0191 12.1054 24.2067 12.2929C24.3942 12.4804 24.4996 12.7348 24.4996 13V16.5M27.4996 14C27.7636 14.112 28.0196 14.217 28.2676 14.315C28.5117 14.4186 28.7047 14.6149 28.8041 14.8607C28.9034 15.1066 28.9011 15.3819 28.7976 15.626L26.4996 21M11.1036 15.197L18.2416 12.088C18.3594 12.0375 18.4861 12.0111 18.6142 12.0104C18.7423 12.0096 18.8693 12.0346 18.9877 12.0837C19.1061 12.1328 19.2134 12.2051 19.3034 12.2964C19.3933 12.3876 19.4641 12.496 19.5116 12.615L24.4356 24.517C24.5369 24.7585 24.5398 25.0299 24.4438 25.2735C24.3478 25.5171 24.1604 25.7136 23.9216 25.821L16.7846 28.93C16.6667 28.9806 16.5399 29.0072 16.4117 29.008C16.2834 29.0088 16.1563 28.9839 16.0378 28.9348C15.9194 28.8857 15.8119 28.8133 15.7219 28.722C15.6318 28.6306 15.561 28.5222 15.5136 28.403L10.5896 16.5C10.4886 16.2585 10.4857 15.9871 10.5817 15.7436C10.6777 15.5001 10.8649 15.3046 11.1036 15.197Z"
      stroke={color || '#111111'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
