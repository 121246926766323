import { Box } from '@mui/material';

export const SlideUpOnScroll = ({ children, delay = 0 }) => {
  return (
    <Box overflow="hidden">
      <Box
        data-scroll-class="slide-up-on-scroll"
        data-scroll
        className="slide-up-on-scroll-base"
        sx={{
          transitionDelay: `${delay}ms !important`,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
