import React from 'react';
import { LogoProps } from '..';

export const EnrollmentNewsIcon = ({ width, height, color }: LogoProps) => (
  <svg width={width || '40'} height={height || '40'} viewBox="0 0 40 40" fill="none">
    <rect opacity="0.1" x="0.75" y="0.75" width="38.5" height="38.5" rx="7.25" stroke={color || '#111111'} strokeWidth="1.5" strokeDasharray="24 16" />
    <path
      d="M22.5 16H24.5M22.5 20H24.5M14.5 24H24.5M13.5 12H25.5C27.1569 12 28.5 13.3431 28.5 15V25C28.5 26.6569 27.1569 28 25.5 28H13.5C11.8431 28 10.5 26.6569 10.5 25V15C10.5 13.3431 11.8431 12 13.5 12ZM18.5 18C18.5 19.1046 17.6046 20 16.5 20C15.3954 20 14.5 19.1046 14.5 18C14.5 16.8954 15.3954 16 16.5 16C17.6046 16 18.5 16.8954 18.5 18Z"
      stroke={color || '#111111'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
