import { Accordion } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledAccordion = styled(Accordion)(({ theme }) => {
  return {
    borderRadius: '6px',
    boxShadow: 'none',
    minWidth: 'none',
    '&:before': {
      display: 'none',
    },
    borderColor: `${theme.palette.grey[100]} !important`,
    paddingLeft: '0px',
    '& .Mui-expanded': {
      margin: '0px',
    },
    '& .MuiAccordionSummary-root': {
      padding: `0 ${theme.spacing(3)}`,
      '&::after': {
        content: `""`,
        position: 'absolute',
        width: `calc(100% - ${theme.spacing(6)})`,
        bottom: '-1px',
        height: '1px',
        backgroundColor: theme.palette.grey[100],
      },
    },
    '.MuiAccordionDetails-root': {
      padding: theme.spacing(3),
    },
    '.MuiAccordionSummary-content': {
      margin: `${theme.spacing(3)} 0`,
    },
  };
});
