import React from 'react';
import { LogoProps } from '..';

export const SuccessFileLogo = ({ width, height, color }: LogoProps) => (
  <svg width={width || '41'} height={height || '40'} viewBox="0 0 41 40" fill="none">
    <path
      d="M23.832 5V11.6667C23.832 12.1087 24.0076 12.5326 24.3202 12.8452C24.6327 13.1577 25.0567 13.3333 25.4987 13.3333H32.1654M23.832 5H12.1654C11.2813 5 10.4335 5.35119 9.80834 5.97631C9.18322 6.60143 8.83203 7.44928 8.83203 8.33333V31.6667C8.83203 32.5507 9.18322 33.3986 9.80834 34.0237C10.4335 34.6488 11.2813 35 12.1654 35H28.832C29.7161 35 30.5639 34.6488 31.1891 34.0237C31.8142 33.3986 32.1654 32.5507 32.1654 31.6667V13.3333M23.832 5L32.1654 13.3333"
      stroke={color || '#111111'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
