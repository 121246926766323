import { Box, Button, Stack, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import Link from 'next/link';

export const Sidebar = ({ open = false, setOpen }) => {
  return (
    <motion.nav
      style={{
        position: 'fixed',
        backgroundColor: 'white',
        top: '-100vh',
        zIndex: '100',
      }}
      animate={{
        top: open ? 0 : '-100vh',
      }}
      transition={{
        duration: 0.3,
      }}
    >
      <motion.div
        animate={{
          opacity: open ? 1 : 0,
          y: open ? 0 : 20,
        }}
        transition={{
          delay: 0.4,
          duration: 0.2,
        }}
      >
        <Stack
          sx={{
            width: '100vw',
            height: '100vh',
          }}
        >
          <Stack justifyContent={'center'} direction={'column'} flex={1} gap={'28px'}>
            <Link href="/">
              <Button onClick={() => setOpen(false)} sx={{ justifyContent: 'flex-start', paddingY: 0 }} data-cy="SideBar-Home-Button">
                <Typography lineHeight={'100%'} fontSize={{ lg: '62.4px', md: '36.4px', sm: '31.2px' }} textTransform="uppercase" fontWeight={500} color="#000000" sx={{ transform: 'scaleY(0.76)' }}>
                  Нүүр
                </Typography>
              </Button>
            </Link>

            <Box
              marginX={{ sm: '24px', md: '32px' }}
              height={'1px'}
              sx={{
                background: 'rgba(0, 0, 0, 0.1)',
              }}
            />
            <Stack m={'0px 24px'}>
              <Typography lineHeight={'100%'} fontSize={{ lg: '62.4px', md: '36.4px', sm: '31.2px' }} textTransform="uppercase" fontWeight={500} color="#000000" sx={{ transform: 'scaleY(0.76)' }}>
                Хөтөлбөр
              </Typography>
              <Stack direction={'column'} gap={'20px'} mt={3}>
                <Link href="/hop/">
                  <Button onClick={() => setOpen(false)} sx={{ justifyContent: 'flex-start', paddingY: 0 }} data-cy="SideBar-Hop-Button">
                    <Typography lineHeight={'100%'} fontSize={{ lg: '62.4px', md: '36.4px', sm: '24px' }} textTransform="uppercase" fontWeight={500} color="#000000" sx={{ transform: 'scaleY(0.76)' }}>
                      HOP
                    </Typography>
                    <Typography
                      variant="bodyM"
                      color="#393939"
                      ml={1}
                      sx={{
                        opacity: 0.5,
                      }}
                    >
                      13-18 нас
                    </Typography>
                  </Button>
                </Link>
                <Link href="/leap/">
                  <Button onClick={() => setOpen(false)} sx={{ justifyContent: 'flex-start', paddingY: 0 }} data-cy="SideBar-Leap-Button">
                    <Typography lineHeight={'100%'} fontSize={{ lg: '62.4px', md: '36.4px', sm: '24px' }} textTransform="uppercase" fontWeight={500} color="#000000" sx={{ transform: 'scaleY(0.76)' }}>
                      LEAP
                    </Typography>
                    <Typography
                      variant="bodyM"
                      color="#393939"
                      ml={1}
                      sx={{
                        opacity: 0.5,
                      }}
                    >
                      18-с дээш
                    </Typography>
                  </Button>
                </Link>
              </Stack>
            </Stack>
            <Box
              marginX={{ sm: '24px', md: '32px' }}
              height={'1px'}
              sx={{
                background: 'rgba(0, 0, 0, 0.1)',
              }}
            />
            <Link href="/about-us/">
              <Button onClick={() => setOpen(false)} sx={{ justifyContent: 'flex-start', paddingY: 0 }} data-cy="SideBar-About-Us-Button">
                <Typography lineHeight={'100%'} fontSize={{ lg: '62.4px', md: '36.4px', sm: '31.2px' }} textTransform="uppercase" fontWeight={500} color="#000000" sx={{ transform: 'scaleY(0.76)' }}>
                  Бидний тухай
                </Typography>
              </Button>
            </Link>
          </Stack>
          <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
            <Button target="_blank" href={'https://www.facebook.com/pinecone.academy.mongolia'} title="Pinecone Instagram">
              <Typography variant="bodyM" color="#000000">
                Facebook
              </Typography>
            </Button>
            <Box
              width={'1px'}
              sx={{
                background: 'rgba(0, 0, 0, 0.1)',
              }}
            />
            <Button target="_blank" href={'https://www.instagram.com/pineconemongolia/'} title="Pinecone Instagram">
              <Typography variant="bodyM" color="#000000">
                Instagram
              </Typography>
            </Button>
          </Stack>
        </Stack>
      </motion.div>
    </motion.nav>
  );
};

export default Sidebar;
