/* tslint:disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Currency: any;
  Date: any;
  Email: any;
  IsoWeek: any;
  Link: any;
  LocalDate: any;
  _Any: any;
  federation__FieldSet: any;
  link__Import: any;
};

export type ActiveClass = {
  __typename?: 'ActiveClass';
  activeClassCode?: Maybe<Scalars['String']>;
  classDay?: Maybe<Scalars['String']>;
  classStartingTime?: Maybe<Scalars['String']>;
  classTeachers?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ActiveStudents = {
  __typename?: 'ActiveStudents';
  about?: Maybe<Scalars['String']>;
  advantage?: Maybe<Array<Maybe<Scalars['String']>>>;
  age?: Maybe<Scalars['String']>;
  batchId?: Maybe<Scalars['String']>;
  classCode?: Maybe<Scalars['String']>;
  disadvantage?: Maybe<Array<Maybe<Scalars['String']>>>;
  education: Array<Maybe<EducationExperience>>;
  email?: Maybe<Scalars['String']>;
  emergency?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  github?: Maybe<Scalars['String']>;
  grade: Array<Maybe<Grade>>;
  lastName?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  nin?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profileImageUrl?: Maybe<Scalars['String']>;
  projectIntroduction?: Maybe<Scalars['String']>;
  schoolEmail?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  studentCode?: Maybe<Scalars['String']>;
  studentId?: Maybe<Scalars['String']>;
  teacherName?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  workExperience: Array<Maybe<WorkExperience>>;
};

export type AllActiveClass = {
  __typename?: 'AllActiveClass';
  activeClasses?: Maybe<Array<Maybe<ActiveClass>>>;
  count?: Maybe<Scalars['Int']>;
};

export type Contact = {
  __typename?: 'Contact';
  type: ContactType;
  value?: Maybe<Scalars['String']>;
};

export enum ContactType {
  EmailAddress = 'EMAIL_ADDRESS',
  Facebook = 'FACEBOOK',
  PhoneNumber = 'PHONE_NUMBER'
}

export type DiscoveryClassList = {
  __typename?: 'DiscoveryClassList';
  classDate?: Maybe<Scalars['String']>;
  classTime?: Maybe<Array<Maybe<Scalars['String']>>>;
  location?: Maybe<Scalars['String']>;
};

export enum DiscoveryClassRegisterTypes {
  Online = 'online',
  Onsite = 'onsite'
}

export type EducationExperience = {
  __typename?: 'EducationExperience';
  degree?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  major?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
};

export type EnrollmentDetails = {
  __typename?: 'EnrollmentDetails';
  forWho?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  program?: Maybe<Scalars['String']>;
  students?: Maybe<Array<Maybe<Scalars['String']>>>;
  time?: Maybe<Scalars['String']>;
};

export enum EnrollmentForWhoTypes {
  Others = 'OTHERS',
  Yourself = 'YOURSELF'
}

export type EnrollmentProgramDetails = {
  __typename?: 'EnrollmentProgramDetails';
  program?: Maybe<Scalars['String']>;
  result?: Maybe<Array<Maybe<EnrollmentProgramResult>>>;
  startDate?: Maybe<Scalars['String']>;
};

export type EnrollmentProgramResult = {
  __typename?: 'EnrollmentProgramResult';
  leftSeatsCount?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
};

export enum EnrollmentProgramTypes {
  HopProgram = 'HOP_PROGRAM',
  HopProgram_2 = 'HOP_PROGRAM_2',
  LeapProgram = 'LEAP_PROGRAM',
  LeapProgramSummer = 'LEAP_PROGRAM_SUMMER',
  LeapUxdesign = 'LEAP_UXDESIGN'
}

export type Grade = {
  __typename?: 'Grade';
  description?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type GuardianContact = {
  __typename?: 'GuardianContact';
  contacts?: Maybe<Array<Maybe<Contact>>>;
  relationship?: Maybe<Scalars['String']>;
  studentCode?: Maybe<Scalars['String']>;
};

export type InformationSource = {
  __typename?: 'InformationSource';
  name?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type MeetingDetails = {
  __typename?: 'MeetingDetails';
  date?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<MeetingTypes>;
};

export enum MeetingTypes {
  Office = 'OFFICE',
  Online = 'ONLINE'
}

export type Mutation = {
  __typename?: 'Mutation';
  addEmergencyContact?: Maybe<Scalars['String']>;
  createEnrollmentProfile?: Maybe<Scalars['String']>;
  createUserOnAzureActiveDirectory?: Maybe<Scalars['String']>;
  discoveryClassRegister?: Maybe<Scalars['String']>;
  registerStudentForAcademy?: Maybe<StudentDetails>;
  removeStudent?: Maybe<StudentDetails>;
  scheduleMeeting?: Maybe<Scalars['String']>;
  teardownDiscoveryClassRegistration?: Maybe<Scalars['String']>;
  teardownStudentRegistration?: Maybe<Scalars['String']>;
  updateEnrollment?: Maybe<Scalars['String']>;
  updateStudentDetail?: Maybe<StudentDetails>;
  updateStudentStatusById?: Maybe<ActiveStudents>;
};


export type MutationAddEmergencyContactArgs = {
  phone: Scalars['String'];
  who: Scalars['String'];
};


export type MutationCreateEnrollmentProfileArgs = {
  forWho: EnrollmentForWhoTypes;
  program: EnrollmentProgramTypes;
  time: Scalars['String'];
};


export type MutationCreateUserOnAzureActiveDirectoryArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mailNickname?: InputMaybe<Scalars['String']>;
};


export type MutationDiscoveryClassRegisterArgs = {
  classDate?: InputMaybe<Scalars['String']>;
  classTime?: InputMaybe<Scalars['String']>;
  emergencyContact?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
  registerType: DiscoveryClassRegisterTypes;
};


export type MutationRegisterStudentForAcademyArgs = {
  details?: InputMaybe<RegisterStudentDetailsInput>;
};


export type MutationRemoveStudentArgs = {
  studentId: Scalars['String'];
};


export type MutationScheduleMeetingArgs = {
  date: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  location: Scalars['String'];
  title: Scalars['String'];
  type: MeetingTypes;
};


export type MutationTeardownDiscoveryClassRegistrationArgs = {
  phoneNumber: Scalars['String'];
  typeOfRegister: Scalars['String'];
};


export type MutationTeardownStudentRegistrationArgs = {
  phoneNumber: Scalars['String'];
};


export type MutationUpdateEnrollmentArgs = {
  enrollmentId: Scalars['String'];
  forWho?: InputMaybe<EnrollmentForWhoTypes>;
  program?: InputMaybe<EnrollmentProgramTypes>;
  time?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateStudentDetailArgs = {
  details?: InputMaybe<UpdateStudentDetailsInput>;
  studentId: Scalars['String'];
};


export type MutationUpdateStudentStatusByIdArgs = {
  status: StudentStatusTypes;
  studentId: Scalars['String'];
};

export type Page = {
  __typename?: 'Page';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginationInput = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  _entities: Array<Maybe<_Entity>>;
  _service: _Service;
  getActiveStudentByStudentId?: Maybe<ActiveStudents>;
  getAllActiveClass?: Maybe<AllActiveClass>;
  getAllActiveStudents?: Maybe<StudentsPagination>;
  getAllEnrollmentDetails?: Maybe<Array<Maybe<EnrollmentDetails>>>;
  getAllScheduledMeetings?: Maybe<Array<Maybe<MeetingDetails>>>;
  getAllStudentDetails?: Maybe<Array<Maybe<StudentDetails>>>;
  getCouponCodeIsValid?: Maybe<IsValidCoupon>;
  getDiscoveryClassList?: Maybe<Array<Maybe<DiscoveryClassList>>>;
  getEnrollmentProgramDetails?: Maybe<Array<Maybe<EnrollmentProgramDetails>>>;
  getInformationSources?: Maybe<Array<Maybe<InformationSource>>>;
  getStudent?: Maybe<Student>;
  getStudentDetails?: Maybe<StudentDetails>;
  getStudentProgramDetails?: Maybe<EnrollmentDetails>;
  getStudentScheduledMeeting?: Maybe<MeetingDetails>;
  getStudentsByActiveClassCode?: Maybe<SearchStudentResponse>;
  getStudentsByBatchId?: Maybe<Array<Maybe<ActiveStudents>>>;
  searchStudentQuery?: Maybe<SearchStudentResponse>;
};


export type Query_EntitiesArgs = {
  representations: Array<Scalars['_Any']>;
};


export type QueryGetActiveStudentByStudentIdArgs = {
  studentId?: InputMaybe<Scalars['String']>;
};


export type QueryGetAllActiveStudentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
};


export type QueryGetAllScheduledMeetingsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAllStudentDetailsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCouponCodeIsValidArgs = {
  couponCode: Scalars['String'];
};


export type QueryGetStudentArgs = {
  studentQuery: StudentQueryInput;
};


export type QueryGetStudentDetailsArgs = {
  phone?: InputMaybe<Scalars['String']>;
  studentId?: InputMaybe<Scalars['String']>;
};


export type QueryGetStudentProgramDetailsArgs = {
  enrollmentId: Scalars['String'];
};


export type QueryGetStudentScheduledMeetingArgs = {
  studentId: Scalars['String'];
};


export type QueryGetStudentsByActiveClassCodeArgs = {
  activeClassCode?: InputMaybe<Scalars['String']>;
  paginationInput?: InputMaybe<PaginationInput>;
};


export type QueryGetStudentsByBatchIdArgs = {
  batchId?: InputMaybe<Scalars['String']>;
};


export type QuerySearchStudentQueryArgs = {
  paginationInput?: InputMaybe<PaginationInput>;
  query?: InputMaybe<StudentsQuery>;
};

export type RegisterStudentDetailsInput = {
  class?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emergency?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  enrollment?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  facebook?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  informationSource?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  meeting?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  paid?: InputMaybe<Scalars['Boolean']>;
  phone: Scalars['String'];
  studentCode?: InputMaybe<Scalars['String']>;
  transactionValue?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['String']>;
};

export type SearchStudentResponse = {
  __typename?: 'SearchStudentResponse';
  matches?: Maybe<Array<Maybe<StudentSearchParts>>>;
  pageInfo?: Maybe<Page>;
};

export type SocialLinks = {
  __typename?: 'SocialLinks';
  link?: Maybe<Scalars['Link']>;
  type: SocialType;
};

export enum SocialType {
  Facebook = 'FACEBOOK',
  Github = 'GITHUB',
  Instagram = 'INSTAGRAM'
}

export type Student = {
  __typename?: 'Student';
  activeClassCode?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  classCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  firstName?: Maybe<Scalars['String']>;
  guardianContacts?: Maybe<GuardianContact>;
  lastName?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['Boolean']>;
  schoolEmail?: Maybe<Scalars['Email']>;
  socialLinks?: Maybe<Array<Maybe<SocialLinks>>>;
  studentCode: Scalars['String'];
  studentContacts?: Maybe<Array<Maybe<Contact>>>;
};

export type StudentDetails = {
  __typename?: 'StudentDetails';
  batchId?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  classCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergency?: Maybe<Array<Maybe<Scalars['String']>>>;
  enrollment?: Maybe<Array<Maybe<Scalars['String']>>>;
  facebook?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  meeting?: Maybe<Array<Maybe<Scalars['String']>>>;
  paid?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  schoolEmail?: Maybe<Scalars['String']>;
  studentCode?: Maybe<Scalars['String']>;
  transactionValue?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

export enum StudentField {
  ActiveClassCode = 'ACTIVE_CLASS_CODE',
  Email = 'EMAIL',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  StudentCode = 'STUDENT_CODE'
}

export type StudentPayment = {
  __typename?: 'StudentPayment';
  activeClassCode?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  batchCode?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['Currency']>;
  description?: Maybe<Scalars['String']>;
  paymentDate?: Maybe<Scalars['Date']>;
  studentCode: Scalars['String'];
};

export type StudentQuery = {
  __typename?: 'StudentQuery';
  attendanceDateRange?: Maybe<Array<Maybe<Scalars['LocalDate']>>>;
  reportWeeks?: Maybe<Array<Maybe<Scalars['IsoWeek']>>>;
};

export type StudentQueryInput = {
  attendanceDateRange?: InputMaybe<Array<InputMaybe<Scalars['LocalDate']>>>;
  reportWeeks?: InputMaybe<Array<InputMaybe<Scalars['IsoWeek']>>>;
  studentCode: Scalars['String'];
};

export type StudentResponse = {
  __typename?: 'StudentResponse';
  student?: Maybe<Student>;
  studentCode: Scalars['String'];
  studentQuery?: Maybe<StudentQuery>;
};

export type StudentSearchPart = {
  __typename?: 'StudentSearchPart';
  activeClassCode?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  schoolEmail?: Maybe<Scalars['String']>;
  studentCode?: Maybe<Scalars['String']>;
};

export type StudentSearchParts = {
  __typename?: 'StudentSearchParts';
  activeClassCode?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  schoolEmail?: Maybe<Scalars['String']>;
  studentCode?: Maybe<Scalars['String']>;
};

export type StudentStatus = {
  __typename?: 'StudentStatus';
  status: Scalars['String'];
};

export enum StudentStatusTypes {
  Active = 'active',
  Dropout = 'dropout',
  Graduated = 'graduated',
  Terminated = 'terminated'
}

export type StudentsPagination = {
  __typename?: 'StudentsPagination';
  count?: Maybe<Scalars['Int']>;
  students?: Maybe<Array<Maybe<ActiveStudents>>>;
};

export type StudentsQuery = {
  facets?: InputMaybe<Array<InputMaybe<StudentField>>>;
  offset?: InputMaybe<Scalars['Int']>;
  searchQuery?: InputMaybe<Scalars['String']>;
};

export type UpdateStudentDetailsInput = {
  classCode?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emergency?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  enrollment?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  facebook?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  meeting?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  paid?: InputMaybe<Scalars['Boolean']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  schoolEmail?: InputMaybe<Scalars['String']>;
  studentCode?: InputMaybe<Scalars['String']>;
  transactionValue?: InputMaybe<Scalars['String']>;
};

export type WorkExperience = {
  __typename?: 'WorkExperience';
  companyName?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type _Entity = Student | StudentResponse;

export type _Service = {
  __typename?: '_Service';
  sdl?: Maybe<Scalars['String']>;
};

export type IsValidCoupon = {
  __typename?: 'isValidCoupon';
  isValid: Scalars['Boolean'];
  message: Scalars['String'];
};

export enum Link__Purpose {
  /** `EXECUTION` features provide metadata necessary for operation execution. */
  Execution = 'EXECUTION',
  /** `SECURITY` features provide metadata necessary to securely resolve fields. */
  Security = 'SECURITY'
}

export type DiscoveryClassRegisterMutationVariables = Exact<{
  registerType: DiscoveryClassRegisterTypes;
  phone: Scalars['String'];
  classTime?: InputMaybe<Scalars['String']>;
  classDate?: InputMaybe<Scalars['String']>;
  emergencyContact?: InputMaybe<Scalars['String']>;
}>;


export type DiscoveryClassRegisterMutation = { __typename?: 'Mutation', discoveryClassRegister?: string | null };

export type GetStudentProgramDetailsQueryVariables = Exact<{
  enrollmentId: Scalars['String'];
}>;


export type GetStudentProgramDetailsQuery = { __typename?: 'Query', getStudentProgramDetails?: { __typename?: 'EnrollmentDetails', id?: string | null, forWho?: string | null, program?: string | null, time?: string | null, students?: Array<string | null> | null } | null };

export type GetEnrollmentProgramDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEnrollmentProgramDetailsQuery = { __typename?: 'Query', getEnrollmentProgramDetails?: Array<{ __typename?: 'EnrollmentProgramDetails', program?: string | null, startDate?: string | null, result?: Array<{ __typename?: 'EnrollmentProgramResult', time?: string | null, leftSeatsCount?: string | null } | null> | null } | null> | null };

export type GetDiscoveryClassListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDiscoveryClassListQuery = { __typename?: 'Query', getDiscoveryClassList?: Array<{ __typename?: 'DiscoveryClassList', location?: string | null, classDate?: string | null, classTime?: Array<string | null> | null } | null> | null };

export type GetCouponCodeIsValidQueryVariables = Exact<{
  couponCode: Scalars['String'];
}>;


export type GetCouponCodeIsValidQuery = { __typename?: 'Query', getCouponCodeIsValid?: { __typename?: 'isValidCoupon', message: string, isValid: boolean } | null };

export type CreateEnrollmentProfileMutationVariables = Exact<{
  forWho: EnrollmentForWhoTypes;
  program: EnrollmentProgramTypes;
  time: Scalars['String'];
}>;


export type CreateEnrollmentProfileMutation = { __typename?: 'Mutation', createEnrollmentProfile?: string | null };

export type UpdateEnrollmentMutationVariables = Exact<{
  enrollmentId: Scalars['String'];
  forWho: EnrollmentForWhoTypes;
  program: EnrollmentProgramTypes;
  time: Scalars['String'];
}>;


export type UpdateEnrollmentMutation = { __typename?: 'Mutation', updateEnrollment?: string | null };

export type GetInformationSourcesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInformationSourcesQuery = { __typename?: 'Query', getInformationSources?: Array<{ __typename?: 'InformationSource', name?: string | null, text?: string | null } | null> | null };

export type GetAllStudentDetailsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type GetAllStudentDetailsQuery = { __typename?: 'Query', getAllStudentDetails?: Array<{ __typename?: 'StudentDetails', id?: string | null, firstName?: string | null, lastName?: string | null, phone?: string | null, facebook?: string | null, email?: string | null, paid?: boolean | null, transactionValue?: string | null, studentCode?: string | null, emergency?: Array<string | null> | null, enrollment?: Array<string | null> | null, meeting?: Array<string | null> | null } | null> | null };

export type GetStudentDetailsQueryVariables = Exact<{
  phone?: InputMaybe<Scalars['String']>;
  studentId?: InputMaybe<Scalars['String']>;
}>;


export type GetStudentDetailsQuery = { __typename?: 'Query', getStudentDetails?: { __typename?: 'StudentDetails', id?: string | null, firstName?: string | null, lastName?: string | null, phone?: string | null, facebook?: string | null, email?: string | null, paid?: boolean | null, transactionValue?: string | null, studentCode?: string | null, emergency?: Array<string | null> | null, enrollment?: Array<string | null> | null, meeting?: Array<string | null> | null } | null };

export type AddEmergencyContactMutationVariables = Exact<{
  phone: Scalars['String'];
  who: Scalars['String'];
}>;


export type AddEmergencyContactMutation = { __typename?: 'Mutation', addEmergencyContact?: string | null };

export type RegisterStudentForAcademyMutationVariables = Exact<{
  details?: InputMaybe<RegisterStudentDetailsInput>;
}>;


export type RegisterStudentForAcademyMutation = { __typename?: 'Mutation', registerStudentForAcademy?: { __typename?: 'StudentDetails', id?: string | null, firstName?: string | null, lastName?: string | null, phone?: string | null, facebook?: string | null, email?: string | null, paid?: boolean | null, transactionValue?: string | null, studentCode?: string | null, emergency?: Array<string | null> | null, enrollment?: Array<string | null> | null, meeting?: Array<string | null> | null, year?: string | null, class?: string | null } | null };

export type UpdateStudentDetailMutationVariables = Exact<{
  studentId: Scalars['String'];
  details?: InputMaybe<UpdateStudentDetailsInput>;
}>;


export type UpdateStudentDetailMutation = { __typename?: 'Mutation', updateStudentDetail?: { __typename?: 'StudentDetails', id?: string | null, firstName?: string | null, lastName?: string | null, phone?: string | null, facebook?: string | null, email?: string | null, paid?: boolean | null, transactionValue?: string | null, studentCode?: string | null } | null };

export type GetStudentScheduledMeetingQueryVariables = Exact<{
  studentId: Scalars['String'];
}>;


export type GetStudentScheduledMeetingQuery = { __typename?: 'Query', getStudentScheduledMeeting?: { __typename?: 'MeetingDetails', title?: string | null, description?: string | null, location?: string | null, type?: MeetingTypes | null, date?: string | null } | null };

export type ScheduleMeetingMutationVariables = Exact<{
  title: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  location: Scalars['String'];
  type: MeetingTypes;
  date: Scalars['String'];
}>;


export type ScheduleMeetingMutation = { __typename?: 'Mutation', scheduleMeeting?: string | null };


export const DiscoveryClassRegisterDocument = gql`
    mutation discoveryClassRegister($registerType: DiscoveryClassRegisterTypes!, $phone: String!, $classTime: String, $classDate: String, $emergencyContact: String) {
  discoveryClassRegister(
    registerType: $registerType
    phone: $phone
    classTime: $classTime
    classDate: $classDate
    emergencyContact: $emergencyContact
  )
}
    `;
export type DiscoveryClassRegisterMutationFn = Apollo.MutationFunction<DiscoveryClassRegisterMutation, DiscoveryClassRegisterMutationVariables>;

/**
 * __useDiscoveryClassRegisterMutation__
 *
 * To run a mutation, you first call `useDiscoveryClassRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDiscoveryClassRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [discoveryClassRegisterMutation, { data, loading, error }] = useDiscoveryClassRegisterMutation({
 *   variables: {
 *      registerType: // value for 'registerType'
 *      phone: // value for 'phone'
 *      classTime: // value for 'classTime'
 *      classDate: // value for 'classDate'
 *      emergencyContact: // value for 'emergencyContact'
 *   },
 * });
 */
export function useDiscoveryClassRegisterMutation(baseOptions?: Apollo.MutationHookOptions<DiscoveryClassRegisterMutation, DiscoveryClassRegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DiscoveryClassRegisterMutation, DiscoveryClassRegisterMutationVariables>(DiscoveryClassRegisterDocument, options);
      }
export type DiscoveryClassRegisterMutationHookResult = ReturnType<typeof useDiscoveryClassRegisterMutation>;
export type DiscoveryClassRegisterMutationResult = Apollo.MutationResult<DiscoveryClassRegisterMutation>;
export type DiscoveryClassRegisterMutationOptions = Apollo.BaseMutationOptions<DiscoveryClassRegisterMutation, DiscoveryClassRegisterMutationVariables>;
export const GetStudentProgramDetailsDocument = gql`
    query getStudentProgramDetails($enrollmentId: String!) {
  getStudentProgramDetails(enrollmentId: $enrollmentId) {
    id
    forWho
    program
    time
    students
  }
}
    `;

/**
 * __useGetStudentProgramDetailsQuery__
 *
 * To run a query within a React component, call `useGetStudentProgramDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentProgramDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentProgramDetailsQuery({
 *   variables: {
 *      enrollmentId: // value for 'enrollmentId'
 *   },
 * });
 */
export function useGetStudentProgramDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetStudentProgramDetailsQuery, GetStudentProgramDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentProgramDetailsQuery, GetStudentProgramDetailsQueryVariables>(GetStudentProgramDetailsDocument, options);
      }
export function useGetStudentProgramDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentProgramDetailsQuery, GetStudentProgramDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentProgramDetailsQuery, GetStudentProgramDetailsQueryVariables>(GetStudentProgramDetailsDocument, options);
        }
export type GetStudentProgramDetailsQueryHookResult = ReturnType<typeof useGetStudentProgramDetailsQuery>;
export type GetStudentProgramDetailsLazyQueryHookResult = ReturnType<typeof useGetStudentProgramDetailsLazyQuery>;
export type GetStudentProgramDetailsQueryResult = Apollo.QueryResult<GetStudentProgramDetailsQuery, GetStudentProgramDetailsQueryVariables>;
export const GetEnrollmentProgramDetailsDocument = gql`
    query getEnrollmentProgramDetails {
  getEnrollmentProgramDetails {
    program
    startDate
    result {
      time
      leftSeatsCount
    }
  }
}
    `;

/**
 * __useGetEnrollmentProgramDetailsQuery__
 *
 * To run a query within a React component, call `useGetEnrollmentProgramDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnrollmentProgramDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnrollmentProgramDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEnrollmentProgramDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetEnrollmentProgramDetailsQuery, GetEnrollmentProgramDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEnrollmentProgramDetailsQuery, GetEnrollmentProgramDetailsQueryVariables>(GetEnrollmentProgramDetailsDocument, options);
      }
export function useGetEnrollmentProgramDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEnrollmentProgramDetailsQuery, GetEnrollmentProgramDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEnrollmentProgramDetailsQuery, GetEnrollmentProgramDetailsQueryVariables>(GetEnrollmentProgramDetailsDocument, options);
        }
export type GetEnrollmentProgramDetailsQueryHookResult = ReturnType<typeof useGetEnrollmentProgramDetailsQuery>;
export type GetEnrollmentProgramDetailsLazyQueryHookResult = ReturnType<typeof useGetEnrollmentProgramDetailsLazyQuery>;
export type GetEnrollmentProgramDetailsQueryResult = Apollo.QueryResult<GetEnrollmentProgramDetailsQuery, GetEnrollmentProgramDetailsQueryVariables>;
export const GetDiscoveryClassListDocument = gql`
    query getDiscoveryClassList {
  getDiscoveryClassList {
    location
    classDate
    classTime
  }
}
    `;

/**
 * __useGetDiscoveryClassListQuery__
 *
 * To run a query within a React component, call `useGetDiscoveryClassListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscoveryClassListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscoveryClassListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDiscoveryClassListQuery(baseOptions?: Apollo.QueryHookOptions<GetDiscoveryClassListQuery, GetDiscoveryClassListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDiscoveryClassListQuery, GetDiscoveryClassListQueryVariables>(GetDiscoveryClassListDocument, options);
      }
export function useGetDiscoveryClassListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDiscoveryClassListQuery, GetDiscoveryClassListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDiscoveryClassListQuery, GetDiscoveryClassListQueryVariables>(GetDiscoveryClassListDocument, options);
        }
export type GetDiscoveryClassListQueryHookResult = ReturnType<typeof useGetDiscoveryClassListQuery>;
export type GetDiscoveryClassListLazyQueryHookResult = ReturnType<typeof useGetDiscoveryClassListLazyQuery>;
export type GetDiscoveryClassListQueryResult = Apollo.QueryResult<GetDiscoveryClassListQuery, GetDiscoveryClassListQueryVariables>;
export const GetCouponCodeIsValidDocument = gql`
    query getCouponCodeIsValid($couponCode: String!) {
  getCouponCodeIsValid(couponCode: $couponCode) {
    message
    isValid
  }
}
    `;

/**
 * __useGetCouponCodeIsValidQuery__
 *
 * To run a query within a React component, call `useGetCouponCodeIsValidQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCouponCodeIsValidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCouponCodeIsValidQuery({
 *   variables: {
 *      couponCode: // value for 'couponCode'
 *   },
 * });
 */
export function useGetCouponCodeIsValidQuery(baseOptions: Apollo.QueryHookOptions<GetCouponCodeIsValidQuery, GetCouponCodeIsValidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCouponCodeIsValidQuery, GetCouponCodeIsValidQueryVariables>(GetCouponCodeIsValidDocument, options);
      }
export function useGetCouponCodeIsValidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCouponCodeIsValidQuery, GetCouponCodeIsValidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCouponCodeIsValidQuery, GetCouponCodeIsValidQueryVariables>(GetCouponCodeIsValidDocument, options);
        }
export type GetCouponCodeIsValidQueryHookResult = ReturnType<typeof useGetCouponCodeIsValidQuery>;
export type GetCouponCodeIsValidLazyQueryHookResult = ReturnType<typeof useGetCouponCodeIsValidLazyQuery>;
export type GetCouponCodeIsValidQueryResult = Apollo.QueryResult<GetCouponCodeIsValidQuery, GetCouponCodeIsValidQueryVariables>;
export const CreateEnrollmentProfileDocument = gql`
    mutation createEnrollmentProfile($forWho: EnrollmentForWhoTypes!, $program: EnrollmentProgramTypes!, $time: String!) {
  createEnrollmentProfile(forWho: $forWho, program: $program, time: $time)
}
    `;
export type CreateEnrollmentProfileMutationFn = Apollo.MutationFunction<CreateEnrollmentProfileMutation, CreateEnrollmentProfileMutationVariables>;

/**
 * __useCreateEnrollmentProfileMutation__
 *
 * To run a mutation, you first call `useCreateEnrollmentProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEnrollmentProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEnrollmentProfileMutation, { data, loading, error }] = useCreateEnrollmentProfileMutation({
 *   variables: {
 *      forWho: // value for 'forWho'
 *      program: // value for 'program'
 *      time: // value for 'time'
 *   },
 * });
 */
export function useCreateEnrollmentProfileMutation(baseOptions?: Apollo.MutationHookOptions<CreateEnrollmentProfileMutation, CreateEnrollmentProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEnrollmentProfileMutation, CreateEnrollmentProfileMutationVariables>(CreateEnrollmentProfileDocument, options);
      }
export type CreateEnrollmentProfileMutationHookResult = ReturnType<typeof useCreateEnrollmentProfileMutation>;
export type CreateEnrollmentProfileMutationResult = Apollo.MutationResult<CreateEnrollmentProfileMutation>;
export type CreateEnrollmentProfileMutationOptions = Apollo.BaseMutationOptions<CreateEnrollmentProfileMutation, CreateEnrollmentProfileMutationVariables>;
export const UpdateEnrollmentDocument = gql`
    mutation updateEnrollment($enrollmentId: String!, $forWho: EnrollmentForWhoTypes!, $program: EnrollmentProgramTypes!, $time: String!) {
  updateEnrollment(
    enrollmentId: $enrollmentId
    forWho: $forWho
    program: $program
    time: $time
  )
}
    `;
export type UpdateEnrollmentMutationFn = Apollo.MutationFunction<UpdateEnrollmentMutation, UpdateEnrollmentMutationVariables>;

/**
 * __useUpdateEnrollmentMutation__
 *
 * To run a mutation, you first call `useUpdateEnrollmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEnrollmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEnrollmentMutation, { data, loading, error }] = useUpdateEnrollmentMutation({
 *   variables: {
 *      enrollmentId: // value for 'enrollmentId'
 *      forWho: // value for 'forWho'
 *      program: // value for 'program'
 *      time: // value for 'time'
 *   },
 * });
 */
export function useUpdateEnrollmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEnrollmentMutation, UpdateEnrollmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEnrollmentMutation, UpdateEnrollmentMutationVariables>(UpdateEnrollmentDocument, options);
      }
export type UpdateEnrollmentMutationHookResult = ReturnType<typeof useUpdateEnrollmentMutation>;
export type UpdateEnrollmentMutationResult = Apollo.MutationResult<UpdateEnrollmentMutation>;
export type UpdateEnrollmentMutationOptions = Apollo.BaseMutationOptions<UpdateEnrollmentMutation, UpdateEnrollmentMutationVariables>;
export const GetInformationSourcesDocument = gql`
    query GetInformationSources {
  getInformationSources {
    name
    text
  }
}
    `;

/**
 * __useGetInformationSourcesQuery__
 *
 * To run a query within a React component, call `useGetInformationSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInformationSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInformationSourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInformationSourcesQuery(baseOptions?: Apollo.QueryHookOptions<GetInformationSourcesQuery, GetInformationSourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInformationSourcesQuery, GetInformationSourcesQueryVariables>(GetInformationSourcesDocument, options);
      }
export function useGetInformationSourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInformationSourcesQuery, GetInformationSourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInformationSourcesQuery, GetInformationSourcesQueryVariables>(GetInformationSourcesDocument, options);
        }
export type GetInformationSourcesQueryHookResult = ReturnType<typeof useGetInformationSourcesQuery>;
export type GetInformationSourcesLazyQueryHookResult = ReturnType<typeof useGetInformationSourcesLazyQuery>;
export type GetInformationSourcesQueryResult = Apollo.QueryResult<GetInformationSourcesQuery, GetInformationSourcesQueryVariables>;
export const GetAllStudentDetailsDocument = gql`
    query getAllStudentDetails($limit: Int, $offset: Int) {
  getAllStudentDetails(limit: $limit, offset: $offset) {
    id
    firstName
    lastName
    phone
    facebook
    email
    paid
    transactionValue
    studentCode
    emergency
    enrollment
    meeting
  }
}
    `;

/**
 * __useGetAllStudentDetailsQuery__
 *
 * To run a query within a React component, call `useGetAllStudentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllStudentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllStudentDetailsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetAllStudentDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllStudentDetailsQuery, GetAllStudentDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllStudentDetailsQuery, GetAllStudentDetailsQueryVariables>(GetAllStudentDetailsDocument, options);
      }
export function useGetAllStudentDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllStudentDetailsQuery, GetAllStudentDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllStudentDetailsQuery, GetAllStudentDetailsQueryVariables>(GetAllStudentDetailsDocument, options);
        }
export type GetAllStudentDetailsQueryHookResult = ReturnType<typeof useGetAllStudentDetailsQuery>;
export type GetAllStudentDetailsLazyQueryHookResult = ReturnType<typeof useGetAllStudentDetailsLazyQuery>;
export type GetAllStudentDetailsQueryResult = Apollo.QueryResult<GetAllStudentDetailsQuery, GetAllStudentDetailsQueryVariables>;
export const GetStudentDetailsDocument = gql`
    query getStudentDetails($phone: String, $studentId: String) {
  getStudentDetails(phone: $phone, studentId: $studentId) {
    id
    firstName
    lastName
    phone
    facebook
    email
    paid
    transactionValue
    studentCode
    emergency
    enrollment
    meeting
  }
}
    `;

/**
 * __useGetStudentDetailsQuery__
 *
 * To run a query within a React component, call `useGetStudentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentDetailsQuery({
 *   variables: {
 *      phone: // value for 'phone'
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useGetStudentDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetStudentDetailsQuery, GetStudentDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentDetailsQuery, GetStudentDetailsQueryVariables>(GetStudentDetailsDocument, options);
      }
export function useGetStudentDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentDetailsQuery, GetStudentDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentDetailsQuery, GetStudentDetailsQueryVariables>(GetStudentDetailsDocument, options);
        }
export type GetStudentDetailsQueryHookResult = ReturnType<typeof useGetStudentDetailsQuery>;
export type GetStudentDetailsLazyQueryHookResult = ReturnType<typeof useGetStudentDetailsLazyQuery>;
export type GetStudentDetailsQueryResult = Apollo.QueryResult<GetStudentDetailsQuery, GetStudentDetailsQueryVariables>;
export const AddEmergencyContactDocument = gql`
    mutation addEmergencyContact($phone: String!, $who: String!) {
  addEmergencyContact(phone: $phone, who: $who)
}
    `;
export type AddEmergencyContactMutationFn = Apollo.MutationFunction<AddEmergencyContactMutation, AddEmergencyContactMutationVariables>;

/**
 * __useAddEmergencyContactMutation__
 *
 * To run a mutation, you first call `useAddEmergencyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEmergencyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEmergencyContactMutation, { data, loading, error }] = useAddEmergencyContactMutation({
 *   variables: {
 *      phone: // value for 'phone'
 *      who: // value for 'who'
 *   },
 * });
 */
export function useAddEmergencyContactMutation(baseOptions?: Apollo.MutationHookOptions<AddEmergencyContactMutation, AddEmergencyContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddEmergencyContactMutation, AddEmergencyContactMutationVariables>(AddEmergencyContactDocument, options);
      }
export type AddEmergencyContactMutationHookResult = ReturnType<typeof useAddEmergencyContactMutation>;
export type AddEmergencyContactMutationResult = Apollo.MutationResult<AddEmergencyContactMutation>;
export type AddEmergencyContactMutationOptions = Apollo.BaseMutationOptions<AddEmergencyContactMutation, AddEmergencyContactMutationVariables>;
export const RegisterStudentForAcademyDocument = gql`
    mutation registerStudentForAcademy($details: RegisterStudentDetailsInput) {
  registerStudentForAcademy(details: $details) {
    id
    firstName
    lastName
    phone
    facebook
    email
    paid
    transactionValue
    studentCode
    emergency
    enrollment
    meeting
    year
    class
  }
}
    `;
export type RegisterStudentForAcademyMutationFn = Apollo.MutationFunction<RegisterStudentForAcademyMutation, RegisterStudentForAcademyMutationVariables>;

/**
 * __useRegisterStudentForAcademyMutation__
 *
 * To run a mutation, you first call `useRegisterStudentForAcademyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterStudentForAcademyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerStudentForAcademyMutation, { data, loading, error }] = useRegisterStudentForAcademyMutation({
 *   variables: {
 *      details: // value for 'details'
 *   },
 * });
 */
export function useRegisterStudentForAcademyMutation(baseOptions?: Apollo.MutationHookOptions<RegisterStudentForAcademyMutation, RegisterStudentForAcademyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterStudentForAcademyMutation, RegisterStudentForAcademyMutationVariables>(RegisterStudentForAcademyDocument, options);
      }
export type RegisterStudentForAcademyMutationHookResult = ReturnType<typeof useRegisterStudentForAcademyMutation>;
export type RegisterStudentForAcademyMutationResult = Apollo.MutationResult<RegisterStudentForAcademyMutation>;
export type RegisterStudentForAcademyMutationOptions = Apollo.BaseMutationOptions<RegisterStudentForAcademyMutation, RegisterStudentForAcademyMutationVariables>;
export const UpdateStudentDetailDocument = gql`
    mutation updateStudentDetail($studentId: String!, $details: UpdateStudentDetailsInput) {
  updateStudentDetail(studentId: $studentId, details: $details) {
    id
    firstName
    lastName
    phone
    facebook
    email
    paid
    transactionValue
    studentCode
  }
}
    `;
export type UpdateStudentDetailMutationFn = Apollo.MutationFunction<UpdateStudentDetailMutation, UpdateStudentDetailMutationVariables>;

/**
 * __useUpdateStudentDetailMutation__
 *
 * To run a mutation, you first call `useUpdateStudentDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStudentDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStudentDetailMutation, { data, loading, error }] = useUpdateStudentDetailMutation({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      details: // value for 'details'
 *   },
 * });
 */
export function useUpdateStudentDetailMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStudentDetailMutation, UpdateStudentDetailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStudentDetailMutation, UpdateStudentDetailMutationVariables>(UpdateStudentDetailDocument, options);
      }
export type UpdateStudentDetailMutationHookResult = ReturnType<typeof useUpdateStudentDetailMutation>;
export type UpdateStudentDetailMutationResult = Apollo.MutationResult<UpdateStudentDetailMutation>;
export type UpdateStudentDetailMutationOptions = Apollo.BaseMutationOptions<UpdateStudentDetailMutation, UpdateStudentDetailMutationVariables>;
export const GetStudentScheduledMeetingDocument = gql`
    query getStudentScheduledMeeting($studentId: String!) {
  getStudentScheduledMeeting(studentId: $studentId) {
    title
    description
    location
    type
    date
  }
}
    `;

/**
 * __useGetStudentScheduledMeetingQuery__
 *
 * To run a query within a React component, call `useGetStudentScheduledMeetingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentScheduledMeetingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentScheduledMeetingQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useGetStudentScheduledMeetingQuery(baseOptions: Apollo.QueryHookOptions<GetStudentScheduledMeetingQuery, GetStudentScheduledMeetingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentScheduledMeetingQuery, GetStudentScheduledMeetingQueryVariables>(GetStudentScheduledMeetingDocument, options);
      }
export function useGetStudentScheduledMeetingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentScheduledMeetingQuery, GetStudentScheduledMeetingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentScheduledMeetingQuery, GetStudentScheduledMeetingQueryVariables>(GetStudentScheduledMeetingDocument, options);
        }
export type GetStudentScheduledMeetingQueryHookResult = ReturnType<typeof useGetStudentScheduledMeetingQuery>;
export type GetStudentScheduledMeetingLazyQueryHookResult = ReturnType<typeof useGetStudentScheduledMeetingLazyQuery>;
export type GetStudentScheduledMeetingQueryResult = Apollo.QueryResult<GetStudentScheduledMeetingQuery, GetStudentScheduledMeetingQueryVariables>;
export const ScheduleMeetingDocument = gql`
    mutation scheduleMeeting($title: String!, $description: String, $location: String!, $type: MeetingTypes!, $date: String!) {
  scheduleMeeting(
    title: $title
    description: $description
    location: $location
    type: $type
    date: $date
  )
}
    `;
export type ScheduleMeetingMutationFn = Apollo.MutationFunction<ScheduleMeetingMutation, ScheduleMeetingMutationVariables>;

/**
 * __useScheduleMeetingMutation__
 *
 * To run a mutation, you first call `useScheduleMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleMeetingMutation, { data, loading, error }] = useScheduleMeetingMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      location: // value for 'location'
 *      type: // value for 'type'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useScheduleMeetingMutation(baseOptions?: Apollo.MutationHookOptions<ScheduleMeetingMutation, ScheduleMeetingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ScheduleMeetingMutation, ScheduleMeetingMutationVariables>(ScheduleMeetingDocument, options);
      }
export type ScheduleMeetingMutationHookResult = ReturnType<typeof useScheduleMeetingMutation>;
export type ScheduleMeetingMutationResult = Apollo.MutationResult<ScheduleMeetingMutation>;
export type ScheduleMeetingMutationOptions = Apollo.BaseMutationOptions<ScheduleMeetingMutation, ScheduleMeetingMutationVariables>;