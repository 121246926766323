import { AnimatePresence, motion } from 'framer-motion';
import { PropsWithChildren } from 'react';

interface BackInUpProps extends PropsWithChildren {
  duration?: number;
  upheight?: number;
}

export const BackInUp = ({ upheight = 10, duration = 0.3, children }: BackInUpProps) => {
  return (
    <AnimatePresence mode="wait">
      <motion.div style={{ width: '100%' }} initial={{ y: upheight, opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ y: -upheight, opacity: 0 }} transition={{ duration }}>
        {children}
      </motion.div>
    </AnimatePresence>
  );
};
