import { PlayIcon } from '@assets/icons';
import { Box, Stack } from '@mui/material';
import { useState } from 'react';
import { ExpanedTypography } from './expanded-typography';
import dynamic from 'next/dynamic';

const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false });

interface VideoThumbnailProps {
  src: string;
  thumbnail: string;
  label: string;
}

export const VideoThumbnail = (props: VideoThumbnailProps) => {
  const { src, thumbnail, label = 'Программист гэж юу вэ?' } = props;
  const [isPlaying, setIsPlaying] = useState(false);

  const handleClick = () => {
    if (src) {
      setIsPlaying(true);
    }
  };

  return (
    <Stack
      className="interactable"
      data-type="play"
      minWidth={{
        sm: 'calc(100% - 16px)',
        md: 'auto',
        lg: 'auto',
      }}
      flex={{
        sm: 0,
        md: 1,
        lg: 1,
      }}
      height={{
        sm: 439,
        md: 561,
      }}
      position="relative"
      sx={{ cursor: 'pointer', scrollSnapAlign: 'start' }}
    >
      {isPlaying ? (
        <ReactPlayer controls url={src} playing={isPlaying} width="100%" height="100%" />
      ) : (
        <Box
          width="100%"
          height="100%"
          sx={{
            backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.7189469537815126) 5%, rgba(255,255,255,0) 41%),url(${thumbnail})`,
            backgroundPosition: 'top',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
          onClick={handleClick}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <PlayIcon />
          <Box
            position="absolute"
            left={{
              lg: 42,
              md: 21,
              sm: 16,
            }}
            bottom={{
              md: 40,
              sm: 32,
            }}
            maxWidth={238}
          >
            <ExpanedTypography fontSize={{ sm: 24, md: 18, lg: 24 }} color="white" lineHeight={'110%'}>
              {label}
            </ExpanedTypography>
          </Box>
        </Box>
      )}
    </Stack>
  );
};
