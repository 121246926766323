import { LogoProps } from '../index';

export const RoundedArrowLeftIcon = ({ width, height, color }: LogoProps) => (
  <svg width={width || '48'} height={height || '49'} viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="23.5" transform="matrix(0 -1 -1 0 48 48.5)" stroke={color || 'black'} />
    <path
      d="M24.71 18.2901C24.8037 18.383 24.8781 18.4936 24.9289 18.6155C24.9797 18.7373 25.0058 18.868 25.0058 19.0001C25.0058 19.1321 24.9797 19.2628 24.9289 19.3846C24.8781 19.5065 24.8037 19.6171 24.71 19.7101L21.41 23.0001H29C29.2652 23.0001 29.5196 23.1054 29.7071 23.293C29.8946 23.4805 30 23.7348 30 24.0001C30 24.2653 29.8946 24.5196 29.7071 24.7072C29.5196 24.8947 29.2652 25.0001 29 25.0001H21.41L24.71 28.2901C24.8983 28.4784 25.0041 28.7338 25.0041 29.0001C25.0041 29.2664 24.8983 29.5218 24.71 29.7101C24.5217 29.8984 24.2663 30.0042 24 30.0042C23.7337 30.0042 23.4783 29.8984 23.29 29.7101L18.29 24.7101C18.199 24.615 18.1276 24.5028 18.08 24.3801C18.0271 24.2604 17.9998 24.1309 17.9998 24.0001C17.9998 23.8692 18.0271 23.7398 18.08 23.6201C18.1276 23.4973 18.199 23.3852 18.29 23.2901L23.29 18.2901C23.383 18.1963 23.4936 18.1219 23.6154 18.0712C23.7373 18.0204 23.868 17.9943 24 17.9943C24.132 17.9943 24.2627 18.0204 24.3846 18.0712C24.5064 18.1219 24.617 18.1963 24.71 18.2901Z"
      fill={color || 'black'}
    />
  </svg>
);
