import { LocomotiveProvider } from '@contexts';
import { PropsWithChildren, useState } from 'react';
import { Footer } from './footer';
import Header from './header';
import Sidebar from './sidebar';

export const LocomotiveLayout = ({ children }: PropsWithChildren) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [open, setOpen] = useState(false);

  return (
    <>
      <Header isScrolled={isScrolled} open={open} setOpen={setOpen} />
      <Sidebar open={open} setOpen={setOpen} />

      <LocomotiveProvider setIsScrolled={setIsScrolled}>
        {children}
        <Footer />
      </LocomotiveProvider>
    </>
  );
};
