import { Theme, useMediaQuery as MuiUseMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';

export const useMediaQuery = (breakpoints: 'sm' | 'md' | 'lg') => {
  const [isAvailable, setIsAvailable] = useState<boolean>(false);
  const query = MuiUseMediaQuery((theme: Theme) => theme.breakpoints.only(breakpoints));

  useEffect(() => {
    setIsAvailable(query);
  }, [query]);

  return isAvailable;
};
