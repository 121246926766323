import { CheckIcon } from '@assets/icons';
import { InputField } from '@components';
import { useGetCouponCodeIsValidLazyQuery } from '@generated';
import { Box, Button, CircularProgress, Modal, Skeleton, Stack, Typography } from '@mui/material';

import { useRouter } from 'next/router';
import { ChangeEvent, Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { EnrollmentCurriculumSectionData } from '../data-content';
import { launchPaymentWindow } from './payment';

export const EnrollmentReviewDesktop = ({ studentDetails, studentProgramDetailsData }) => {
  const router = useRouter();
  const {
    getStudentDetails: { transactionValue, paid },
  } = studentDetails || { getStudentDetails: { enrollment: '', transactionValue: '', paid: false } };

  const enrollment = studentProgramDetailsData?.getStudentProgramDetails;
  const onUpdate = () => {
    router.replace(router.asPath);
  };
  const program = EnrollmentCurriculumSectionData?.selections?.filter((s) => s?.value === enrollment?.program && s?.label)[0]?.label;

  return (
    <Stack width={({ spacing }) => `calc(100% - ${spacing(4)})`} maxWidth={648} alignItems="center" spacing={5}>
      <Stack direction="row">
        <Card label="Хөтөлбөр" text={program} first loading={program ? false : true} />
        <Box height="100%" width={24} borderTop={({ palette }) => `1px solid ${palette.primary.main}`} />
        <Card label="Суралцах ээлж" text={enrollment?.time} loading={enrollment?.time ? false : true} />
        <Box height="100%" width={24} borderTop={({ palette }) => `1px solid ${palette.primary.main}`} />
        <Card label="Ерөнхий мэдээлэл" text="Бөглөсөн" loading={program ? false : true} />
        <Box height="100%" width={24} borderTop={({ palette }) => `1px solid ${palette.primary.main}`} />
        <StepFour label="Суудал баталгаажуулах" text="Та суудлаа баталгаажуулснаар суралцах боломжтой болно" transactionValue={transactionValue} paid={paid} refetch={onUpdate} />
      </Stack>
    </Stack>
  );
};

interface CardProps {
  loading?: boolean;
  label: string;
  text: string;
  first?: boolean;
}

const Card = ({ loading = false, label, text, first }: CardProps) => {
  return (
    <Stack position="relative" alignItems="center" paddingY={3} bgcolor={({ palette }) => palette.grey[50]} gap={1.5} width={190} paddingTop={4} height="fit-content">
      <Stack direction="row" position="absolute" top={-19.5} alignItems="center" width="100%">
        <Box width={`calc(50% - 20px)`} height={first ? 0 : '1px'} bgcolor={({ palette }) => palette.primary.main} />
        <Stack justifyContent="center" alignItems="center" width={40} height={40} borderRadius="100%" border={({ palette }) => `1px solid ${palette.primary.main}`} bgcolor="primary.light">
          <CheckIcon width={17} height={17} />
        </Stack>
        <Box width={`calc(50% - 20px)`} height="1px" bgcolor={({ palette }) => palette.primary.main} />
      </Stack>
      <Typography variant="bodyM" fontWeight={600}>
        {label}
      </Typography>

      <Box height="1px" width={({ spacing }) => `calc(100% - ${spacing(3)})`} bgcolor={({ palette }) => palette.grey[200]} />

      {loading ? (
        <Box>
          <Skeleton width="150px" height={12} animation="wave" /> <Skeleton width="150px" height={12} animation="pulse" />
        </Box>
      ) : (
        <>
          <Typography variant="bodyS">{text}</Typography>
        </>
      )}
    </Stack>
  );
};
export interface CouponType {
  percent: string;
  date: object;
  id: string;
}

const StepFour = (props: { label: string; text: string; first?: boolean; transactionValue: string; paid: boolean; refetch: () => void }) => {
  const { label, text, first, transactionValue, paid } = props;
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState<boolean>(false);

  const [amount, setAmount] = useState('500000');
  const [couponCode, setCouponCode] = useState<string>('');
  const [isVerifiedCoupon, setIsVerifiedCoupon] = useState(false);
  const [error, setError] = useState<string>('');
  const [getCouponCodeIsValid] = useGetCouponCodeIsValidLazyQuery();

  const onPress = async () => {
    launchPaymentWindow(transactionValue, Number(amount), couponCode);
  };

  const checkCoupon = async () => {
    setLoading(true);
    if (!couponCode || couponCode.length !== 16) {
      setError('Купон 16 оронтой байх ёстой');
      setLoading(false);
      return;
    }
    const destucture: CouponType = {
      percent: couponCode.substring(0, 3),
      date: {
        y: couponCode.substring(3, 7),
        m: couponCode.substring(7, 9),
        d: Number(couponCode.substring(9, 11)) + Number(couponCode.substring(11, 13)) + 1,
      },
      id: couponCode.substring(13, 16),
    };
    const isExpired = 0 > new Date(Object.values(destucture.date).join('-')).getTime() - Date.now();
    if (isExpired) {
      setError('Таны оруулсан купоны хугацаа нь дууссан байна');
      setLoading(false);
      return;
    }
    const { data } = await getCouponCodeIsValid({ variables: { couponCode: couponCode }, fetchPolicy: 'no-cache' });

    if (data.getCouponCodeIsValid.message.toLowerCase().includes('invalid')) {
      setError('Бүртгэлгүй купон байна');
      setLoading(false);
      return;
    }

    if (data.getCouponCodeIsValid.message.toLowerCase().includes('used')) {
      setError('Ашиглагдсан купон байна');
      setLoading(false);
      return;
    }
    if (Number(destucture.percent) > 99) {
      setAmount('10');
      setIsVerifiedCoupon(true);
      setError('');
      setLoading(false);
      return;
    }

    setAmount((Number(amount) - Number(amount) * (Number(destucture.percent) / 100)).toString());
    setIsVerifiedCoupon(true);
    setError('');
    setLoading(false);
  };
  const handleCoupon = (event: ChangeEvent<HTMLInputElement>) => {
    setCouponCode(event?.target?.value);
  };

  return (
    <Stack gap={2}>
      <Stack position="relative" alignItems="center" paddingY={3} bgcolor="white" border={({ palette }) => `1px solid ${palette.grey[100]}`} gap={1.5} width={330} paddingTop={4} height="fit-content">
        <Stack direction="row" position="absolute" top={-20.5} alignItems="center" width="100%">
          <Box width={`calc(50% - 20px)`} height={first ? 0 : '1px'} bgcolor={({ palette }) => palette.primary.main} />
          <Stack justifyContent="center" alignItems="center" width={40} height={40} borderRadius="100%" border={({ palette }) => `1px solid ${palette.grey[100]}`} bgcolor="white">
            4
          </Stack>
        </Stack>

        <Typography variant="bodyM" fontWeight={600}>
          {label}
        </Typography>
        <Box height="1px" width={({ spacing }) => `calc(100% - ${spacing(3)})`} bgcolor={({ palette }) => palette.grey[200]} />
        <Typography textAlign="center" variant="bodyS">
          {text}
        </Typography>
        <Typography textAlign="left" variant="bodyS" fontWeight={590} color={isVerifiedCoupon && ''}>
          Төлөх дүн:{' '}
          <span style={{ color: isVerifiedCoupon && '#1AC74A' }} data-cy="Enroll-Review-Total-Amount">
            {new Intl.NumberFormat(undefined, {}).format(Number(amount))}₮
          </span>
        </Typography>

        <Box width={({ spacing }) => `calc(100% - ${spacing(3)})`} mt={2} display="flex" flexDirection="column" gap={1}>
          <Button
            variant="contained"
            fullWidth
            sx={{ ':hover': { backgroundColor: ({ palette }) => palette.primary.dark } }}
            onClick={onPress}
            disabled={!loading && paid}
            data-cy="Desktop-Payment-Button"
          >
            <Typography variant="buttonS" color={paid ? 'black' : 'white'}>
              {loading ? <CircularProgress size={20} color="inherit" /> : paid ? 'Төлбөр төлөгдсөн' : 'Төлбөр төлөх'}
            </Typography>
          </Button>
          <Box height="1px" width="100%" bgcolor={({ palette }) => palette.grey[200]} />
          <Box width="100%" display="flex" flexDirection="row" justifyContent="space-between" gap={1}>
            <Box width="100%">
              <InputField
                fullWidth
                label="Купон код"
                sx={{ height: '48px !important' }}
                value={couponCode}
                onChange={handleCoupon}
                disabled={isVerifiedCoupon}
                type="string"
                dataCy="Enroll-Review-Coupon-Input"
              />
            </Box>
            <Button
              variant="outlined"
              sx={{
                maxWidth: '110px',
                color: 'primary.main',
                ':hover': { backgroundColor: 'primary.dark', color: 'common.white', border: 'none' },
              }}
              onClick={checkCoupon}
              disabled={isVerifiedCoupon}
              data-cy="Enroll-Review-Coupon-Button"
            >
              <Typography variant="bodyS" fontWeight={590} color={isVerifiedCoupon && 'grey.300'}>
                Ашиглах
              </Typography>
            </Button>
          </Box>
          {error && !isVerifiedCoupon && (
            <Typography variant="caption" color="error.main" data-cy="Enroll-Review-Error-Msg">
              {error}
            </Typography>
          )}
          {isVerifiedCoupon && !error && (
            <Typography variant="caption" color="primary.main" fontWeight={590} data-cy="Enroll-Review-Success-Msg">
              Амжилттай
            </Typography>
          )}
        </Box>
      </Stack>
      {/* <Stack width={330} alignItems="center">
        <Typography textAlign="center" width={227}>
          Та төлбөрөө төлөхөөсөө өмнө
          <Link href="/booking" color={palette.text.primary} fontWeight={600} mx={0.5}>
            уулзах цаг
          </Link>
          товлох уу?
        </Typography>
      </Stack> */}
      <WarningPaidPopUp open={open} setOpen={setOpen} />
    </Stack>
  );
};

interface WarningPaidPopUpProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  children?: ReactNode;
}

export const WarningPaidPopUp = ({ open, setOpen, children }: WarningPaidPopUpProps) => {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      slotProps={{
        backdrop: {
          sx: {
            height: {
              sm: 'auto',
              md: '100vh',
            },
          },
        },
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        position="absolute"
        width={250}
        gap={5}
        height={250}
        borderRadius={2}
        bgcolor="white"
        top="50%"
        left="50%"
        sx={{
          transform: 'translate(-50%, -50%)',
        }}
      >
        Таны төлбөр төлөгдсөн байна.
        <Button variant="contained" sx={{ '&:hover': { backgroundColor: 'green' } }} onClick={() => setOpen(false)}>
          Хаах
        </Button>
      </Stack>
    </Modal>
  );
};
