import { FrLeftArrorIcon } from '@assets/icons';
import { Box, IconButton, Stack } from '@mui/material';
import { SimpleHeaderProps } from '.';

export const SimpleHeaderMobile = (props: SimpleHeaderProps) => {
  const { showProgressBar, handleBack } = props;

  return (
    <Stack width="100vw" alignItems="center">
      <Stack position="relative" width="100%" height={80} maxWidth={({ breakpoints }) => breakpoints.values.lg} paddingX={8} justifyContent="center" alignItems="center">
        <Box position="absolute" left={16}>
          <IconButton onClick={handleBack}>
            <FrLeftArrorIcon width={40} height={40} />
          </IconButton>
        </Box>

        {showProgressBar && <ProgressBar {...props} />}
      </Stack>
    </Stack>
  );
};

const ProgressBar = (props: SimpleHeaderProps) => {
  const { progress = 0, progressBarValue = 4 } = props;

  return (
    <Stack direction="row" spacing={1}>
      {[...Array(progressBarValue).keys()].map((cur, key) => (
        <Box key={`progress-${key}`} width={30} height={3} bgcolor={({ palette }) => (cur <= progress ? palette.primary.main : palette.grey[100])} borderRadius={1} />
      ))}
    </Stack>
  );
};
