import AES from 'crypto-js/aes';

export const launchPaymentWindow = (studentInfo: string, amount: number, couponCode: string) => {
  const userObj = {
    studentInfo,
    amount,
    couponCode,
    backButtonUrl: `https://pinecone.mn/enroll/?progress=3`,
    successUrl: `https://pinecone.mn/success`,
  };

  const encryptTxt = encryptUrl(userObj);
  window.location.href = `${process.env.CHECKOUT_URL}/checkout?token=${encodeURIComponent(encryptTxt)}`;
};

const encryptUrl = (userPayload) => {
  try {
    const ciphertext = AES.encrypt(JSON.stringify(userPayload), process.env.PRIVATE_KEY).toString();

    return ciphertext;
  } catch (err) {
    console.log(err);
  }
};
