import { useMediaQuery } from '@hooks';
import { Box, Container } from '@mui/material';
import { Theme, SxProps } from '@mui/material/styles';
import { Dispatch, PropsWithChildren, SetStateAction, createContext, useContext, useEffect, useMemo, useState } from 'react';

interface ContainerProps {
  lgWidthLimit?: number | string;
  mdWidthLimit?: number | string;
  smWidthLimit?: number | string;
}

interface BackgroundLinesPropsType extends PropsWithChildren {
  style?: SxProps<Theme>;
}

const ContainerContext = createContext<{ containerProps?: ContainerProps; setContainerProps?: Dispatch<SetStateAction<ContainerProps>> }>({});

export const BackgroundLines = (props: BackgroundLinesPropsType) => {
  const { children, style } = props;
  const isMobile = useMediaQuery('sm');
  const [containerProps, setContainerProps] = useState<ContainerProps>({});

  const value = useMemo(() => ({ containerProps, setContainerProps }), [containerProps]);

  return (
    <ContainerContext.Provider value={value}>
      {/* Background */}

      <Box top={0} position="absolute" width="100%" height="100%">
        <Container
          sx={{
            height: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {new Array(isMobile ? 3 : 5).fill(0).map((_, index) => (
            <Box
              key={index}
              sx={{
                width: '1px',
                height: '100%',
                background: 'repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) 4px, transparent 4px, transparent 8px)',
              }}
            />
          ))}
        </Container>
      </Box>

      {/* Main content */}

      <Container sx={{ margin: 'auto', position: 'relative', zIndex: 1, ...style }}>{children}</Container>
    </ContainerContext.Provider>
  );
};

export const useContainerProps = (props?: ContainerProps) => {
  const { containerProps, setContainerProps } = useContext(ContainerContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => props && setContainerProps(props), []);

  return { containerProps, setContainerProps };
};
