import { useMediaQuery } from '@hooks';
import { motion } from 'framer-motion';
import { LogoProps } from '../';

export const ArrowDownInsideCircleIcon = ({ width, height, color }: LogoProps) => {
  const isDesktop = useMediaQuery('lg');
  return (
    <motion.svg
      animate={{ y: [0, 0, 0, 0, -16, 0, -8, 0, 0, 0, 0] }}
      transition={{ duration: 1.5, repeat: Infinity, repeatDelay: 2, ease: 'easeInOut' }}
      width={width || isDesktop ? '48' : '32'}
      height={height || isDesktop ? '48' : '32'}
      viewBox="0 0 33 32"
      fill="none"
    >
      <circle cx="16.25" cy="16" r="15.5" stroke={color || '#111111'} />
      <path
        d="M20.39 15.5267C20.328 15.4642 20.2543 15.4146 20.173 15.3807C20.0918 15.3469 20.0046 15.3295 19.9166 15.3295C19.8286 15.3295 19.7415 15.3469 19.6602 15.3807C19.579 15.4146 19.5053 15.4642 19.4433 15.5267L17.25 17.7267V12.6667C17.25 12.4899 17.1797 12.3203 17.0547 12.1953C16.9297 12.0702 16.7601 12 16.5833 12C16.4065 12 16.2369 12.0702 16.1119 12.1953C15.9869 12.3203 15.9166 12.4899 15.9166 12.6667V17.7267L13.7233 15.5267C13.5978 15.4011 13.4275 15.3306 13.25 15.3306C13.0724 15.3306 12.9022 15.4011 12.7766 15.5267C12.6511 15.6522 12.5806 15.8225 12.5806 16C12.5806 16.1775 12.6511 16.3478 12.7766 16.4733L16.11 19.8067C16.1734 19.8674 16.2481 19.9149 16.33 19.9467C16.4098 19.9819 16.496 20.0002 16.5833 20.0002C16.6705 20.0002 16.7568 19.9819 16.8366 19.9467C16.9185 19.9149 16.9932 19.8674 17.0566 19.8067L20.39 16.4733C20.4524 16.4114 20.502 16.3376 20.5359 16.2564C20.5697 16.1751 20.5872 16.088 20.5872 16C20.5872 15.912 20.5697 15.8249 20.5359 15.7436C20.502 15.6624 20.4524 15.5886 20.39 15.5267Z"
        fill={color || '#111111'}
      />
    </motion.svg>
  );
};
