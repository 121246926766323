import { LogoProps } from '.';

export const VerifiedIcon = ({ width = 20, height = 20, color = '#1AC74A' }: LogoProps) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.64356 1.2519C9.40965 0.544855 10.5904 0.544854 11.3564 1.2519L11.5825 1.46055C12.0411 1.88377 12.6709 2.0687 13.2855 1.96058L13.5885 1.90728C14.6152 1.72666 15.6085 2.36499 15.8707 3.37398L15.9481 3.67173C16.105 4.27568 16.5349 4.77177 17.1104 5.01308L17.3941 5.13204C18.3555 5.53519 18.8459 6.6092 18.521 7.59977L18.4252 7.89209C18.2307 8.48502 18.3241 9.13476 18.6777 9.64889L18.8521 9.90235C19.4429 10.7613 19.2749 11.93 18.466 12.5876L18.2273 12.7817C17.7431 13.1754 17.4705 13.7725 17.49 14.3962L17.4997 14.7037C17.5323 15.7456 16.7591 16.638 15.7231 16.7539L15.4174 16.7881C14.7972 16.8575 14.245 17.2124 13.9243 17.7477L13.7661 18.0116C13.2303 18.9058 12.0974 19.2385 11.1631 18.7759L10.8874 18.6394C10.3282 18.3625 9.67179 18.3625 9.11257 18.6394L8.83687 18.7759C7.90262 19.2385 6.76974 18.9058 6.23388 18.0116L6.07575 17.7477C5.75499 17.2124 5.20278 16.8575 4.58264 16.7881L4.27691 16.7539C3.24088 16.638 2.46768 15.7456 2.50035 14.7037L2.50999 14.3962C2.52954 13.7725 2.25686 13.1754 1.77268 12.7817L1.53399 12.5876C0.725111 11.93 0.557078 10.7613 1.1479 9.90235L1.32225 9.64889C1.67591 9.13476 1.76932 8.48503 1.57484 7.89209L1.47896 7.59977C1.15406 6.6092 1.64454 5.53519 2.60594 5.13204L2.88964 5.01308C3.46511 4.77177 3.89497 4.27568 4.05193 3.67173L4.12931 3.37398C4.39153 2.365 5.3848 1.72666 6.41153 1.90728L6.71452 1.96058C7.3291 2.0687 7.95892 1.88377 8.41749 1.46055L8.64356 1.2519Z"
      fill={color}
    />
    <path d="M6 10.1667L8.54545 12.5L14 7.5" stroke="white" strokeWidth="1.5" />
  </svg>
);
