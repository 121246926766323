import { NotSelectedDotIcon, SelectedDotIcon } from '@assets/icons';
import { useMediaQuery } from '@hooks';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { DefaultSelectorProps, SelectorItemProps } from './props';

export const ColumnSelector = (props: DefaultSelectorProps) => {
  const { values, selected = '', onChange, columns = 3 } = props;
  const isDesktop = useMediaQuery('lg');

  if (isDesktop)
    return (
      <Grid container columnSpacing={2}>
        {values?.map((cur) => (
          <Grid key={`selector-item-${cur.value}`} item sm={12 / columns} zIndex={10}>
            <DesktopSelectorItem {...cur} active={selected === cur.value} onChange={onChange} />
          </Grid>
        ))}
      </Grid>
    );

  return (
    <Stack spacing={1.5} flexWrap="wrap" width="100%">
      {values?.map((cur) => (
        <SelectorItem {...cur} key={`selector-item-${cur.value}`} active={selected === cur.value} onChange={onChange} />
      ))}
    </Stack>
  );
};

const DesktopSelectorItem = (props: SelectorItemProps) => {
  const { active, label, value, subLabel, onChange, icon, disabled, disableText } = props;

  const onClick = () => {
    if (!disabled) onChange && onChange(value);
  };

  return (
    <Box
      zIndex={10}
      height="100%"
      borderRadius={1}
      sx={{ cursor: disabled ? 'normal' : 'pointer' }}
      onClick={onClick}
      boxShadow={!disabled && '0px 4px 15px rgba(0, 0, 0, 0.05)'}
      bgcolor={({ palette }) => (disabled ? palette.grey[50] : palette.common.white)}
      border={({ palette }) => {
        if (active) return `1px ${palette.primary.main} solid`;
        if (!disabled) return `0.5px ${palette.grey[100]} solid`;
        return 'none';
      }}
    >
      <Stack alignItems="center" padding={3} spacing={4}>
        <Stack alignItems="center">
          {icon}

          <Typography width="160px" textAlign="center" variant="bodyM" fontWeight={600} marginBottom={0.5} data-cy="ColumnSelector-Sub-Label">
            {label}
          </Typography>
          <Typography width="160px" textAlign="center" variant="bodyM">
            {subLabel}
          </Typography>
        </Stack>

        {disabled && (
          <Typography variant="caption" textAlign="center" fontWeight="600">
            {disableText}
          </Typography>
        )}

        {!disabled && active && <SelectedDotIcon />}
        {!disabled && !active && <NotSelectedDotIcon />}
      </Stack>
    </Box>
  );
};
const SelectorItem = (props: SelectorItemProps) => {
  const { active, label, value, subLabel, onChange, icon, disabled, disableText } = props;

  const onClick = () => {
    if (!disabled) onChange && onChange(value);
  };
  return (
    <Box
      zIndex={10}
      borderRadius={1}
      width="100%"
      sx={{ cursor: disabled ? 'normal' : 'pointer' }}
      onClick={onClick}
      boxShadow={!disabled && '0px 4px 15px rgba(0, 0, 0, 0.05)'}
      bgcolor={({ palette }) => (disabled ? palette.grey[50] : palette.common.white)}
      border={({ palette }) => {
        if (active) return `1px ${palette.primary.main} solid`;
        if (!disabled) return `0.5px ${palette.grey[100]} solid`;
        return 'none';
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" paddingX={2} paddingY={2.2} spacing={6}>
        <Stack direction="row" alignItems="center" spacing={2}>
          {icon}

          <Stack>
            <Typography variant="bodyS" fontWeight={600} marginBottom={0.5}>
              {label}
            </Typography>
            <Typography variant="bodyS">{subLabel}</Typography>

            {disabled && (
              <Typography display={{ sm: 'block', md: 'none' }} variant="caption" fontWeight="600">
                {disableText}
              </Typography>
            )}
          </Stack>
        </Stack>

        {disabled && (
          <Typography display={{ sm: 'none', md: 'block' }} variant="caption" fontWeight="600">
            {disableText}
          </Typography>
        )}

        {!disabled && active && <SelectedDotIcon />}
        {!disabled && !active && <NotSelectedDotIcon />}
      </Stack>
    </Box>
  );
};
