import { Box, InputBase, InputBaseProps, Stack, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { MouseEventHandler, ReactElement, forwardRef, useImperativeHandle, useRef, useState } from 'react';

const color = {
  standard: 'rgba(22, 23, 24, 0.5)',
  error: '#F64949',
  success: '#18BA51',
  warning: '#F6BD49',
};

type TextFieldVariantType = 'standard' | 'success' | 'warning' | 'error';
export interface InputFieldProps extends Omit<InputBaseProps, 'variant'> {
  label?: string;
  value?: string;
  disabled?: boolean;
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  important?: boolean;
  errorMessage?: string;
  variant?: TextFieldVariantType;
  onClick?: MouseEventHandler<unknown> | undefined;
  onIconClick?: MouseEventHandler<unknown> | undefined;
  dataCy?: string;
}

const useStyles = makeStyles(
  ({ palette }: Theme) => ({
    container: {
      height: '56px',
      display: 'flex',
      borderRadius: '4px',
      paddingLeft: '12px',
      paddingRight: '12px',
      alignItems: 'center',
      cursor: 'text',
      backgroundColor: palette.grey[50],

      '&:hover': {
        borderColor: 'black',
      },
      '& .MuiTypography-root': {
        color: palette.grey[400],
        lineHeight: '24px',
        fontSize: '16px',
        fontWeight: 400,
        transition: 'font-size 0.3s',
      },
      '& .MuiInputBase-input': {
        padding: '0px',
        lineHeight: '24px',
        fontSize: '16px',
        fontWeight: 400,
      },
      '& .MuiInputBase-root': {
        height: '20px',
        transition: 'height 0.3s',
        overflow: 'hidden',
      },
    },
    hide: {
      height: '0px !important',
      display: 'block',
    },
    focusedLabel: {
      fontSize: '12px !important',
      fontWeight: '500 !important',
      lineHeight: '16px !important',
    },
    standardRoot: {
      borderColor: 'rgba(0, 0, 0, 0.1)',
    },
    successRoot: {
      borderColor: color.success,
    },
    warningRoot: {
      borderColor: color.warning,
    },
    errorRoot: {
      borderColor: color.error,
      borderWidth: '1px',
    },
    disabled: {
      opacity: 1,
      pointerEvents: 'none',
    },
    iconButton: {
      cursor: 'pointer',
    },
  }),
  {
    name: 'MuiCustomStyle',
  }
);
// eslint-disable-next-line react/display-name
export const InputField = forwardRef((props: InputFieldProps, ref: React.Ref<unknown>) => {
  const { name, variant = 'standard', value, label, leftIcon, rightIcon, disabled, onIconClick, important, errorMessage, sx, dataCy, ...rest } = props;
  const [isFocused, setIsFocused] = useState(false);
  const classes = useStyles();

  const innerRef = useRef<HTMLInputElement>(null);
  useImperativeHandle(ref, () => innerRef.current);
  const isInputEmpty = innerRef.current?.value.length === 0 && value.length === 0;

  const onFocus = () => {
    if (disabled) return;
    setIsFocused(true);
    innerRef.current?.focus();
  };
  const onBlur = () => {
    if (disabled) return;
    setIsFocused(false);
  };

  return (
    <Stack>
      <Box className={`${classes.container} ${classes[`${variant}Root`]} ${disabled && classes.disabled}`} sx={sx} onClick={onFocus}>
        {leftIcon}

        <Box height="auto" width="100%" pl={leftIcon && '8px'} pr={rightIcon && '8px'}>
          {label && (
            <Typography className={`${(isFocused || !isInputEmpty) && classes.focusedLabel}`} data-cy={dataCy}>
              {label}
              {important && (
                <Box component="span" color="error.main" pl="2px">
                  *
                </Box>
              )}
            </Typography>
          )}

          <InputBase {...rest} id={name} value={value} onBlur={onBlur} fullWidth inputRef={innerRef} disabled={disabled} className={`${isInputEmpty && (isFocused || classes.hide)}`} />
        </Box>

        <Box className={classes.iconButton} onClick={onIconClick}>
          {rightIcon}
        </Box>
      </Box>

      {variant === 'error' && errorMessage && <Typography color="error.main">{errorMessage}</Typography>}
    </Stack>
  );
});
