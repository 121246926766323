import { ChevronRightIcon } from '@assets/icons';
import { BackInUp, ScheduleSelector } from '@components';
import { useGetEnrollmentProgramDetailsQuery } from '@generated';
import { useMediaQuery } from '@hooks';
import { Grid, Stack, Typography } from '@mui/material';
import dynamic from 'next/dynamic';
import { useState } from 'react';
import loadingLottie from '../../assets/lottie/loading.json';
import { useEnrollmentContext } from './context';
import { EnrollmentShiftSectionData } from './data-content';
import { EnrollmentProcessHeader } from './header';
import { StyledIconButton } from './styled-icon-button';

const Lottie = dynamic(() => import('lottie-react'), { ssr: false });

export const EnrollmentShiftTime = () => {
  const { setEnrollmentData, enrollmentData } = useEnrollmentContext();
  const { shift } = enrollmentData;
  const isSelected = shift !== undefined;
  const isDesktop = useMediaQuery('lg');

  const onChange = (newValue: string) => {
    setEnrollmentData((prev) => ({ ...prev, shift: newValue }));
  };

  const { data, loading } = useGetEnrollmentProgramDetailsQuery();

  let programDetails;
  let programSchedules;
  let hasMultipleStartDates;

  if (data && !loading) {
    programSchedules = data?.getEnrollmentProgramDetails.filter(({ program }) => program.startsWith(enrollmentData.curriculum));

    hasMultipleStartDates = programSchedules.length > 1;
    if (!hasMultipleStartDates) {
      programDetails = programSchedules[0];
    }
  }

  const onSubmit = () => {
    if (isSelected) setEnrollmentData((prev) => ({ ...prev, progress: prev.progress + 1 }));
  };

  return (
    <BackInUp>
      <Stack width="100%" alignItems="center" spacing={5} minHeight="calc(100vh - 170px)" paddingTop={4}>
        <EnrollmentProcessHeader icon={EnrollmentShiftSectionData.titleIcon} title={EnrollmentShiftSectionData.title} subTitle={EnrollmentShiftSectionData.subTitle} />
        {loading && (
          <Grid maxWidth="md" margin={'0 auto'} textAlign="center">
            <Lottie animationData={loadingLottie} style={{ height: 200 }} />
          </Grid>
        )}

        {!loading && hasMultipleStartDates ? (
          <>
            <MultipleStartDateSchedules shift={shift} programSchedules={programSchedules} onChange={onChange} />
          </>
        ) : (
          <>
            <SingleStartDateSchedulesContainer shift={shift} programDetails={programDetails} onChange={onChange} columns={isDesktop ? 2 : 1} />
          </>
        )}

        <StyledIconButton disabled={!isSelected} onClick={onSubmit} data-cy="Shift-Next-Button">
          <ChevronRightIcon color={isSelected && 'white'} />
        </StyledIconButton>
      </Stack>
    </BackInUp>
  );
};

function SingleStartDateSchedulesContainer(props) {
  const [selected, setSelected] = useState<string>(props.value);

  return <SingleStartDateSchedules selected={selected} onSelectedChange={setSelected} {...props} />;
}

function SingleStartDateSchedules({ programPrefix = '', selected, onSelectedChange, shift, programDetails, onChange, columns }) {
  const shiftData =
    programDetails?.result.map((cur, index) => ({
      label: `${index + 1}-ээлж`,
      subLabel: cur.time,
      helperText: `Үлдсэн: ${cur.leftSeatsCount}`,
      value: `${programPrefix}${cur.time}`,
      disableText: Number(cur.leftSeatsCount) <= 0 && 'Дүүрсэн',
      disabled: Number(cur.leftSeatsCount) <= 0,
    })) || [];

  return (
    <>
      {programDetails?.startDate && (
        <Typography variant="bodyM">
          Хичээл эхлэх өдөр: <strong>{generateDateStringFromProgramName(programDetails.startDate)}</strong>
        </Typography>
      )}

      <Stack maxWidth={722} sx={{ width: 'calc(100% - 32px)' }} alignItems="center" spacing={5}>
        <ScheduleSelector selected={selected} onSelectedChange={onSelectedChange} type="grid" columns={columns} value={shift} values={shiftData} onChange={onChange} />
      </Stack>
    </>
  );
}

function MultipleStartDateSchedules({ shift, programSchedules, onChange }) {
  const [selected, setSelected] = useState<string>(shift);
  const isDesktop = useMediaQuery('lg');

  return (
    <Stack direction={isDesktop ? 'row' : 'column'} rowGap={8} columnGap={4}>
      {programSchedules.map((programDetails) => (
        <Stack key={programDetails.program} maxWidth={isDesktop ? 320 + 32 : undefined}>
          <SingleStartDateSchedules
            programPrefix={`${programDetails.program} `}
            selected={selected}
            onSelectedChange={setSelected}
            shift={shift}
            programDetails={programDetails}
            onChange={onChange}
            columns={1}
          />
        </Stack>
      ))}
    </Stack>
  );
}

function generateDateStringFromProgramName(startDate: string) {
  if (startDate) {
    const dateStrings = startDate.split('-');
    return dateStrings.join(' сарын ');
  }
  return '';
}
