import { EnrollmentStackIcon } from '@assets/icons/enrollment-icons';
import { BackInUp } from '@components';
import { GetStudentDetailsQuery, GetStudentProgramDetailsQuery } from '@generated';
import { useMediaQuery } from '@hooks';
import { Stack } from '@mui/material';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { EnrollmentProcessHeader } from '../header';
import { ShiftPopUp } from '../shift-popup';
import { EnrollmentReviewDesktop } from './desktop';
import { EnrollmentReviewMobile } from './mobile';

interface EnrollmentReviewPropsType {
  studentDetails: GetStudentDetailsQuery;
  studentProgramDetailsData: GetStudentProgramDetailsQuery;
  paid: boolean;
  leftSeatsCount: number;
}

export const EnrollmentReview = ({ paid, leftSeatsCount, studentDetails, studentProgramDetailsData }: EnrollmentReviewPropsType) => {
  const isDesktop = useMediaQuery('lg');
  const [shiftPopUpOpen, setShiftPopUpOpen] = useState<boolean>(!paid && Number(leftSeatsCount) === 0);
  const router = useRouter();

  const onClose = async () => {
    router.reload();
  };

  return (
    <BackInUp>
      <Stack width="100%" alignItems="center" spacing={5} minHeight="calc(100vh - 170px)">
        <EnrollmentProcessHeader icon={<EnrollmentStackIcon />} title="Элсэлт" />
        {isDesktop && <EnrollmentReviewDesktop studentDetails={studentDetails} studentProgramDetailsData={studentProgramDetailsData} />}
        {!isDesktop && <EnrollmentReviewMobile studentDetails={studentDetails} studentProgramDetailsData={studentProgramDetailsData} />}

        <ShiftPopUp open={shiftPopUpOpen} setOpen={setShiftPopUpOpen} entrantData={studentDetails} onClose={onClose} />
      </Stack>
    </BackInUp>
  );
};
