import { ChevronRightIcon } from '@assets/icons';
import errorAnimation from '@assets/lottie/error.json';
import { BackInUp, Selector } from '@components';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import loadingLottie from '../../assets/lottie/loading.json';
import { EnrollmentProcessHeader } from '../enroll/header';
import { StyledIconButton } from '../enroll/styled-icon-button';
import { useBookingContext } from './context';
import { BookingMeetingSectionData } from './data-content';

const Lottie = dynamic(() => import('lottie-react'), { ssr: false });

export const Meeting = () => {
  const { spacing, palette } = useTheme();
  const { bookingData, setBookingData } = useBookingContext();
  const { meeting } = bookingData;
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const isSelected = meeting !== undefined;
  // const [getStudentData] = useGetStudentDetailsLazyQuery();

  const fetch = async () => {
    setLoading(true);
    try {
      // const { data } = await getMeetingData({ variables: { studentId: localStorage.getItem('uid') } });
      // if (data?.getStudentDetails?.meeting) {
      //   const { _id, date, location, max, currentCount } = data.academyWebEntrant.meeting;
      //   setBookingData((prev) => ({ ...prev, appointment: { id: _id, date: date, hour: '', location: location, disabled: max === currentCount }, progress: prev.progress + 2 }));
      // }
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (window !== undefined) {
      fetch();
    }
  }, []);

  const { titleIcon, title, subTitle, selections } = BookingMeetingSectionData;

  const onChange = (newValue: string) => {
    setBookingData((prev) => ({ ...prev, meeting: newValue }));
  };

  const onSubmit = () => {
    if (isSelected) setBookingData((prev) => ({ ...prev, progress: prev.progress + 1 }));
  };
  if (error) {
    return (
      <>
        <Lottie animationData={errorAnimation} style={{ height: 80 }} />
        <Typography variant="bodyM" mt={3} mb={0.5} fontWeight={590} sx={{ mt: 0.5 }}>
          Алдаа гарлаа
        </Typography>
        <Typography variant="bodyS">{error}</Typography>
      </>
    );
  }

  if (loading) {
    return (
      <Stack width="100%" alignItems="center" spacing={5} minHeight="calc(100vh - 170px)" paddingTop={4}>
        <Lottie animationData={loadingLottie} style={{ height: 200 }} />
      </Stack>
    );
  }

  return (
    <BackInUp>
      <Stack width="100%" alignItems="center" spacing={5} minHeight="calc(100vh - 170px)" paddingTop={4}>
        <EnrollmentProcessHeader {...{ icon: titleIcon, title, subTitle }} />

        <Stack width={`calc(100% - ${spacing(4)})`} maxWidth={358} alignItems="center">
          <Selector type="column" values={selections} onChange={onChange} columns={2} />
        </Stack>

        <StyledIconButton disabled={!isSelected} onClick={onSubmit}>
          <ChevronRightIcon color={isSelected ? 'white' : palette.grey[300]} />
        </StyledIconButton>
      </Stack>
    </BackInUp>
  );
};
