import { FrLeftArrorIcon } from '@assets/icons';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { ReactElement } from 'react';
import { useEnrollmentContext } from './context';

interface EnrollmentProcessHeaderProps {
  icon: ReactElement;
  title: string | string[];
  subTitle?: string;
  handleBack?: () => void;
  hideBackButton?: boolean;
}

export const EnrollmentProcessHeader = (props: EnrollmentProcessHeaderProps) => {
  const { icon, title, handleBack, subTitle, hideBackButton = false } = props;
  const router = useRouter();
  const { enrollmentData, setEnrollmentData } = useEnrollmentContext();
  const { progress } = enrollmentData;

  const onPressBack = () => {
    if (router.route == '/sign-in' && progress === 0) {
      setEnrollmentData((prev) => ({ ...prev, progress: -1 }));
      router.push('/');
      return;
    }
    if (progress === -1) router.push('/');
    setEnrollmentData((prev) => ({ ...prev, progress: prev.progress - 1 }));
    handleBack && handleBack();
  };
  return (
    <Stack width={({ spacing }) => `calc(100% - ${spacing(6)})`} maxWidth={976} position="relative">
      {!hideBackButton && !(enrollmentData.progress === 3) && (
        <Box display={{ sm: 'none', md: 'block' }} position="absolute" left={0} zIndex={100}>
          <IconButton onClick={onPressBack} data-cy="Header-Back">
            <FrLeftArrorIcon width={40} height={40} />
          </IconButton>
        </Box>
      )}

      <Stack alignItems="center" spacing={1.5}>
        <Box display={{ sm: 'none', md: 'block' }}>{icon}</Box>

        <Typography
          maxWidth={{ sm: `calc(100% - 36px)`, md: '100%' }}
          typography={{ sm: 'displayS', md: 'displayM', lg: 'displayL' }}
          textAlign={'center'}
          fontWeight={800}
          data-cy="Header-Title-Text"
        >
          {typeof title === 'string'
            ? title
            : title.map((text, index) => (
                <span key={index}>
                  {text}
                  <br />
                </span>
              ))}
        </Typography>
        <Typography variant="bodyM" textAlign="center" maxWidth={640}>
          {subTitle}
        </Typography>
      </Stack>
    </Stack>
  );
};
