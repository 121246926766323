import { LogoProps } from '../index';

export const RoundedArrowRightIcon = ({ width, height, color }: LogoProps) => (
  <svg width={width || '48'} height={height || '49'} viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24.5" r="23.5" transform="rotate(-90 24 24.5)" stroke={color || 'black'} />
    <path
      d="M23.29 18.2901C23.1963 18.383 23.1219 18.4936 23.0711 18.6155C23.0203 18.7373 22.9942 18.868 22.9942 19.0001C22.9942 19.1321 23.0203 19.2628 23.0711 19.3846C23.1219 19.5065 23.1963 19.6171 23.29 19.7101L26.59 23.0001H19C18.7348 23.0001 18.4804 23.1054 18.2929 23.293C18.1054 23.4805 18 23.7348 18 24.0001C18 24.2653 18.1054 24.5196 18.2929 24.7072C18.4804 24.8947 18.7348 25.0001 19 25.0001H26.59L23.29 28.2901C23.1017 28.4784 22.9959 28.7338 22.9959 29.0001C22.9959 29.2664 23.1017 29.5218 23.29 29.7101C23.4783 29.8984 23.7337 30.0042 24 30.0042C24.2663 30.0042 24.5217 29.8984 24.71 29.7101L29.71 24.7101C29.801 24.615 29.8724 24.5028 29.92 24.3801C29.9729 24.2604 30.0002 24.1309 30.0002 24.0001C30.0002 23.8692 29.9729 23.7398 29.92 23.6201C29.8724 23.4973 29.801 23.3852 29.71 23.2901L24.71 18.2901C24.617 18.1963 24.5064 18.1219 24.3846 18.0712C24.2627 18.0204 24.132 17.9943 24 17.9943C23.868 17.9943 23.7373 18.0204 23.6154 18.0712C23.4936 18.1219 23.383 18.1963 23.29 18.2901Z"
      fill={color || 'black'}
    />
  </svg>
);
