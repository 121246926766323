import React from 'react';
import { LogoProps } from '.';

export function PlayerPlayIcon({ width, height, color }: LogoProps) {
  return (
    <svg width={width || '49'} height={height || '49'} viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.3574 11.3137C17.3586 10.0837 14.7852 11.5217 14.7852 13.8687V35.1313C14.7852 37.4783 17.3586 38.9163 19.3574 37.6863L36.6333 27.055C38.5369 25.8835 38.5369 23.1165 36.6333 21.945L19.3574 11.3137Z"
        fill={color || '#111111'}
      />
    </svg>
  );
}
