import { LogoProps } from '..';

export const PineconeLogoLeft = ({ width, height, color }: LogoProps) => (
  <svg width={width || '106'} height={height || '262'} viewBox="0 0 106 262" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.38877 100.266L49.3782 23.4397C56.9333 9.24931 71.7084 0.384857 87.7872 0.384857H105.642V45.8049L71.1395 110.512C67.7152 116.916 66.0085 123.95 66.0085 131C66.0085 138.05 67.7152 145.085 71.1395 151.49L105.811 216.512V261.615H87.7872C71.7084 261.615 56.9333 252.752 49.3782 238.562L8.38877 161.734C3.27358 152.119 0.710449 141.568 0.710449 131C0.710449 120.433 3.27358 109.881 8.38877 100.266Z"
      fill={color || 'white'}
    />
  </svg>
);
