import { ChevronDownIcon, MenuCloseIcon, MenuIcon, PineconeFilledLogo } from '@assets/icons';
import { Box, Button, ButtonProps, ClickAwayListener, IconButton, MenuItem as MUIMenuItem, MenuList, Stack, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { signOut, useSession } from 'next-auth/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Dispatch, PropsWithChildren, SetStateAction, useMemo, useRef, useState } from 'react';
import { ConfirmModal } from './confirm-modal';

type HeaderPropsType = {
  open: boolean;
  isScrolled?: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

interface AuthButtonProps {
  text: string;
  href?: string;
  variant?: ButtonProps['variant'];
  onClick?: () => void;
}

const AuthButton = ({ text, onClick, href, ...other }: AuthButtonProps) => {
  if (onClick) {
    return (
      <Button onClick={onClick} sx={{ display: { sm: 'none', lg: 'block' } }} {...other}>
        <Typography variant="bodyMSemibold">{text}</Typography>
      </Button>
    );
  }
  return (
    <Link href={href}>
      <Button sx={{ display: { sm: 'none', lg: 'block' } }} {...other}>
        <Typography variant="bodyMSemibold" data-cy="Header-Auth-Button">
          {text}
        </Typography>
      </Button>
    </Link>
  );
};

export const Header = ({ open, setOpen, isScrolled = false }: HeaderPropsType) => {
  const { status } = useSession();
  const [isProgramOpen, setIsProgramOpen] = useState(false);
  const [signOutConfirm, setSignOutConfirm] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const router = useRouter();

  const headerColor = useMemo(() => {
    if (router.route.includes('level')) {
      return 'black';
    }
    const whiteHeaderFirst = router.route === '/' || router.route === '/about-us';
    return whiteHeaderFirst ? (isScrolled ? 'black' : 'white') : 'black';
  }, [isScrolled, router]);

  const onPressOpen = () => {
    if (!open) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'unset';

    setOpen(!open);
  };
  const onPressCloseModal = () => {
    setSignOutConfirm(false);
  };
  const signOutHandler = () => {
    signOut();
  };

  const onClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setIsProgramOpen(false);
  };

  const authenticated = status === 'authenticated';
  const loading = status === 'loading';

  return (
    <Stack
      className="no-trailer"
      direction="row"
      width="100vw"
      justifyContent="center"
      bgcolor={`rgba(250, 250, 250, ${!isScrolled ? 0 : 1})`}
      zIndex={901}
      position={'fixed'}
      top={0}
      borderBottom={open ? `1px solid rgba(0, 0, 0, 0.1)` : `1px solid rgba(255, 255, 255, 0.1)`}
      sx={{
        perspective: 'none',
        transition: 'background 200ms',
        WebkitBackfaceVisibility: 'hidden',
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        paddingX={{ sm: '20px', md: 4, lg: 8 }}
        paddingY={{ sm: '12px', md: '20px' }}
        width="100%"
        maxWidth={({ breakpoints }) => breakpoints.values.lg}
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="center" width="280px" flex={1} display={{ sm: 'none', md: 'none', lg: 'flex' }}>
          <Link href="/">
            <Button data-cy="Header-Home-Button">
              <Typography variant="bodyMSemibold" color={headerColor}>
                Нүүр
              </Typography>
            </Button>
          </Link>

          <Button ref={anchorRef} onClick={() => setIsProgramOpen(!isProgramOpen)} sx={{ position: 'relative' }}>
            <Typography variant="bodyMSemibold" color={headerColor} mr={1} data-cy="Header-Program-Dropdown">
              Хөтөлбөр
            </Typography>

            <motion.div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              animate={{
                rotate: isProgramOpen ? '180deg' : '0deg',
              }}
            >
              <ChevronDownIcon color={headerColor} />
            </motion.div>
            <motion.div
              style={{
                position: 'absolute',
                left: 0,
                border: '1px rgba(255, 255, 255, 0.1) solid',
                borderTop: 0,
                backgroundColor: !isScrolled ? 'transparent' : 'white',
                color: headerColor,
                overflow: 'hidden',
                textAlign: 'left',
              }}
              animate={{
                height: isProgramOpen ? 'auto' : 0,
                bottom: isProgramOpen ? '-131px' : -21,
                opacity: isProgramOpen ? 1 : 0,
              }}
              initial={{
                height: 0,
                bottom: -21,
                opacity: 0,
              }}
            >
              <ClickAwayListener onClickAway={(event) => onClose(event)}>
                <MenuList sx={{ padding: 0 }}>
                  <MenuItem textColor={headerColor} href="/leap/" data-cy="Header-Leap-Button">
                    LEAP
                  </MenuItem>
                  <Box height="1px" width="96px" marginX={2} sx={{ backgroundColor: !isScrolled ? 'grey.300' : '#D9D9D9' }} />
                  <MenuItem textColor={headerColor} href="/hop/" data-cy="Header-Hop-Button">
                    HOP
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </motion.div>
          </Button>
          <Link href="/about-us/">
            <Button aria-label="Бидний тухай">
              <Typography variant="bodyMSemibold" color={headerColor} data-cy="Header-About-Us-Button">
                Бидний тухай
              </Typography>
            </Button>
          </Link>
        </Stack>
        <Link href="/">
          <IconButton
            title="Бидний тухай"
            aria-label="Бидний тухай"
            sx={{
              padding: 0,
              width: { sm: 32, md: 40 },
              height: { sm: 32, md: 40 },
            }}
            data-cy="Header-Icon-Button"
          >
            <Box width={{ sm: 32, md: 40 }} height={{ sm: 32, md: 40 }}>
              <PineconeFilledLogo color={open ? 'black' : headerColor} width="100%" height="100%" />
            </Box>
          </IconButton>
        </Link>

        <Stack direction="row" alignItems="center" justifyContent="right" gap="24px" flex={1}>
          {loading ? null : (
            <>
              {authenticated ? (
                <AuthButton
                  text="Гарах"
                  onClick={() => {
                    setSignOutConfirm(true);
                  }}
                />
              ) : (
                <AuthButton text="Нэвтрэх" href="/sign-in/" />
              )}
              {authenticated ? (
                <Button
                  onClick={() => {
                    setSignOutConfirm(true);
                  }}
                  sx={{
                    display: {
                      lg: authenticated ? 'none' : 'block',
                    },
                  }}
                  aria-label={authenticated ? 'Гарах' : 'Элсэх'}
                >
                  <Typography variant="bodyMSemibold">{authenticated ? 'Гарах' : 'Элсэх'}</Typography>
                </Button>
              ) : (
                <Link href="/enroll/">
                  <Button
                    sx={{
                      display: {
                        lg: authenticated ? 'none' : 'block',
                      },
                    }}
                    aria-label={authenticated ? 'Гарах' : 'Элсэх'}
                  >
                    <Typography variant="bodyMSemibold" data-cy="Header-Enroll-Button">
                      {authenticated ? 'Гарах' : 'Элсэх'}
                    </Typography>
                  </Button>
                </Link>
              )}
            </>
          )}

          <IconButton
            aria-label="Цэс"
            sx={{
              display: {
                sm: 'flex',
                lg: 'none',
              },
            }}
            onClick={onPressOpen}
            data-cy="Header-Menu-Button"
          >
            {open ? <MenuCloseIcon color={open ? 'black' : headerColor === 'white' ? 'black' : 'white'} /> : <MenuIcon color={headerColor} />}
          </IconButton>
        </Stack>
      </Stack>
      {signOutConfirm && (
        <ConfirmModal open={signOutConfirm} onClose={onPressCloseModal}>
          <Box justifyContent="center" textAlign="center">
            <Typography variant="bodyM" fontWeight={590}>
              Гарах?
            </Typography>
            <Typography>Та гарахдаа итгэлтэй байна уу?</Typography>
          </Box>
          <Stack width="100%" height="40px" display="flex" flexDirection="row" justifyContent="center" gap={2}>
            <Button onClick={signOutHandler} variant="contained" sx={{ ':hover': { backgroundColor: 'primary.dark' } }}>
              <Typography sx={{ paddingX: 2 }}>Гарах</Typography>
            </Button>
            <Button onClick={() => setSignOutConfirm(false)} variant="outlined">
              <Typography sx={{ paddingX: 2 }}>Болих</Typography>
            </Button>
          </Stack>
        </ConfirmModal>
      )}
    </Stack>
  );
};

export default Header;

interface MenuItemProps extends PropsWithChildren {
  textColor: 'white' | 'black';
  href: string;
}

const MenuItem = ({ children, textColor, href, ...other }: MenuItemProps) => {
  return (
    <Link href={href}>
      <MUIMenuItem
        sx={{
          paddingLeft: 2,
          paddingY: 2,
          '&:hover': {
            backgroundColor: 'rgba(250, 250, 250, 0.1)',
          },
        }}
        {...other}
      >
        <Typography variant="buttonM" color={textColor}>
          {children}
        </Typography>
      </MUIMenuItem>
    </Link>
  );
};
