import { CalendarIcon, CameraIcon, MessageIcon, OfficeIcon } from '@assets/icons/booking-icons';
// import { AcademyWebMeetingsQuery } from '@generated';
import { v4 as uuid } from 'uuid';

export const BookingMeetingSectionData = {
  title: 'Уулзалт хийх төрөл',
  subTitle: 'Та уулзалтын төрлөө сонгоно уу.',
  titleIcon: <MessageIcon />,
  selections: [
    { value: 'ONLINE', label: 'Видео дуудлага', icon: <CameraIcon />, disabled: true, disableText: 'Уулзалт товлогдоогүй байна.' },
    { value: 'OFFICE', label: 'Оффис дээр очих', icon: <OfficeIcon /> },
  ],
};

export const BookingAppointmentSectionData = {
  title: 'Цаг сонгох',
  titleIcon: <CalendarIcon />,
  selections: getNextWorkingDays(6).map((day: Date) => {
    return {
      id: uuid(),
      number: new Date(day).getDate(),
      name: ['Да', 'Мя', 'Лх', 'Пү', 'Ба', 'Да'][day.getDay()],
      hours: [day.setHours(12, 0, 0)],
    };
  }),
};

export function getNextWorkingDays(numDays: number) {
  const workingDays = [];
  const currentDate = new Date();

  while (workingDays.length < numDays) {
    currentDate.setDate(currentDate.getDate() + 1);

    // Check if the current day is a weekend day
    if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
      workingDays.push(new Date(currentDate));
    }
  }
  return workingDays;
}

export const formatTime = (date: number | string) => {
  const hours = new Date(date).getHours();
  const minutes = new Date(date).getMinutes().toString().padStart(2, '0');
  return `${hours} : ${minutes}`;
};

// export const formatMeetings = (meetings: AcademyWebMeetingsQuery['academyWebMeetings']) => {
//   const groupedMeetings = meetings.reduce((acc, meeting) => {
//     const date = new Date(meeting?.date);
//     date.setHours(0, 0, 0, 0);
//     const dateString = date.toString();
//     if (!acc[dateString]) {
//       acc[dateString] = [];
//     }
//     acc[dateString].push(meeting);
//     return acc;
//   }, {});

//   const sortedMeetings = Object.entries(groupedMeetings)
//     .map(([date, meetings]) => ({
//       date: new Date(date),
//       meetings: meetings as AcademyWebMeetingsQuery['academyWebMeetings'],
//     }))
//     .sort((a, b) => Number(a.date) - Number(b.date))
//     .map(({ date, meetings }) => {
//       return {
//         dayNumber: new Date(date).getDate(),
//         dayName: ['Ня', 'Да', 'Мя', 'Лх', 'Пү', 'Ба', 'Бя'][date.getDay()],
//         meetings: meetings
//           ?.map((meeting) => {
//             const date = new Date(meeting?.date);
//             const hour = `${date.getUTCHours()} : ${date.getMinutes().toString().padStart(2, '0')}`;
//             return {
//               id: meeting._id,
//               location: meeting.location,
//               hour,
//               date: new Date(meeting.date),
//               disabled: meeting.currentCount === meeting.max,
//             };
//           })
//           .sort((a, b) => Number(a.date) - Number(b.date)),
//       };
//     });

//   return sortedMeetings;
// };
