import Head from 'next/head';
import { useRouter } from 'next/router';
import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

export interface SeoContextType {
  setTitle: (title: string) => void;
  setDescription: (description: string) => void;
  setImage: (image: string) => void;
}
const FavIcons = () => {
  return (
    <>
      <link rel="shortcut icon" href="/favicon.ico" />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#1ac74a" />
      <meta name="msapplication-TileColor" content="#1ac74a" />
      <meta name="theme-color" content="#ffffff" />
    </>
  );
};

const TitleContext = createContext<SeoContextType>({} as SeoContextType);
export const TitleProvider = ({ children }: { children: ReactNode }) => {
  const [pageTitle, setTitle] = useState<string>('Pinecone Academy');
  const [image, setImage] = useState<string>('/gallery/about-us-hero.webp');
  const [pageDescription, setDescription] = useState<string>('Nest Academy болон M Stars Academy нэгдэн Pinecone Academy боллоо');
  const router = useRouter();
  const url = process.env.SITE_URL + router?.pathname;
  const providerValue = useMemo(() => ({ setTitle, setDescription, setImage }), [setTitle, setDescription, setImage]);

  return (
    <TitleContext.Provider value={providerValue}>
      <Head>
        {/* <html lang="mn" /> */}

        <title>{pageTitle}</title>
        <FavIcons />
        <meta name="title" content={pageTitle} />
        <meta name="description" content={pageDescription} />
        <meta name="keywords" content="nest academy, m stars academy, pinecone studio, pinecone, pinecone academy, сургалт, программын сургалт, IT сургалт" />
        <link rel="canonical" href={url} />

        <meta name="og:site_name" content="Pinecone Academy" />
        <meta name="og:url" content={url} />
        <meta name="og:title" content={pageTitle} />
        <meta name="og:description" content={pageDescription} />
        <meta name="og:locale" content="mn" />
        <meta name="og:image" content={image} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={url} />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content={image} />
      </Head>
      {children}
    </TitleContext.Provider>
  );
};

export const useTitle = () => useContext(TitleContext);
