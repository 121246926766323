import { CloseIcon, MessageIcon } from '@assets/icons';
import { useMediaQuery } from '@hooks';
import { Box, IconButton, Modal, Stack, Typography } from '@mui/material';
import { useEnrollmentContext } from '@subsets/enroll';
import { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useAuthContext } from '../../contexts';

export const TwoFactorModal = ({ callback, open, setOpen, phone }) => {
  const { optSignIn } = useAuthContext();
  const { enrollmentData } = useEnrollmentContext();
  const [otpInput, setOtpInput] = useState('');
  const isMobile = useMediaQuery('sm');
  const [error, setError] = useState('');
  const [timer, setTimer] = useState(10);
  const handleClose = () => {
    setOpen(false);
  };
  const onSubmit = async (otpCode: string) => {
    try {
      if (process.env.NODE_ENV !== 'development') {
        await window.confirmationResult?.confirm(otpCode);
      }
      callback();
    } catch (error) {
      setError(error.message.includes('auth/invalid-verification-code') ? 'Код буруу байна' : 'Алдаа гарлаа');
    }
  };

  useEffect(() => {
    const interval = setInterval(() => setTimer((prev) => (prev == 0 ? prev : prev - 1)), 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        slotProps={{
          backdrop: {
            sx: {
              height: {
                sm: '100vh',
                md: '100vh',
              },
            },
          },
        }}
      >
        <Stack
          alignItems={'center'}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'white',
            boxShadow: 24,
            p: ({ spacing }) => ({
              sm: `${spacing(4.5)} ${spacing(1)}`,
              md: `${spacing(4.5)} ${spacing(10)}`,
            }),
            borderRadius: 2,
            width: () => ({
              sm: 'calc(100% - 24px)',
              md: 'auto',
            }),
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              right: ({ spacing }) => spacing(3),
              top: ({ spacing }) => spacing(3),
            }}
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </IconButton>
          <Stack alignItems="center" spacing={1.5}>
            <MessageIcon />
            <Typography typography={{ sm: 'displayS', md: 'displayM', lg: 'displayL' }} fontWeight={800} textAlign={'center'}>
              Утасны дугаар
              <br />
              баталгаажуулах
            </Typography>
            <Typography variant="bodyM" textAlign="center" maxWidth={640} color="#393939">
              {`Таны ******${phone?.slice(-2)} дугаарт мессежээр ирсэн`}
              <br />
              {`6 оронтой тоог оруулна уу.`}
            </Typography>
          </Stack>
          <OtpInput
            value={otpInput}
            onChange={(text) => {
              setError(' ');
              setOtpInput(text);
              if (text.length === 6) {
                onSubmit(text);
              }
            }}
            numInputs={6}
            renderSeparator={() => <Box sx={{ width: { sm: '8px', md: '12px' } }} />}
            renderInput={(props) => {
              return <input type="number" {...props} placeholder={'_'} data-cy="Two-Factor-Otp" />;
            }}
            inputType="number"
            inputStyle={{
              flex: 1,
              backgroundColor: '#F6F6F7',
              height: isMobile ? '64px' : '80px',
              width: isMobile ? '38px' : '67px',
              fontSize: '22px',
              lineHeight: '28px',
              color: '#111111',
              borderRadius: '4px',
              margin: '40px 0px',
            }}
          />
          <Typography variant="caption" color={'red'}>
            {error}
          </Typography>
          <Stack
            direction={'row'}
            sx={{
              cursor: timer === 0 ? 'pointer' : 'default',
            }}
          >
            <Typography
              variant="caption"
              color={timer == 0 ? '#18BA51' : '#111111'}
              onClick={() => {
                if (timer != 0) return;
                setError('');
                optSignIn(enrollmentData?.phoneNumber);
                setTimer(60);
              }}
            >
              Дахин илгээх
            </Typography>
            {timer !== 0 && (
              <>
                <Typography variant="caption" color="#111111" mr={1}>
                  :
                </Typography>
                <Typography variant="caption" color="#18BA51">
                  {`${timer} сек`}
                </Typography>
              </>
            )}
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};
