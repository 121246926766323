import { Box, Stack, Typography } from '@mui/material';
import { useState } from 'react';

export interface MeetingInterface {
  id: string;
  hour: string;
  date: Date;
  location: string;
  disabled: boolean;
}

interface DayInterface {
  dayNumber: number;
  dayName: string;
  meetings: MeetingInterface[];
}

interface SelectorProps {
  values: DayInterface[];
  onChange: (appointment: MeetingInterface) => void;
}

export const AppointmentSelector = ({ values, onChange }: SelectorProps) => {
  const [selectedDay, setSelectedDay] = useState<DayInterface>(values.at(0));
  const [selectedMeeting, setSelectedMeeting] = useState<MeetingInterface | null>(null);
  const handleDaySelection = (day: DayInterface) => {
    setSelectedMeeting(null);
    setSelectedDay(day);
  };

  const handleHourSelection = (appointment: MeetingInterface) => {
    setSelectedMeeting(appointment);
  };

  return (
    <Stack gap={4} maxWidth={648}>
      <Stack direction="row" gap={1.5} overflow="scroll" sx={{ '&::-webkit-scrollbar': { display: 'none' } }}>
        {values?.map((day, index) => (
          <DayBlock key={index} selectedDay={selectedDay} day={day} onChange={() => handleDaySelection(day)} />
        ))}
      </Stack>
      <Stack gap={1.5} maxWidth={500}>
        {selectedDay?.meetings?.map((meeting) => {
          return <MeetingBlock key={meeting?.id} selectedMeeting={selectedMeeting} meeting={meeting} onChange={() => handleHourSelection(meeting)} onSubmit={() => onChange(meeting)} />;
        })}
      </Stack>
    </Stack>
  );
};

interface DayBlockProps {
  selectedDay: DayInterface;
  day: DayInterface;
  onChange: (day: DayInterface) => void;
}

const DayBlock = ({ selectedDay, day, onChange }: DayBlockProps) => {
  const isSelected = selectedDay?.dayNumber === day?.dayNumber;

  return (
    <Stack
      border="0.5px solid"
      borderColor="grey.100"
      boxShadow="0px 4px 15px rgba(0, 0, 0, 0.05)"
      bgcolor={isSelected ? 'primary.main' : 'white'}
      paddingX={{ sm: 3.25, lg: 4.5 }}
      paddingY={{ sm: 1.75, lg: 2 }}
      borderRadius={1}
      sx={{ cursor: 'pointer' }}
      onClick={() => onChange(day)}
      color={isSelected ? 'white' : 'black'}
    >
      <Typography fontWeight="bold">{day?.dayNumber}</Typography>
      <Typography>{day?.dayName}</Typography>
    </Stack>
  );
};

interface MeetingBlockProps {
  selectedMeeting: MeetingInterface;
  meeting: MeetingInterface;
  onChange: () => void;
  onSubmit: () => void;
}

const MeetingBlock = ({ selectedMeeting, meeting, onChange, onSubmit }: MeetingBlockProps) => {
  const isSelected = selectedMeeting?.id === meeting?.id;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      border={isSelected ? 'none' : '0.5px solid'}
      gap={1}
      borderColor={meeting.disabled ? 'grey.50' : 'grey.100'}
      boxShadow={meeting.disabled || isSelected ? 'none' : '0px 4px 15px rgba(0, 0, 0, 0.05)'}
      borderRadius={1}
      bgcolor={meeting.disabled ? 'grey.50' : 'gray.50'}
      onClick={() => meeting.disabled || onChange()}
      textAlign="center"
    >
      <Box flex={1} borderRadius={1} paddingY={2.5} bgcolor={isSelected && 'grey.50'} sx={{ cursor: isSelected || meeting.disabled ? 'auto' : 'pointer' }}>
        <Typography color={meeting.disabled ? 'grey.400' : 'black'} fontWeight="bold">
          {meeting?.hour}
        </Typography>
      </Box>
      {isSelected && (
        <Box flex={1} borderRadius={1} bgcolor="primary.main" height="100%" paddingY={2.5} sx={{ cursor: 'pointer' }} onClick={onSubmit}>
          <Typography color="white" fontWeight="bold">
            Сонгох
          </Typography>
        </Box>
      )}
    </Stack>
  );
};
