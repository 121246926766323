import { SuccessClockLogo, SuccessLocationLogo } from '@assets/icons';
import successAnimation from '@assets/lottie/success.json';
import { BackInUp } from '@components';
import { Box, Container, Stack, Typography } from '@mui/material';
import { useBookingContext } from './context';
import dynamic from 'next/dynamic';

const Lottie = dynamic(() => import('lottie-react'), { ssr: false });

export const Success = () => {
  const { bookingData } = useBookingContext();

  const date = new Date(bookingData?.appointment.date);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getUTCHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');

  const appointment = `${month} сарын ${day}-ний өдрийн ${hours}:${minutes} цаг`;

  return (
    <Container>
      <BackInUp>
        <Stack alignItems="center" height={{ sm: '80vh', md: '79vh', lg: '83vh' }}>
          <Stack alignItems="center" gap={1}>
            <Lottie animationData={successAnimation} loop={false} style={{ height: 80 }} />
            <Typography variant="displayL" fontWeight="800" mt={3}>
              Амжилттай
            </Typography>
            <Typography variant="bodyM">Уулзалтын цаг товлогдлоо.</Typography>
          </Stack>

          <Stack flexDirection={{ sm: 'column', lg: 'row' }} alignItems={{ sm: 'center', lg: 'start' }} gap={2.5} mt={{ lg: 8 }}>
            <Stack
              direction={{
                sm: 'row',
                lg: 'column',
              }}
              alignItems={{ sm: 'start', lg: 'center' }}
              gap={0.75}
              mt={{ sm: 8, lg: 0 }}
            >
              <Box width={{ sm: 20, lg: 40 }} height={{ sm: 20, lg: 40 }}>
                <SuccessClockLogo width="100%" height="100%" />
              </Box>

              <Typography display={{ sm: 'none', lg: 'block' }} variant="bodyM" fontWeight={600}>
                Хэзээ
              </Typography>

              <Stack direction={{ sm: 'column', lg: 'row' }} alignItems="start">
                <Typography display={{ sm: 'block', lg: 'none' }} variant="bodyM" fontWeight={600}>
                  Хэзээ
                </Typography>

                <Typography variant="bodyM" width={164}>
                  {appointment}
                </Typography>
              </Stack>
            </Stack>

            <Box height={{ lg: '100%' }} width={{ sm: '100%' }} border="0.5px solid" borderColor="grey.200" />

            <Stack
              direction={{
                sm: 'row',
                lg: 'column',
              }}
              alignItems={{ sm: 'normal', lg: 'center' }}
              gap={0.75}
            >
              <Box width={{ sm: 20, lg: 40 }} height={{ sm: 20, lg: 40 }}>
                <SuccessLocationLogo width="100%" height="100%" />
              </Box>

              <Typography display={{ sm: 'none', lg: 'block' }} variant="bodyM" fontWeight={600}>
                Байршил
              </Typography>

              <Stack direction={{ sm: 'column', lg: 'row' }} alignItems="start">
                <Typography display={{ sm: 'block', lg: 'none' }} variant="bodyM" fontWeight={600}>
                  Байршил
                </Typography>

                <Typography variant="bodyM" width={240} textAlign={{ sm: 'left', lg: 'center' }}>
                  {bookingData.appointment.location}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        {/* <Stack gap={1.5}>
          <Button endIcon={<BookIcon />}>
            <Typography color="primary.main">Уулзалтын өдрөө сануулаарай</Typography>
          </Button>
        </Stack> */}
      </BackInUp>
    </Container>
  );
};
