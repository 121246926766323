'use client';
import { ApolloProvider } from '@apollo/client';
import { Footer, Header, LocomotiveLayout, Sidebar } from '@components';
import { ModalProvider, TitleProvider } from '@hooks';
import { Stack } from '@mui/material';
import '@styles/global.scss';
import { BookingContextProvider } from '@subsets/booking';
import { EnrollmentContextProvider } from '@subsets/enroll';
import { Theme } from '@theme';
import 'locomotive-scroll/dist/locomotive-scroll.css';
import { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';

import Head from 'next/head';
import { useState } from 'react';
import { AuthProvider, CustomCursorProvider, useApollo } from '../contexts';

const Font = dynamic(() => import('../components/font'), { ssr: false });

const GoogleAnalytics = dynamic(() => import('../lib/GoogleAnalytics'), { ssr: false });
const Datadog = dynamic(() => import('../components/datadog'), { ssr: false });

interface MyApp extends AppProps {
  session: Session;
}

const Content = ({ Component, pageProps, router }: AppProps) => {
  const { route } = router;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  if (route === '/404')
    return (
      <Stack height="100vh" overflow="scroll">
        <Header open={isSidebarOpen} setOpen={setIsSidebarOpen} />
        <Sidebar open={isSidebarOpen} setOpen={setIsSidebarOpen} />
        <Component {...pageProps} />
        <Footer data-cy="Footer" />
      </Stack>
    );

  if (['/enroll', '/sign-in', '/booking', '/success', '/discovery'].some((validRoute) => route.startsWith(validRoute))) {
    return (
      <EnrollmentContextProvider>
        <BookingContextProvider>
          <main>
            <Component {...pageProps} />
          </main>
        </BookingContextProvider>
      </EnrollmentContextProvider>
    );
  }

  return (
    <div className="paperBackground">
      <CustomCursorProvider>
        <LocomotiveLayout>
          <Component {...pageProps} />
        </LocomotiveLayout>
      </CustomCursorProvider>
    </div>
  );
};

const CustomApp = (props: MyApp) => {
  const client = useApollo(props);

  const isDevelopment = process.env.NODE_ENV === 'development';

  return (
    <>
      <Head>
        <meta name="google-site-verification" content="b02_HYrzrqF0E5e-G571OgD6YMDvxb7BRBhWFVS87KY" />
      </Head>
      <Font />

      {!isDevelopment && <GoogleAnalytics />}
      {!isDevelopment && <Datadog />}

      <SessionProvider session={props?.session}>
        <ApolloProvider client={client}>
          <AuthProvider>
            <Theme>
              <TitleProvider>
                <ModalProvider>
                  <Content {...props} />
                </ModalProvider>
              </TitleProvider>
            </Theme>
          </AuthProvider>
        </ApolloProvider>
      </SessionProvider>
    </>
  );
};
export default CustomApp;
