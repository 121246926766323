import { useState } from 'react';
import { VideoPlayIcon } from '@assets/icons';
import { Box, Stack } from '@mui/material';
import { Theme, SxProps } from '@mui/material/styles';
import dynamic from 'next/dynamic';
import Image from 'next/image';

const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false });

interface InterviewVideoProps {
  src: string;
  thumbnail: string;
  style?: SxProps<Theme>;
}

export const InterviewVideo = (props: InterviewVideoProps) => {
  const { src, thumbnail, style } = props;
  const [isPlaying, setIsPlaying] = useState(false);

  const handleClick = () => setIsPlaying(true);

  if (isPlaying)
    return (
      <Stack width="100%" height="100%" sx={{ cursor: 'pointer', aspectRatio: { sm: '288/151' }, ...style }}>
        {isPlaying && <ReactPlayer url={src} playing={isPlaying} width="100%" height="100%" />}
      </Stack>
    );

  return (
    <Stack
      className="interactable"
      data-type="play"
      width="100%"
      height="100%"
      position="relative"
      sx={{ cursor: 'pointer', aspectRatio: { sm: '288/151' }, ...style }}
      onClick={handleClick}
      justifyContent="center"
      alignItems="center"
    >
      <Box position="absolute" top={0} left={0} width="100%" height="100%">
        <Image
          src={thumbnail}
          alt={thumbnail}
          fill
          style={{
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        />
      </Box>

      <VideoPlayIcon />
    </Stack>
  );
};
