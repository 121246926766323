import { ChevronRightIcon } from '@assets/icons';
import { BackInUp, Selector } from '@components';
import { Link, Stack, Typography } from '@mui/material';
import { useEnrollmentContext } from './context';
import { EnrollmentForWhoSectionData } from './data-content';
import { EnrollmentProcessHeader } from './header';
import { StyledIconButton } from './styled-icon-button';

export const EnrollmentForWho = () => {
  const { setEnrollmentData, enrollmentData } = useEnrollmentContext();
  const { forWho } = enrollmentData;
  const isSelected = forWho !== undefined;
  const onChange = (newValue: string) => {
    setEnrollmentData((prev) => ({ ...prev, forWho: newValue }));
  };

  const onSubmit = () => {
    if (isSelected) setEnrollmentData((prev) => ({ ...prev, progress: prev.progress + 1 }));
  };

  return (
    <BackInUp>
      <Stack width="100%" alignItems="center" spacing={5} minHeight="calc(100vh - 170px)" paddingTop={4}>
        <EnrollmentProcessHeader icon={EnrollmentForWhoSectionData.titleIcon} title={EnrollmentForWhoSectionData.title} />
        <Stack width={({ spacing }) => `calc(100% - ${spacing(4)})`} maxWidth={512} alignItems="center" spacing={5}>
          <Selector values={EnrollmentForWhoSectionData.selections} value={forWho} onChange={onChange} />
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography variant="bodyM">Та бүртгэлтэй юу?</Typography>
            <Link href="/sign-in" underline="always" variant="bodyS" fontWeight={700} color="#000000">
              Нэвтрэх
            </Link>
          </Stack>
        </Stack>
        <StyledIconButton disabled={!isSelected} onClick={onSubmit} data-cy="For-Who-Next-Button">
          <ChevronRightIcon color={isSelected && 'white'} />
        </StyledIconButton>
      </Stack>
    </BackInUp>
  );
};
