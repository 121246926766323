import React from 'react';
import { LogoProps } from '..';

export const PineconeFilledLogo = ({ width, height, color }: LogoProps) => (
  <svg width={width || '48'} height={height || '47'} viewBox="0 0 48 47" fill="none">
    <path
      d="M12.0527 10.9364L7.23173 19.9599C6.63011 21.0892 6.32864 22.3286 6.32864 23.5697C6.32864 24.811 6.63011 26.0502 7.23173 27.1795L12.0527 36.2032C12.9413 37.8699 14.6791 38.9109 16.5702 38.9109H21.6909V36.3547H21.6893C20.7445 36.3547 19.8757 35.8349 19.4313 35.0017L14.6122 25.9763C14.2094 25.224 14.0087 24.3978 14.0087 23.5697C14.0087 22.7416 14.2094 21.9154 14.6122 21.1633L19.4313 12.1379C19.8757 11.3045 20.7445 10.7849 21.6893 10.7849H21.6909V8.22849H16.5702C14.6791 8.22849 12.9413 9.26965 12.0527 10.9364Z"
      fill={color || 'black'}
    />
    <path
      d="M41.2683 19.96L36.4475 10.9364C35.5587 9.26953 33.8211 8.22855 31.93 8.22855H26.8091V10.7848H26.8109C27.7557 10.7848 28.6245 11.3045 29.0687 12.1378L33.8879 21.1632C34.2908 21.9155 34.4912 22.7417 34.4912 23.5698C34.4912 24.3978 34.2908 25.224 33.8879 25.9764L29.0687 35.0016C28.6245 35.835 27.7557 36.3546 26.8109 36.3546H26.8091V38.911H31.93C33.8211 38.911 35.5587 37.87 36.4475 36.2031L41.2683 27.1796C41.8697 26.0503 42.1714 24.8108 42.1714 23.5698C42.1714 22.3287 41.8697 21.0893 41.2683 19.96Z"
      fill={color || 'black'}
    />
  </svg>
);
