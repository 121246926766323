import React from 'react';
import { LogoProps } from '..';

export const HomePageFilledLogo = ({ width, height, color }: LogoProps) => (
  <svg width={width || '551'} height={height || '442'} viewBox="0 0 551 442" fill="none">
    <path
      d="M537.117 169.116L463.01 39.4207C449.346 15.4656 422.631 0.5 393.564 0.5H314.84V37.2449H314.873C329.39 37.2449 342.737 44.7124 349.574 56.6951L423.659 186.411C429.851 197.218 432.936 209.099 432.936 221C432.936 232.901 429.851 244.771 423.659 255.589L349.574 385.305C342.737 397.288 329.39 404.755 314.873 404.755H314.84V441.5H393.564C422.631 441.5 449.346 426.534 463.01 402.579L537.117 272.884C546.361 256.652 551 238.836 551 221C551 203.164 546.361 185.348 537.117 169.116ZM157.436 0.5H236.16V37.2449H236.127C221.61 37.2449 208.252 44.7124 201.415 56.6951L127.341 186.411C121.149 197.218 118.064 209.099 118.064 221C118.064 232.901 121.149 244.771 127.341 255.589L201.415 385.305C208.252 397.288 221.61 404.755 236.127 404.755H236.16V441.5H157.436C128.369 441.5 101.654 426.534 87.9898 402.579L13.8828 272.884C4.63854 256.652 0 238.836 0 221C0 203.164 4.63854 185.348 13.8828 169.116L87.9898 39.4207C101.654 15.4656 128.369 0.5 157.436 0.5Z"
      fill={color || 'white'}
    />
  </svg>
);
