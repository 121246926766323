import React from 'react';
import { LogoProps } from '.';

export function PlayIcon({ width, height, color }: LogoProps) {
  return (
    <svg width={width || '98'} height={height || '54'} viewBox="0 0 98 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="98" height="54" rx="2.73333" fill="black" fillOpacity="0.7" />
      <path d="M30.541 13.498L64.1077 27.1512L30.541 40.8043V13.498Z" fill={color || 'white'} />
    </svg>
  );
}
