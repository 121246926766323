import React from 'react';
import { LogoProps } from '..';

export const PineconeLogoIcon = ({ width, height, color }: LogoProps) => (
  <svg width={width || '48'} height={height || '48'} viewBox="0 0 48 48" fill="none">
    <path
      d="M11.5431 11.1691L6.61956 20.3846C6.00514 21.5379 5.69727 22.8037 5.69727 24.0712C5.69727 25.3389 6.00514 26.6044 6.61956 27.7578L11.5431 36.9735C12.4506 38.6757 14.2253 39.7388 16.1567 39.7388H21.3864V37.1282H21.3847C20.4199 37.1282 19.5325 36.5974 19.0787 35.7464L14.157 26.529C13.7457 25.7607 13.5407 24.9169 13.5407 24.0712C13.5407 23.2255 13.7457 22.3817 14.157 21.6136L19.0787 12.3962C19.5325 11.545 20.4199 11.0144 21.3847 11.0144H21.3864V8.40356H16.1567C14.2253 8.40356 12.4506 9.46688 11.5431 11.1691Z"
      fill={color || '#111111'}
    />
    <path
      d="M41.3803 20.3846L36.4569 11.1691C35.5493 9.46676 33.7747 8.40363 31.8434 8.40363H26.6135V11.0142H26.6154C27.5802 11.0142 28.4675 11.545 28.9212 12.396L33.8428 21.6134C34.2544 22.3818 34.459 23.2256 34.459 24.0713C34.459 24.9169 34.2544 25.7607 33.8428 26.5291L28.9212 35.7463C28.4675 36.5975 27.5802 37.1281 26.6154 37.1281H26.6135V39.7389H31.8434C33.7747 39.7389 35.5493 38.6758 36.4569 36.9734L41.3803 27.7579C41.9945 26.6045 42.3026 25.3387 42.3026 24.0713C42.3026 22.8038 41.9945 21.538 41.3803 20.3846Z"
      fill={color || '#111111'}
    />
  </svg>
);
