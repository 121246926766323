import React from 'react';
import { LogoProps } from '..';

export const PineconeRightStrokeLogo = ({ width, height, color }: LogoProps) => (
  <svg width={width || '92'} height={height || '181'} viewBox="0 0 92 181" fill="none">
    <path
      opacity="0.5"
      d="M1 15.4744V1.08041L29.4663 1C50.7758 1.88451 56.0831 14.1073 61.712 25.2848L86.3989 71.3616C94.1829 86.4793 89.6423 103.286 86.3989 109.799L59.6213 159.816C50.9863 177.566 39.887 178.939 31.461 179.982L31.3158 180H1V165.606C9.62031 165.091 13.8661 159.173 14.9115 156.278L42.9757 104.009C48.6851 92.3495 45.1469 80.7432 42.9757 77.1514L15.3136 25.4456C11.7111 17.2757 4.27014 15.394 1 15.4744Z"
      stroke={color || 'black'}
    />
  </svg>
);
