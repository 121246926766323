import { useRouter } from 'next/router';
import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from 'react';
import { MeetingInterface } from './AppointmentSelector';

export interface BookingDataProps {
  progress: number;
  meeting?: string;
  appointment?: MeetingInterface;
}
export interface BookingContextProps {
  bookingData?: BookingDataProps;
  setBookingData?: Dispatch<SetStateAction<BookingDataProps>>;
}

export const BookingContext = createContext<BookingContextProps>({});

export const BookingContextProvider = ({ children }: PropsWithChildren) => {
  const { query } = useRouter();
  const [bookingData, setBookingData] = useState<BookingDataProps>({ progress: Number(query?.progress) || -1 });

  return <BookingContext.Provider value={{ bookingData, setBookingData }}>{children}</BookingContext.Provider>;
};

export const useBookingContext = () => useContext(BookingContext);
