import NextImage, { ImageProps as NextImageProps } from 'next/image';

interface ImageProps extends NextImageProps {
  cutCorners?: boolean;
}

export const Image = (props: ImageProps) => {
  const { cutCorners, style, ...others } = props;

  return <NextImage style={{ ...style, clipPath: cutCorners && 'polygon(5% 0%, 100% 0%, 100% 88%, 95% 100%, 0% 100%, 0% 12%)' }} {...others} />;
};
