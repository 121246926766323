import { useEffect, useState } from 'react';

type dimensionProps = {
  width?: number;
  height?: number;
};

export const useWindowDimensions = () => {
  const [size, setSize] = useState<dimensionProps>({
    width: null,
    height: null,
  });

  useEffect(() => {
    const resizeHandler = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    resizeHandler();

    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  return size;
};
