import { getApp, getApps, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

export const firebaseConfig = {
  apiKey: process.env.FIREBASE_APIKEY,
  authDomain: 'academy-mgl-web.firebaseapp.com',
  projectId: 'academy-mgl-web',
  storageBucket: 'academy-mgl-web.appspot.com',
  messagingSenderId: '1089212552396',
  appId: '1:1089212552396:web:519004a9ffa9e755741e69',
};

const app = getApps.length > 0 ? getApp() : initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const OTPconfirmation = auth.currentUser;
