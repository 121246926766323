export * from './arrow/';
export * from './calendar-icon';
export * from './check-icon';
export * from './chevron';
export * from './close-icon';
export * from './dot/';
export * from './menu-close-icon';
export * from './menu-icon';
export * from './message-icon';
export * from './phone-icon';
export * from './pinecone-logo';
export * from './play-icon';
export * from './player-play-icon';
export * from './success-icons';
export * from './verified-icon';
export * from './video-play-icon';
export interface LogoProps {
  width?: string | number;
  height?: string | number;
  color?: string;
  className?: string;
}
