import { TypographyOptions } from '@mui/material/styles/createTypography';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    display1: React.CSSProperties;
    displayXXL: React.CSSProperties;
    displayXL: React.CSSProperties;
    displayL: React.CSSProperties;
    displayM: React.CSSProperties;
    displayS: React.CSSProperties;
    bodyMSemibold: React.CSSProperties;
    caption: React.CSSProperties;
    bodyM: React.CSSProperties;
    bodyS: React.CSSProperties;
    bodyX: React.CSSProperties;
    buttonL: React.CSSProperties;
    buttonM: React.CSSProperties;
    buttonS: React.CSSProperties;
    titleL: React.CSSProperties;
    titleM: React.CSSProperties;
    titleS: React.CSSProperties;
    headerM: React.CSSProperties;
    headerS: React.CSSProperties;
    impressionL: React.CSSProperties;
    impressionM: React.CSSProperties;
    impressionS: React.CSSProperties;
    labelS: React.CSSProperties;
    labelX: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    display1?: React.CSSProperties;
    displayXXL?: React.CSSProperties;
    displayXL?: React.CSSProperties;
    displayL?: React.CSSProperties;
    displayM?: React.CSSProperties;
    displayS?: React.CSSProperties;
    bodyMSemibold?: React.CSSProperties;
    bodyM?: React.CSSProperties;
    bodyS?: React.CSSProperties;
    bodyX?: React.CSSProperties;
    caption?: React.CSSProperties;
    buttonL?: React.CSSProperties;
    buttonM?: React.CSSProperties;
    buttonS?: React.CSSProperties;
    titleL: React.CSSProperties;
    titleM: React.CSSProperties;
    titleS: React.CSSProperties;
    headerM: React.CSSProperties;
    headerS: React.CSSProperties;
    impressionL: React.CSSProperties;
    impressionM: React.CSSProperties;
    impressionS: React.CSSProperties;
    labelS: React.CSSProperties;
    labelX: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    display1: true;
    displayXXL: true;
    displayXL: true;
    displayL: true;
    displayM: true;
    displayS: true;
    bodyMSemibold: true;
    caption: true;
    bodyM: true;
    bodyS: true;
    labelS: true;
    labelX: true;
    buttonL: true;
    buttonM: true;
    buttonS: true;
    titleL: true;
    titleM: true;
    titleS: true;
    headerM: true;
    headerS: true;
    impressionL: true;
    impressionM: true;
    impressionS: true;

    h1?: false;
    h2?: false;
    h3?: false;
    h4?: false;
    h5?: false;
    h6?: false;
    subtitle1?: false;
    subtitle2?: false;
    body1?: false;
    body2?: false;
    button?: false;
    overline?: false;
  }
}

export const typography: TypographyOptions = {
  fontFamily: 'SF Pro Display, sans-serif',

  display1: {
    fontSize: '80px',
    lineHeight: '105%',
    fontWeight: 800,
  },
  displayXXL: {
    fontSize: '48px',
    lineHeight: '57px',
    fontWeight: 700,
  },
  displayXL: {
    fontSize: '42px',
    lineHeight: '44px',
    fontWeight: 600,
  },
  displayL: {
    fontSize: '32px',
    lineHeight: '32px',
    fontWeight: 600,
  },
  displayM: {
    fontSize: '26px',
    lineHeight: '32px',
    fontWeight: 600,
  },
  displayS: {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 600,
  },
  bodyMSemibold: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 600,
  },
  bodyM: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
  },
  bodyS: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
  },
  labelS: {
    fontSize: '24px',
    lineHeight: '29px',
    fontWeight: 590,
  },
  labelX: {
    fontSize: '26px',
    lineHeight: '34px',
    fontWeight: 590,
  },
  caption: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400,
  },
  buttonL: {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 600,
  },
  buttonM: {
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 600,
  },
  buttonS: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 600,
  },
  titleL: {
    fontSize: '26px',
    lineHeight: '32px',
    fontWeight: 600,
  },
  titleM: {
    fontSize: '22px',
    lineHeight: '28px',
    fontWeight: 600,
  },
  titleS: {
    fontSize: '17px',
    lineHeight: '20px',
    fontWeight: 600,
  },
  headerM: {
    fontWeight: 650,
    fontSize: '28px',
    lineHeight: '33px',
  },
  headerS: {
    fontWeight: 540,
    fontSize: '24px',
    lineHeight: '29px',
  },
  impressionL: {
    fontWeight: 300,
    fontSize: '40px',
    lineHeight: '48px',
  },
  impressionM: {
    fontWeight: 300,
    fontSize: '32px',
    lineHeight: '38px',
  },
  impressionS: {
    fontWeight: 300,
    fontSize: '22px',
    lineHeight: '26px',
  },
};
